<template>
  <ModalComponent :id="id" title="Update Mobile User">
    <template #body>
      <Form @submit="acceptModal">
        <div class="p-6 space-y-3">
          <InputWithLabel
            label="First Name"
            type="text"
            name="firstname"
            :value="byId.first_name"
          />
          <InputWithLabel
            label="Last Name"
            type="text"
            name="lastname"
            :value="byId.last_name"
          />
          <InputWithLabel
            label="Phone Number"
            type="text"
            name="phonenumber"
            :value="byId.phone_number"
          />
          <SelectSearchWithLabel
            label="Modem ID"
            labelColor="text-black"
            placeholder="Select Modem ID"
            :data="listModem.rows"
            :optionsValue="'name'"
            :optionsKey="'modem_id'"
            v-model:formControlName="byId.modem_id"
          />
          <div class="mt-3">
            <InputIsActive
              name="isactive"
              v-model="byId.is_active"
              ket="Is Active"
            />
          </div>
        </div>

        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary">
              Update
            </ButtonComponent>
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import InputIsActive from "@/components/InputIsActive.vue";
import SelectSearchWithLabel from "@/components/SelectSearchWithLabel.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { Form } from "vee-validate";
import { useMobileUserStore, useModemStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

export default {
  props: {
    id: Number,
  },
  components: {
    ModalComponent,
    InputWithLabel,
    InputIsActive,
    SelectSearchWithLabel,
    ModalFooter,
    ButtonComponent,
    Form,
  },
  setup() {
    const mobileUserStore = useMobileUserStore();
    const modemStore = useModemStore();
    const { listModem } = storeToRefs(modemStore);
    const { byId } = storeToRefs(mobileUserStore);

    const getAllModem = async () => {
      await modemStore.getAllModem({ limit: 999, offset: 0 });
    };

    onMounted(() => {
      getAllModem();
    });

    const acceptModal = async (value) => {
      const { firstname, lastname, phonenumber } = value;
      await mobileUserStore.updateMobileUser(
        firstname,
        lastname,
        mobileUserStore.byId.modem_id,
        mobileUserStore.byId.is_active,
        phonenumber
      );
    };

    return {
      listModem,
      byId,
      acceptModal,
    };
  },
};
</script>

<style></style>
