import { createRouter, createWebHistory } from "vue-router";
import AppLayoutAdmin from "../layouts/AppLayoutAdmin.vue";
import DashboardView from "../views/dashboard/DashboardView.vue";
import TeleUserView from "../views/tele_user/TeleUserView.vue";
import LoginView from "@/views/login/LoginView.vue";
import RawSkywaveView from "@/views/raw_skywave/RawSkywaveView.vue";
import UserView from "@/views/user/UserView.vue";
import TeleMessageView from "@/views/tele_message/TeleMessageView.vue";
import RegisterView from "@/views/login/RegisterView.vue";
import LogbookView from "@/views/logbook/LogbookView.vue";
import SOSView from "@/views/sos/SOSView.vue";
import DefinedMessageView from "@/views/defined_message/DefinedMessageView.vue";
import FormGeneratorView from "@/views/form_report/FormGeneratorView.vue";
import NewFormGenerator from "@/views/form_report/NewFormGenerator.vue";
import SeahubDevice from "@/views/seahub_device/DeviceView.vue";
import MapsView from "@/views/maps/MapsView.vue";
import GeofencingView from "@/views/geofencing/GeofencingView.vue";
import VesselReportView from "@/views/vessel_report/VesselReportView";
import RawOgwsView from "@/views/raw_ogws/RawOgwsView.vue";
import OgwsMessageView from "@/views/chat_ogws/OgwsMessageView.vue";
import MobileUserView from "@/views/mobile_users/MobileUserView.vue";
import ModemView from "@/views/modems/ModemView.vue";
import {
  useAlertStore,
  useAuthStore,
  useScrollStore,
  useSidebarStore,
} from "@/store";

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      authPage: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      authPage: true,
    },
  },
  {
    path: "/",
    name: "AppLayout",
    component: AppLayoutAdmin,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/tele_user",
        name: "tele_user",
        component: TeleUserView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/raw_skywave",
        name: "raw_skywave",
        component: RawSkywaveView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/user",
        name: "user",
        component: UserView,
        meta: {
          requireAdmin: true,
        },
      },
      {
        path: "/tele_message",
        name: "tele_message",
        component: TeleMessageView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/logbook",
        name: "logbook",
        component: LogbookView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/sos",
        name: "sos",
        component: SOSView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/raw_ogws",
        name: "raw_ogws",
        component: RawOgwsView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/form_generator",
        name: "form_generator",
        meta: {
          requireAuth: true,
        },
        children: [
          {
            path: "",
            name: "form_generator",
            component: FormGeneratorView,
          },
          {
            path: "form_generator_new",
            name: "form_generator_new",
            component: NewFormGenerator,
          },
        ],
      },
      {
        path: "/defined_message",
        name: "defined_message",
        component: DefinedMessageView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/seahub_device",
        name: "seahub_device",
        component: SeahubDevice,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/maps",
        name: "maps",
        component: MapsView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/manajemen_geofencing",
        name: "manajemen_geofencing",
        component: GeofencingView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/vessel_report",
        name: "vessel_report",
        component: VesselReportView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/ogws_chat",
        name: "ogws_chat",
        component: OgwsMessageView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/mobile_user",
        name: "mobile_user",
        component: MobileUserView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/modems",
        name: "modems",
        component: ModemView,
        meta: {
          requireAdmin: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const alertStore = useAlertStore();
  const sidebarStore = useSidebarStore();
  const scroll = useScrollStore();

  alertStore.clear();
  sidebarStore.$reset();
  scroll.$reset();

  const authStore = useAuthStore();

  if (to.meta.authPage) {
    if (!authStore.user) {
      next();
    } else {
      next(from);
    }
  }

  if (to.meta.requireAuth) {
    if (authStore.user) {
      next();
    } else {
      next({ name: "login" });
    }
  }

  if (to.meta.requireAdmin) {
    if (authStore.user && authStore.user.role == "admin") {
      next();
    } else {
      next({ name: "dashboard" });
    }
  }
});

export default router;
