<template>
  <div
    class="flex-none ease-in-out duration-300 max-h-[420px] z-30 h-[420px] left-0 absolute md:block md:static"
    :class="{ 'w-0 ': !open, 'w-[22vw]': open }"
  >
    <div
      class="absolute ease-in-out flex-row bg-gray-100 h-full pt-8"
      :class="{ 'w-0 ': !open, 'w-[22vw]': open }"
    >
      <div class="flex justify-between px-4 -mt-5 items-center">
        <button
          class="bg-amber hover:bg-amber500 rounded-full p-1"
          v-if="btnClose"
          :class="{ hidden: !open, '': open }"
          @click="close"
        >
          <svg
            class="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </button>

        <slot name="search" />
      </div>

      <slot v-if="open"> </slot>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useSidebarStore } from "@/store";

export default {
  props: {
    btnClose: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const sidebar = useSidebarStore();
    const open = computed(() => sidebar.open);

    const close = () => {
      sidebar.$reset();
    };

    return {
      open,
      close,
    };
  },
};
</script>
