<template>
  <div class="main">
    <HeaderTitle title="Form">
      <IconForm size="w-10 h-10" color="#14444f" />
    </HeaderTitle>
    <MainContent>
      <div class="pt-3 mb-5 flex justify-end">
        <ButtonComponent
          vClass="text-xs text-greenPrimary bg-gray-300 hover:bg-[#c6cbd2] focus:ring-0 px-3 py-2 space-x-1 flex items-center"
          @click="modal.showModal(1)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <span class="sr-only md:not-sr-only">Add Form Field</span>
        </ButtonComponent>
        <AddFormField :id="1" />
      </div>
      <AlertErrorComponent
        v-if="alertStore.alert"
        :message="alertStore.alert.message"
      />
      <InputFloatingLabel
        label="Form Name"
        v-model:formControlName="formName"
      />
      <div class="flex flex-col" v-if="formProperties.length">
        <p class="text-sm text-gray-500 mb-2">Form Properties</p>
        <div v-for="(form, index) in formProperties" :key="index">
          <div class="flex space-x-3 items-center">
            <div
              class="flex-1"
              v-if="form.type == 'text' || form.type == 'password'"
            >
              <FormKit
                :type="form.type"
                :name="form.name"
                :label="form.name"
                :validation="form.validation"
              />
            </div>

            <div
              class="flex-1"
              v-else-if="
                form.type == 'radio' ||
                form.type == 'select' ||
                form.type == 'checkbox'
              "
            >
              <FormKit
                :type="form.type"
                :name="form.name"
                :label="form.name"
                :validation="form.validation"
                :options="form.options"
              />
            </div>
            <div v-else class="flex-1">
              <FormKit
                :type="form.type"
                :name="form.name"
                :label="form.name"
                :validation="form.validation"
              />
            </div>
            <IconEdit color="fill-amber" @click="editField(form)" />
            <IconTrash color="red" @click="removeField(index)" />
          </div>
        </div>
      </div>
      <EditFormField :id="2" />
      <div class="flex justify-end pt-5">
        <ButtonComponent
          vClass="pt-3 text-xs text-center text-white bg-greenPrimary hover:bg-greenSide focus:ring-0 px-3 py-2"
          @click="saveForm()"
          v-if="formProperties.length"
        >
          Save
        </ButtonComponent>
      </div>
    </MainContent>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import MainContent from "@/components/MainContent.vue";
import IconForm from "@/components/icons/IconForm.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import AddFormField from "./AddFormField.vue";
import InputFloatingLabel from "@/components/InputFloatingLabel.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import EditFormField from "./EditFormField.vue";
import { useAlertStore, useFormStore, useModalStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  components: {
    HeaderTitle,
    MainContent,
    ButtonComponent,
    AddFormField,
    IconForm,
    InputFloatingLabel,
    IconEdit,
    AlertErrorComponent,
    IconTrash,
    EditFormField,
  },
  setup() {
    const modal = useModalStore();

    const formStore = useFormStore();
    const { formName, formProperties } = storeToRefs(formStore);
    const alertStore = useAlertStore();

    const saveForm = async () => {
      await formStore.saveForm();
    };

    const removeField = (index) => {
      formStore.formProperties.splice(index, 1);
    };

    const editField = (value) => {
      formStore.formById = value;
      localStorage.setItem("properties", JSON.stringify(value));
      modal.showModal(2);
    };

    return {
      modal,
      formProperties,
      saveForm,
      formName,
      alertStore,
      removeField,
      editField,
    };
  },
};
</script>

<style></style>
