<template>
  <ModalComponent :id="id" title="Batas Kecepatan">
    <template #body>
      <Form
        :validation-schema="schema"
        v-slot="{ errors }"
        @submit="acceptModal"
      >
        <div class="p-6 space-y-3">
          <SelectSearchWithLabel
            :data="area.rows"
            optionsKey="id"
            optionsValue="area"
            placeholder="Select Area"
            v-model:formControlName="areaSelected"
            label="Area"
          />
          <InputWithLabel
            label="Batas Min"
            name="batasMin"
            type="text"
            :error="errors.batasMin"
          />
          <InputWithLabel
            label="Batas Max"
            name="batasMax"
            type="text"
            :error="errors.batasMax"
          />
          <div class="mb-2">
            <span class="block mb-0.5 text-sm font-medium text-gray-900">
              Rentang Waktu
            </span>
            <div class="flex justify-between items-center gap-2">
              <div class="py-2">
                <input
                  class="text-center border h-8 rounded-lg"
                  size="5"
                  placeholder="00"
                  v-model="startHour"
                />
                <span class="px-2">:</span>
                <input
                  class="text-center border h-8 rounded-lg"
                  size="5"
                  placeholder="00"
                  v-model="startMinute"
                />
              </div>
              -
              <div class="py-2">
                <input
                  class="text-center border h-8 rounded-lg"
                  size="5"
                  placeholder="00"
                  v-model="endHour"
                />
                <span class="px-2">:</span>
                <input
                  class="text-center border h-8 rounded-lg"
                  size="5"
                  placeholder="00"
                  v-model="endMinute"
                />
              </div>
            </div>
          </div>
        </div>
        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary">
              Save
            </ButtonComponent>
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "./ModalComponent.vue";
import { Form } from "vee-validate";
import SelectSearchWithLabel from "./SelectSearchWithLabel.vue";
import InputWithLabel from "./InputWithLabel.vue";
import ModalFooter from "./ModalFooter.vue";
import ButtonComponent from "./ButtonComponent.vue";
import { onMounted, ref } from "vue";
import * as Yup from "yup";
import { useAreaGeofenceStore, useSpeedLimitStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    ModalComponent,
    Form,
    SelectSearchWithLabel,
    InputWithLabel,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const areaStore = useAreaGeofenceStore();
    const { area } = storeToRefs(areaStore);
    const speedStore = useSpeedLimitStore();

    const areaSelected = ref("");
    const startHour = ref("");
    const startMinute = ref("");
    const endHour = ref("");
    const endMinute = ref("");

    const schema = Yup.object().shape({
      batasMin: Yup.string().required("Batas Min is required"),
      batasMax: Yup.string().required("Batas Max is required"),
    });

    const getArea = async () => {
      areaStore.getAll({ limit: 999 });
    };

    onMounted(async () => {
      await getArea();
    });

    const acceptModal = async (value) => {
      const { batasMin, batasMax } = value;
      const startTime = `${startHour.value}:${startMinute.value}`;
      const endTime = `${endHour.value}:${endMinute.value}`;
      await speedStore.addSpeed(
        areaSelected.value,
        batasMin,
        batasMax,
        startTime,
        endTime
      );
      areaSelected.value = "";
      startHour.value = "";
      startMinute.value = "";
      endHour.value = "";
      endMinute.value = "";
    };

    return {
      areaSelected,
      startHour,
      startMinute,
      endHour,
      endMinute,
      area,
      schema,
      acceptModal,
    };
  },
};
</script>

<style></style>
