<template>
  <ModalGeofence :id="id" title="Edit Area Geofence" @onClose="close">
    <template #body>
      <Form :validation-schema="schema" v-slot="{ errors }" @submit="save">
        <div class="p-6 space-y-3">
          <InputWithLabel
            label="Area"
            type="text"
            name="area"
            :error="errors.area"
            :value="dataById.area"
          />

          <SelectSearchWithLabel
            label="Kategori Area"
            :data="kategoriArea"
            optionsKey="name"
            optionsValue="desc"
            placeholder="Select Kategoru Area"
            v-model:formControlName="dataById.kategori_area"
          />

          <div v-if="show">
            <span class="block mb-2 text-sm font-medium text-gray-900">
              Koordinat
            </span>

            <div
              class="flex flex-col max-h-40 -space-y-3 overflow-y-auto pt-1.5"
            >
              <div
                class="flex space-x-3 items-center justify-between"
                v-for="(koordinat, index) in dataById.koordinat"
                :key="index"
              >
                <div class="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    placeholder=" "
                    class="block py-2.5 px-3 w-full text-sm rounded-lg text-gray-900 bg-gray-50 border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-blue-600 peer"
                    autocomplete="off"
                    v-model="koordinat.lat"
                  />
                  <label
                    class="peer-focus:font-medium peer-focus:bg-white peer-focus:z-50 peer-focus:ml-3 absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:px-1 bg-white ml-3 px-1 font-medium peer-placeholder-shown:bg-transparent peer-placeholder-shown:font-normal peer-placeholder-shown:z-10"
                  >
                    Latitude
                  </label>
                </div>

                <div class="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    placeholder=" "
                    class="block py-2.5 px-3 w-full text-sm rounded-lg text-gray-900 bg-gray-50 border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-blue-600 peer"
                    autocomplete="off"
                    v-model="koordinat.long"
                  />
                  <label
                    class="peer-focus:font-medium peer-focus:bg-white peer-focus:z-50 peer-focus:ml-3 absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:px-1 bg-white ml-3 px-1 font-medium peer-placeholder-shown:bg-transparent peer-placeholder-shown:font-normal peer-placeholder-shown:z-10"
                  >
                    Longitude
                  </label>
                </div>

                <div class="flex space-x-1 -mt-5">
                  <div
                    class="bg-[#f6a823] hover:bg-amber500 rounded-full p-1 cursor-pointer"
                    @click="addMore"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      class="w-5 h-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>

                  <div
                    class="bg-red-700 hover:bg-red-800 rounded-full p-1"
                    :class="
                      index == 0 ? 'cursor-not-allowed' : 'cursor-pointer'
                    "
                    @click="index == 0 ? '' : remove(index)"
                  >
                    <IconTrash color="white" size="w-5 h-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex justify-end items-center p-6 space-x-2 rounded-b border-t border-gray-200"
        >
          <ButtonComponentVue vClass="bg-darkGreen hover:bg-greenPrimary">
            Save
          </ButtonComponentVue>

          <ButtonComponentVue @click="close" vClass="btn-darkGray">
            Close
          </ButtonComponentVue>
        </div>
      </Form>
    </template>
  </ModalGeofence>
</template>

<script>
import ModalGeofence from "./ModalGeofence.vue";
import { Form } from "vee-validate";
import InputWithLabel from "./InputWithLabel.vue";
import SelectSearchWithLabel from "./SelectSearchWithLabel.vue";
import IconTrash from "./icons/IconTrash.vue";
import ButtonComponentVue from "./ButtonComponent.vue";
import * as Yup from "yup";
import { useAreaGeofenceStore, useModalStore } from "@/store";
import { ref, watchEffect } from "vue";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    ModalGeofence,
    Form,
    InputWithLabel,
    SelectSearchWithLabel,
    IconTrash,
    ButtonComponentVue,
  },
  setup() {
    const areaStore = useAreaGeofenceStore();
    const { dataById } = storeToRefs(areaStore);
    const modal = useModalStore();

    const show = ref(true);

    const kategoriArea = [
      { name: "Area Terlarang", desc: "Area Terlarang" },
      { name: "Area Terbatas", desc: "Area Terbatas" },
      { name: "Area Kerja", desc: "Area Kerja" },
    ];

    const schema = Yup.object().shape({
      area: Yup.string().required("Area is required"),
    });

    const addMore = () => {
      dataById.value.koordinat.push({ lat: "", long: "" });
    };

    const remove = (index) => {
      dataById.value.koordinat.splice(index, 1);
    };

    const close = () => {
      modal.closeModal();
      Object.keys(areaStore.geoEdited).forEach((key) => {
        delete areaStore.geoEdited[key];
      });
    };

    const save = async (value) => {
      const { area } = value;
      var coordinate = {};

      if (Object.keys(areaStore.geoEdited).length == 0) {
        const result = areaStore.dataById.koordinat.map(({ lat, long }) => [
          long,
          lat,
        ]);
        console.log(result);
        coordinate = areaStore.dataById.geomKoordinat;
        coordinate.properties = {
          ...coordinate.properties,
          area: area,
          kategori_area: dataById.value.kategori_area,
        };
        if (coordinate.geometry.type == "Point") {
          coordinate.geometry.coordinates = result[0];
        } else if (coordinate.geometry.type == "LineString") {
          coordinate.geometry.coordinates = result;
        } else {
          coordinate.geometry.coordinates = [result];
        }
      } else {
        coordinate = areaStore.geoEdited;
        coordinate.properties = {
          ...coordinate.properties,
          area: area,
          kategori_area: dataById.kategori_area,
        };
      }

      await areaStore.updateArea(
        area,
        areaStore.dataById.kategori_area,
        coordinate
      );
    };

    watchEffect(() => {
      if (Object.keys(areaStore.geoEdited).length != 0) {
        show.value = false;
        console.log(Object.keys(areaStore.geoEdited).length);
        console.log(areaStore.geoEdited);
      }
    });

    return {
      dataById,
      kategoriArea,
      show,
      schema,
      addMore,
      remove,
      close,
      save,
    };
  },
};
</script>

<style></style>
