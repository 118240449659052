<template>
  <div class="absolute top-28 right-14 z-10 bg-gray-100 px-4 py-2 w-72">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-4 h-4 float-right cursor-pointer"
      @click="onClose"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18 18 6M6 6l12 12"
      />
    </svg>
    <h1 class="font-bold text-base px-3 mt-2 mb-4">New Vessel Group</h1>
    <Form
      ref="form"
      @submit="save"
      :validation-schema="schema"
      v-slot="{ errors }"
    >
      <InputWithLabel
        label="Grup Kapal"
        type="text"
        name="vesselGrup"
        placeholder="Masukkan Grup Kapal"
        :error="errors.vesselGrup"
      />
      <SelectShip
        labelColor="text-gray-800"
        v-model:formControlName="selectedVessel"
        ref="childComponentRef"
      />
      <div class="flex justify-end">
        <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary"
          >Save</ButtonComponent
        >
      </div>
    </Form>
  </div>
</template>

<script>
import { ref } from "vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import InputWithLabel from "@/components/InputWithLabel.vue";
import SelectShip from "@/components/SelectShip.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import axios from "../../config";
import { useShipStore } from "@/store";

export default {
  components: {
    Form,
    SelectShip,
    InputWithLabel,
    ButtonComponent,
  },
  setup() {
    const selectedVessel = ref([]);
    const selectedGroup = ref("");
    const childComponentRef = ref();
    const isSubmit = ref(false);

    const shipStore = useShipStore();

    const form = ref();

    const schema = Yup.object().shape({
      vesselGrup: Yup.string().required("Vessel grup is required"),
    });

    const save = async (value) => {
      isSubmit.value = true;
      var { vesselGrup } = value;
      try {
        await axios.post("ref_vessel_group", {
          name: vesselGrup,
        });

        const data = await axios.get("ref_vessel_group");
        const result = data.data.rows;

        selectedGroup.value = result[result.length - 1].id;
        for (let i = 0; i < selectedVessel.value.length; i++) {
          await axios.post("vessel_group", {
            group_id: selectedGroup.value,
            mmsi: selectedVessel.value[i].mmsi,
            name: selectedVessel.value[i].name,
          });
        }
        form.value.resetForm();
        selectedVessel.value = [];
        childComponentRef.value.reset();
        shipStore.$state.showVesselGroup = false;
      } catch (error) {
        console.log(error);
      }
    };

    const onClose = () => {
      shipStore.$state.showVesselGroup = false;
      shipStore.$state.showGroup = false;
      form.value.resetForm();
      childComponentRef.value.reset();
    };

    return {
      selectedVessel,
      selectedGroup,
      save,
      childComponentRef,
      onClose,
      schema,
      form,
      isSubmit,
    };
  },
};
</script>

<style></style>
