<template>
  <SelectGroup
    :data="dataModem?.length ? dataModem : []"
    :optionsValue="'name'"
    :optionsKey="'modem_id'"
    v-model:formControlName="formName"
    placeholder="Select Group"
    @onSelectChange="changeData"
    @onClose="close"
  />
</template>

<script>
import { computed } from "vue";
import SelectGroup from "./SelectGroup.vue";
import { useModemStore } from "@/store";
import { onMounted } from "vue";
import { ref } from "vue";

export default {
  components: {
    SelectGroup,
  },
  props: {
    formControlName: [String, Number],
    theme: String,
    labelColor: String,
    optionsKey: String,
  },
  setup(props, { emit }) {
    const modemStore = useModemStore();

    const dataModem = ref([{ modem_id: "all", name: "All Modem" }]);

    const getAllModem = async () => {
      await modemStore.getAllModem();
      modemStore.listModem.rows.forEach((el) => {
        dataModem.value.push({ modem_id: el.modem_id, name: el.name });
      });
    };

    onMounted(async () => {
      getAllModem({ limit: 999, offset: 0 });
    });

    const formName = computed({
      get() {
        return props.formControlName;
      },
      set(value) {
        emit("update:formControlName", value);
      },
    });

    const changeData = (value) => {
      emit("onSelectChange", value);
    };

    const close = () => {
      emit("onClose");
    };

    return {
      dataModem,
      formName,
      changeData,
      close,
    };
  },
};
</script>

<style></style>
