<template>
  <div
    class="bg-gray-100 w-full h-[585px] flex justify-center items-center"
    v-show="id == 0 && !open"
  >
    <img src="../../assets/chat-group.png" class="w-40 h-40 lg:w-60 lg:h-60" />
  </div>
  <div
    class="bg-[#dad3cc] w-full h-[585px] flex flex-col"
    v-show="id != 0 && open"
  >
    <ChatHeader />
    <ChatRoom />
    <ChatInput />
  </div>
</template>

<script>
import ChatHeader from "@/components/ChatHeader.vue";
import ChatRoom from "@/components/ChatRoom.vue";
import ChatInput from "@/components/ChatInput.vue";
import { onMounted } from "vue";
import { useChatStore, useDefinedMessage } from "@/store";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    ChatHeader,
    ChatRoom,
    ChatInput,
  },
  setup() {
    const definedStore = useDefinedMessage();
    const chatStore = useChatStore();

    const { open } = storeToRefs(chatStore);

    const disconnect = () => {
      if (chatStore.clien.connected) {
        try {
          chatStore.clien.end(false, () => {
            console.log("disconnected");
          });
        } catch (error) {
          console.log(error);
        }
      }
    };

    onMounted(async () => {
      await chatStore.createConnection();
      console.log(chatStore.clien);
      await chatStore.subscribe();
      await definedStore.getAll({ limit: 999 });
      chatStore.definedMessage = definedStore.result.rows;
      await chatStore.getChat();
    });

    return {
      disconnect,
      chatStore,
      open,
    };
  },
};
</script>

<style></style>
