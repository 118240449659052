<template>
  <aside
    class="fixed inset-y-0 z-20 duration-200 flex-shrink-0 h-screen overflow-hidden overflow-y-auto bg-greenSide md:static focus:outline-none"
    :class="[open ? 'w-20' : 'w-0 md:w-48']"
  >
    <nav class="flex flex-col flex-shrink-0 h-full px-3 py-4 border-r">
      <div class="flex flex-col flex-1 space-y-4">
        <navigation-link routeLink="/dashboard">
          <IconHome />
          <span class="text-sm">Dashboard</span>
        </navigation-link>

        <div class="ml-1.5">
          <div class="flex justify-between gap-2 items-center text-white">
            <div class="flex gap-2 items-center">
              <IconChat />
              <span class="text-sm font-medium">Messages</span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              :class="[showMessage ? 'rotate-180' : '']"
              class="w-4 h-4 cursor-pointer"
              @click="openMessage"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
          <div
            class="flex flex-col space-y-3 mt-3"
            :class="[showMessage ? 'block' : 'hidden']"
          >
            <navigation-link routeLink="/raw_skywave" class="flex gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-6 h-6"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="text-sm">Message</span>
            </navigation-link>
            <navigation-link routeLink="/logbook" class="flex gap-3.5">
              <IconLogBook size="w-5 h-5" />
              <span class="text-sm">Logbook</span>
            </navigation-link>
            <navigation-link routeLink="/sos" class="flex gap-3">
              <IconSOS size="w-7 h-7" />
              <span class="text-sm">SOS</span>
            </navigation-link>
            <navigation-link routeLink="/raw_ogws" class="flex gap-3">
              <IconMessageOgws size="w-7 h-7" color="white" />
              <span class="text-sm">Messages</span>
            </navigation-link>
          </div>
        </div>
        <navigation-link routeLink="/defined_message">
          <IconDefinedMessage />
          <span class="text-sm">Defined Message</span>
        </navigation-link>

        <navigation-link routeLink="/form_generator">
          <IconForm />
          <span class="text-sm">Form Generator</span>
        </navigation-link>
        <navigation-link routeLink="/tele_message">
          <IconTeleMessage color="white" size="w-8 h-8" />
          <span class="text-sm">Chat</span>
        </navigation-link>
        <navigation-link routeLink="/modems" v-show="role == 'admin'">
          <IconModem color="white" />
          <span class="text-sm">Modem</span>
        </navigation-link>
        <navigation-link routeLink="/seahub_device">
          <IconMobile color="white" />
          <span class="text-sm">Seahub Device</span>
        </navigation-link>
        <navigation-link routeLink="/maps">
          <IconTracking />
          <span class="text-sm">Track</span>
        </navigation-link>

        <div class="ml-1.5">
          <div class="flex justify-between gap-2 items-center text-white">
            <div class="flex gap-2 items-center">
              <IconUserGroup />
              <span class="text-sm font-medium">Users</span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              :class="[showUser ? 'rotate-180' : '']"
              class="w-4 h-4 cursor-pointer"
              @click="openUser"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
          <div
            class="flex flex-col ml-2 space-y-3 mt-3"
            :class="[showUser ? 'block' : 'hidden']"
          >
            <navigation-link routeLink="/tele_user">
              <IconContact size="w-6 h-6" />
              <span class="text-sm">Telegram User</span>
            </navigation-link>
            <navigation-link routeLink="/mobile_user">
              <IconMobileUser size="w-6 h-6" color="white" />
              <span class="text-sm">Mobile User</span>
            </navigation-link>
            <navigation-link routeLink="/user" v-show="role == 'admin'">
              <IconUser size="w-6 h-6" />
              <span class="text-sm">User</span>
            </navigation-link>
          </div>
        </div>

        <navigation-link
          routeLink="/manajemen_geofencing"
          v-show="role == 'admin'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-7 h-7"
          >
            <path
              fill-rule="evenodd"
              d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 0 1-.517.608 7.45 7.45 0 0 0-.478.198.798.798 0 0 1-.796-.064l-.453-.324a1.875 1.875 0 0 0-2.416.2l-.243.243a1.875 1.875 0 0 0-.2 2.416l.324.453a.798.798 0 0 1 .064.796 7.448 7.448 0 0 0-.198.478.798.798 0 0 1-.608.517l-.55.092a1.875 1.875 0 0 0-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 0 1-.064.796l-.324.453a1.875 1.875 0 0 0 .2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 0 1 .796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 0 1 .517-.608 7.52 7.52 0 0 0 .478-.198.798.798 0 0 1 .796.064l.453.324a1.875 1.875 0 0 0 2.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 0 1-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 0 0 1.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 0 1-.608-.517 7.507 7.507 0 0 0-.198-.478.798.798 0 0 1 .064-.796l.324-.453a1.875 1.875 0 0 0-.2-2.416l-.243-.243a1.875 1.875 0 0 0-2.416-.2l-.453.324a.798.798 0 0 1-.796.064 7.462 7.462 0 0 0-.478-.198.798.798 0 0 1-.517-.608l-.091-.55a1.875 1.875 0 0 0-1.85-1.566h-.344ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
              clip-rule="evenodd"
            />
          </svg>

          <span class="text-sm">Geofencing</span>
        </navigation-link>

        <navigation-link routeLink="/vessel_report">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-7 h-7"
          >
            <path
              fill-rule="evenodd"
              d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375ZM6 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V12Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 15a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V15Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 18a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V18Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"
              clip-rule="evenodd"
            />
          </svg>

          <span class="text-sm">Report</span>
        </navigation-link>
      </div>
      <div class="flex items-center flex-shrink-0 mt-4">
        <a
          href="#"
          @click="authStore.logout()"
          class="p-2 flex gap-2 w-full text-indigo-400 transition-colors duration-200 rounded-md bg-indigo-50 hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:bg-indigo-100 focus:ring-indigo-800"
        >
          <svg
            class="w-7 h-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
          </svg>
          <span>Logout</span>
        </a>
      </div>
    </nav>
  </aside>
  <div
    class="fixed inset-y-0 z-10 duration-500 flex-shrink-0 w-full overflow-hidden h-screen bg-primary bg-opacity-30 backdrop-blur-sm border-r md:static focus:outline-none"
    :class="[open ? 'block md:hidden' : 'hidden md:hidden']"
  >
    <a
      href="#"
      @click="openNav"
      class="p-2 text-indigo-400 absolute right-5 top-5 transition-colors duration-200 rounded-md bg-indigo-50 hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:bg-indigo-100 focus:ring-indigo-800"
    >
      <span class="sr-only">close</span>
      <svg
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        class="w-7 h-7"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </a>
  </div>
</template>

<script>
import NavigationLink from "@/components/NavigationLink.vue";
import IconHome from "./icons/IconHome.vue";
import IconContact from "./icons/IconContact.vue";
import IconChat from "./icons/IconChat.vue";
import IconUserGroup from "./icons/IconUserGroup.vue";
import IconUser from "./icons/IconUser.vue";
import IconLogBook from "./icons/IconLogBook.vue";
import IconSOS from "./icons/IconSOS.vue";
import IconDefinedMessage from "./icons/IconDefinedMessage.vue";
import IconTeleMessage from "./icons/IconTeleMessage.vue";
import IconForm from "./icons/IconForm.vue";
import IconMobile from "./icons/IconMobile.vue";
import IconTracking from "./icons/IconTracking.vue";
import IconMessageOgws from "./icons/IconMessageOgws.vue";
import IconMobileUser from "./icons/IconMobileUser.vue";
import IconModem from "./icons/IconModem.vue";
import { useAuthStore, useNavigationStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

export default {
  name: "NavigationWeb",
  components: {
    NavigationLink,
    IconHome,
    IconContact,
    IconChat,
    IconUserGroup,
    IconUser,
    IconLogBook,
    IconSOS,
    IconDefinedMessage,
    IconTeleMessage,
    IconForm,
    IconMobile,
    IconTracking,
    IconMessageOgws,
    IconMobileUser,
    IconModem,
  },
  setup() {
    const authStore = useAuthStore();
    const navigation = useNavigationStore();
    const { open } = storeToRefs(navigation);
    const role = ref("");
    const showMessage = ref(false);
    const showUser = ref(false);

    const openNav = () => {
      navigation.open = !navigation.open;
    };

    onMounted(() => {
      if (authStore.user != null) {
        role.value = authStore.user.role;
      }
    });

    const openMessage = () => {
      showMessage.value = !showMessage.value;
    };
    const openUser = () => {
      showUser.value = !showUser.value;
    };

    return {
      authStore,
      openNav,
      open,
      role,
      openMessage,
      openUser,
      showMessage,
      showUser,
    };
  },
};
</script>
<style></style>
