<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.000000 512.000000"
    :class="size"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="color"
      stroke="none"
    >
      <path
        d="M2321 5110 c-497 -48 -990 -251 -1376 -565 -114 -92 -294 -274 -384
-387 -229 -287 -417 -675 -495 -1023 -49 -218 -60 -325 -60 -575 0 -250 11
-357 60 -575 79 -355 272 -749 509 -1040 92 -114 274 -294 387 -384 287 -229
675 -417 1023 -495 218 -49 325 -60 575 -60 250 0 357 11 575 60 261 58 603
204 828 353 389 259 688 599 893 1016 125 255 196 484 241 775 24 161 24 539
0 700 -45 291 -116 520 -241 775 -134 272 -283 480 -498 692 -211 209 -404
346 -673 478 -252 124 -486 197 -765 240 -126 19 -468 27 -599 15z m559 -246
c508 -74 967 -303 1324 -660 359 -358 581 -804 662 -1329 12 -77 17 -172 17
-315 0 -378 -75 -698 -240 -1032 -343 -693 -980 -1152 -1768 -1274 -151 -23
-479 -23 -630 0 -525 81 -971 303 -1329 662 -359 358 -581 804 -662 1329 -23
151 -23 479 0 630 42 267 111 492 223 717 337 680 970 1144 1733 1268 193 32
468 33 670 4z"
      />
      <path
        d="M2711 3826 c-94 -31 -184 -116 -211 -200 -31 -98 5 -225 85 -295 176
-154 471 -88 541 121 62 188 -81 376 -295 385 -47 2 -93 -3 -120 -11z"
      />
      <path
        d="M2381 2969 c-69 -12 -342 -105 -357 -122 -11 -12 -47 -137 -41 -143
1 -2 32 6 67 17 140 44 270 24 304 -47 35 -75 18 -180 -94 -561 -86 -294 -101
-364 -102 -478 -1 -74 3 -99 21 -142 30 -67 103 -136 189 -176 60 -28 78 -31
183 -35 141 -5 207 8 394 76 l130 48 19 67 c11 37 17 69 14 72 -3 3 -25 -2
-49 -11 -65 -25 -233 -25 -277 -1 -104 57 -98 153 42 634 88 301 96 338 97
455 1 167 -53 253 -201 321 -58 27 -77 31 -175 33 -60 2 -135 -1 -164 -7z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "w-5 h-5",
    },
    color: {
      type: String,
      default: "#1F2937",
    },
  },
};
</script>

<style></style>
