import mqtt from "precompiled-mqtt";
import { defineStore } from "pinia";
import { reactive } from "vue";
import axios from "../config";
import { useDefinedMessage } from "./definedMessage.store";
import { useAuthStore } from "./auth.store";

export const useChatStore = defineStore({
  id: "chat",
  state: () => ({
    URL: "ws://test.mosquitto.org:8081",
    id: 0,
    open: false,
    message: "",
    msg: null,
    showOptions: false,
    textSelect: null,
    contactById: {},
    chatHistory: [],
    unread: [],
    definedMessage: [],
    last_chat: [],
    readState: [],
    clien: { connected: false },
    options: reactive({
      clientId: "seahub" + Math.random().toString(16).substring(2, 8),
      clean: true,
      connectTimeout: 30 * 1000,
      reconnectPeriod: 4000,
      keepAlive: 240,
    }),
  }),
  getters: {
    getData(state) {
      return state.contactById;
    },
  },
  actions: {
    async createConnection() {
      const auth = JSON.parse(localStorage.getItem("token"));
      if (auth) {
        this.clien = await mqtt.connect(this.URL, this.options);
        this.clien.on("connect", () => {
          console.log(`Connect to ${this.URL} success`);
        });
        this.clien.on("error", (err) => {
          console.log(`Connection error ${err}`);
        });
      }
    },

    disconnect() {
      if (this.clien.connected) {
        try {
          this.clien.end(false, () => {
            console.log("disconnected successfully");
          });
        } catch (error) {
          console.log("disconnect error:", error);
        }
      }
    },

    subscribe() {
      const topic = `shb/in/message/#`;
      this.clien.subscribe(topic, (error) => {
        if (error) {
          console.log("subscribe error : ", error);
        }
        this.clien.on("message", async (topic, message) => {
          console.log("message subscribe ", message.toString());
          const data = JSON.parse(message.toString());
          try {
            await axios.post("tele_chat", {
              sender: data.sender,
              receiver: data.receiver,
              message: data.message,
              status: data.status,
              contact: data.sender,
            });
            if (this.id != data.sender) {
              await axios.post("unread_chat", {
                contact: data.sender,
                message: data.message,
              });
            }

            await this.getChat();
            this.lastChat();
            this.unreadCount();
            if (this.msg != null) {
              this.msg.scrollTo(0, this.msg.scrollHeight);
            }
          } catch (err) {
            console.log(err);
          }
        });
      });
    },

    publish() {
      const authStore = useAuthStore();
      const topic = "shb/out/message";
      let payload = `{"message": "${
        this.textSelect ? this.textSelect : this.message
      }","status": 2, "sender": "${authStore.user.firstName}", "receiver": "${
        this.contactById.chat_id
      }" }`;

      if (!this.clien.connected) {
        this.clien.reconnect();
      }

      this.clien.publish(topic, payload, async (error) => {
        if (error) {
          console.log("publish error : ", error);
        } else {
          console.log(`publish message ${payload} to topic ${topic}`);
          const data = JSON.parse(payload);
          try {
            await axios.post("tele_chat", {
              sender: data.sender,
              receiver: data.receiver,
              message: this.textSelect ? this.textSelect : data.message,
              status: data.status,
              contact: this.contactById.chat_id,
            });
            await this.getChat();
            this.lastChat();
            this.msg.scrollTo(0, this.msg.scrollHeight);
          } catch (error) {
            console.log(error);
          }
        }
      });
      this.message = "";
      this.textSelect = null;
    },

    async getChat() {
      const res = await axios.get("tele_chat");
      this.chatHistory = res.data;
    },

    async lastChat() {
      const res = await axios.get("tele_chat/last_chat");
      this.last_chat = res.data;
    },

    async unreadCount() {
      const res = await axios.get("unread_chat");
      this.unread = res.data;
      this.readState = this.unread.reduce((status, data) => {
        status[data.contact] = data.unread;
        return status;
      }, {});
    },

    filterMessage(params) {
      let message_filter = this.definedMessage
        .filter((tele_chat) => {
          return tele_chat.text_id === params;
        })
        .map((tele_chat) => {
          return tele_chat.text_defined;
        });

      return message_filter.toString();
    },

    async suggestText(value) {
      const definedStore = useDefinedMessage();
      let dataParams = {
        limit: 9999,
        offset: 0,
        q: value.target.value,
      };
      await definedStore.getAll(dataParams);
      if (definedStore.result.rows?.length != 0) {
        this.showOptions = true;
      }
      if (value.target.value == "") {
        this.showOptions = false;
      }
    },
  },
});
