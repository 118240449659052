import debounce from "debounce-promise";
import { getData } from "@/utils/fetch";
import { defineStore } from "pinia";
import { useModalStore } from "./modal.store";
import { reactive, ref } from "vue";
import axios from "../config";
import { useErrorStore } from "./error.store";
import { useSidebarStore } from "./sidebar.store";

let debouncedFetch = debounce(getData, 500);

export const useMobileUserStore = defineStore({
  id: "mobile-user",
  state: () => ({
    mobileUsers: {},
    byId: reactive({
      mobile_id: 0,
      first_name: "",
      last_name: "",
      modem_id: "",
      is_active: false,
      phone_number: "",
      createdAt: null,
      updatedAt: null,
    }),
    mobileid: 0,
    page: 1,
    length: 0,
    size: 10,
    q: "",
    selected: 0,
    dateFilter: ref([]),
  }),
  getters: {
    getDataMobileUser(state) {
      return state.mobileUsers.data;
    },
  },
  actions: {
    async registerMobileUser(
      firstname,
      lastname,
      modemid,
      isactive,
      phonenumber
    ) {
      const modal = useModalStore();
      try {
        await axios.post("mobile_user", {
          first_name: firstname,
          last_name: lastname,
          modem_id: modemid,
          is_active: isactive,
          phone_number: phonenumber,
        });

        modal.closeModal();
        this.page = 1;
        await this.getAll({});
        this.length = this.mobileUsers.count;
      } catch (error) {
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },

    async getAll(params) {
      this.mobileUsers = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        order: params?.order || "first_name",
        ordertype: params?.ordertype || "ASC",
        q: params?.q,
        modemid: params?.modemid || "all",
        fromDate: params?.fromDate,
        toDate: params?.toDate,
      };

      try {
        const data = await debouncedFetch("mobile_user", dataparams);
        this.mobileUsers = { ...data.data };
      } catch (error) {
        this.mobileUsers = { error };
      }
    },

    async getMobileUserById(mobileId) {
      if (mobileId) {
        this.mobileid = mobileId;
        try {
          const res = await axios.get(`mobile_user/${mobileId}`);
          this.byId.mobile_id = res.data.mobile_id;
          this.byId.first_name = res.data.first_name;
          this.byId.last_name = res.data.last_name;
          this.byId.modem_id = res.data.modem_id;
          this.byId.is_active = res.data.is_active;
          this.byId.phone_number = res.data.phone_number;
          this.byId.createdAt = res.data.createdAt;
          this.byId.updatedAt = res.data.updatedAt;
        } catch (error) {
          console.log(error);
        }
      }
    },

    async updateMobileUser(
      firstname,
      lastname,
      modemid,
      isactive,
      phonenumber
    ) {
      const modal = useModalStore();
      const sidebarStore = useSidebarStore();
      try {
        await axios.patch(`mobile_user/${this.mobileid}`, {
          first_name: firstname,
          last_name: lastname,
          modem_id: modemid,
          is_active: isactive,
          phone_number: phonenumber,
        });
        modal.closeModal();
        this.page = 1;
        await this.getAll({});
        await this.getMobileUserById(this.mobileid);

        sidebarStore.$patch((state) => {
          state.id = this.byId.mobile_id;
          state.data = this.byId;
        });
      } catch (error) {
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },

    async deleteMobileUser(mobileId) {
      const modal = useModalStore();
      try {
        await axios.delete(`mobile_user/${mobileId}`);
        modal.closeModal();
        this.page = 1;
        await this.getAll({});
        this.length = this.mobileUsers.count;
      } catch (error) {
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },
  },
});
