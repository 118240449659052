import { defineStore } from "pinia";
import debounce from "debounce-promise";
import { getData } from "@/utils/fetch";
import { useModalStore } from "./modal.store";
import axios from "../config";
import { useErrorStore } from "./error.store";

let debouncedFetch = debounce(getData, 500);

export const useDefinedMessage = defineStore({
  id: "definedMessage",
  state: () => ({
    result: {},
    id: "",
    textDefined: "",
    length: 0,
    page: 1,
    q: "",
  }),
  getters: {
    getDefinedMessage(state) {
      return state.result.data;
    },
  },
  actions: {
    async addMessage(text_defined) {
      const modal = useModalStore();
      try {
        await axios.post("defined_message", {
          text_defined: text_defined,
        });
        modal.closeModal();
        this.page = 1;
        await this.getAll({});
        this.length = this.result.count;
      } catch (error) {
        modal.closeModal();
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.retry = false;
        errorMessage.message = error.response.data.message;
      }
    },
    async getAll(params) {
      this.result = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        order: params?.order || "text_defined",
        ordertype: params?.ordertype || "ASC",
        q: params?.q,
      };

      try {
        const data = await debouncedFetch("defined_message", dataparams);
        this.result = { ...data.data };
      } catch (error) {
        this.result = { error };
      }
    },
    async getById(id) {
      if (id) {
        this.id = id;
        try {
          const res = await axios.get(`defined_message/${id}`);
          this.textDefined = res.data.text_defined;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async updateDefinedMessage(text_defined) {
      const modal = useModalStore();
      try {
        await axios.patch(`defined_messages/${this.id}`, {
          text_defined: text_defined,
        });
        modal.closeModal();
        this.page = 1;
        await this.getAll({});
        this.length = this.result.count;
      } catch (error) {
        modal.closeModal();
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.retry = false;
        errorMessage.message =
          error.response.data.message != null
            ? error.response.data.message
            : errorMessage.message;
        console.log(error);
      }
    },
    async deleteDefinedMessage(id) {
      const modal = useModalStore();
      try {
        await axios.delete(`defined_message/${id}`);
        modal.closeModal();
        this.page = 1;
        await this.getAll({});
        this.length = this.result.count;
      } catch (error) {
        modal.closeModal();
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.message = error.response.data.message;
        console.log(error);
      }
    },
  },
});
