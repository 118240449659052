import { getData } from "@/utils/fetch";
import debounce from "debounce-promise";
import { defineStore } from "pinia";
import axios from "../config";
import { useModalStore } from "./modal.store";
import { useErrorStore } from "./error.store";
import { reactive } from "vue";

let debouncedFetch = debounce(getData, 500);

export const useAreaGeofenceStore = defineStore({
  id: "areaGeofence",
  state: () => ({
    area: {},
    areaId: "",
    dataById: reactive({
      area: "",
      kategori_area: "",
      koordinat: {},
      geomKoordinat: {},
    }),
    geofence: {},
    drawArea: {},
    coordinate: [],
    type: "",
    geoEdited: {},
  }),
  getters: {
    getDataArea(state) {
      return state.area.data;
    },
  },
  actions: {
    async addArea(id, area, kategori_area, koordinat) {
      const modal = useModalStore();
      try {
        await axios.post("area_geofence", {
          id: id,
          area: area,
          kategori_area: kategori_area,
          koordinat: koordinat,
        });
        modal.closeModal();
        await this.getAll({});
      } catch (error) {
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
        console.log(error);
      }
    },
    async getAll(params) {
      this.area = { loading: true };

      const dataparams = {
        limit: params?.limit || 999,
        offset: params?.offset || 0,
        order: params?.order || "createdAt",
        ordertype: params?.ordertype || "ASC",
      };

      try {
        const result = await debouncedFetch("area_geofence", dataparams);
        this.area = { ...result.data };
      } catch (error) {
        console.log(error);
        this.area = { error };
      }
    },
    async areaById(id) {
      if (id) {
        this.areaId = id;
        try {
          const result = await debouncedFetch(`area_geofence/${id}`);
          this.dataById.area = result.data.area;
          this.dataById.kategori_area = result.data.kategori_area;

          if (result.data.koordinat.geometry.type == "Point") {
            this.dataById.koordinat = [
              {
                lat: result.data.koordinat.geometry.coordinates[1],
                long: result.data.koordinat.geometry.coordinates[0],
              },
            ];
          } else if (result.data.koordinat.geometry.type == "LineString") {
            const data = [result.data.koordinat.geometry.coordinates];
            this.dataById.koordinat = data[0].map((x) => ({
              lat: x[1],
              long: x[0],
            }));
          } else {
            this.dataById.koordinat =
              result.data.koordinat.geometry.coordinates[0].map((x) => ({
                lat: x[1],
                long: x[0],
              }));
          }

          this.dataById.geomKoordinat = result.data.koordinat;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async updateArea(area, kategori_area, koordinat) {
      const modal = useModalStore();
      try {
        await axios.patch(`area_geofence/${this.areaId}`, {
          area: area,
          kategori_area: kategori_area,
          koordinat: koordinat,
        });
        modal.closeModal();
        await this.getAll({});
      } catch (error) {
        console.log(error);
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },
    async areaCoordinate() {
      try {
        const areea = await axios.get("area_geofence");
        this.geofence = { ...areea.data };
      } catch (error) {
        console.log(error);
      }
    },
  },
});
