<template>
  <div
    class="bg-greenSide w-full flex justify-between items-center text-white font-medium py-2 px-3 rounded-t-sm"
  >
    Pengaturan Alarm
  </div>
  <div v-if="alarm.rows?.length">
    <table class="w-full text-sm text-left text-gray-500 font-medium">
      <tbody>
        <tr class="bg-white border-b text-gray-900">
          <th scope="row" class="py-2.5 px-6 text-center">#</th>
          <th scope="row" class="py-2.5 px-6">Jenis Alarm</th>
          <th scope="row" class="py-2.5 px-6 text-center">Notifikasi</th>
          <th scope="row" class="py-2.5 px-6 text-center">Status Respon</th>
          <th scope="row" class="py-2.5 px-6">Template Respon</th>
          <th scope="row" class="py-2.5 px-6 text-center">Aksi</th>
        </tr>

        <tr
          class="bg-white border-b"
          v-for="(data, index) in alarm.rows"
          :key="data.id"
        >
          <td class="py-2.5 px-6 text-center">{{ index + 1 }}</td>
          <td class="py-2.5 px-6">{{ data.jenis_alarm }}</td>
          <td
            class="py-2.5 px-6 text-center"
            :class="data.notifikasi ? 'text-green-600' : 'text-red-600'"
          >
            {{ data.notifikasi ? "Ya" : "Tidak" }}
          </td>
          <td
            class="py-2.5 px-6 text-center"
            :class="data.status_respon ? 'text-green-600' : 'text-red-600'"
          >
            {{ data.status_respon ? "Ya" : "Tidak" }}
          </td>
          <td class="py-2.5 px-6">
            <div v-if="data.template_respon == null">-</div>
            <div v-else class="flex items-center space-x-3">
              <div v-for="respon in data.template_respon" :key="respon">
                <span
                  class="bg-gray-300 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"
                >
                  {{ respon }}
                </span>
              </div>
            </div>
          </td>
          <td class="py-2.5 px-6 text-center">
            <button
              class="bg-green-700 hover:bg-green-800 rounded-md p-1"
              @click="editAlarm(data.id)"
            >
              <IconEdit size="w-3.5 h-3.5" color="fill-white" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <EditAlarm :id="5" />
  </div>
  <LoadingLottie v-if="alarm.loading" />
  <div class="flex justify-center" v-if="alarm.rows?.length == 0">
    <NoData size="w-full md:w-5/12" />
  </div>
  <AlertErrorComponent
    v-if="alarm.error"
    :message="alarm.error.response.data.message"
  />
</template>

<script>
import IconEdit from "@/components/icons/IconEdit.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import EditAlarm from "@/components/EditAlarm.vue";
import { useAlarmStore, useModalStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

export default {
  components: {
    IconEdit,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
    EditAlarm,
  },
  setup() {
    const alarmStore = useAlarmStore();
    const { alarm } = storeToRefs(alarmStore);
    const modal = useModalStore();

    onMounted(async () => {
      await alarmStore.getAll();
    });

    const editAlarm = async (id) => {
      await alarmStore.getById(id);
      modal.showModal(5);
    };

    return {
      alarm,
      modal,
      editAlarm,
    };
  },
};
</script>

<style></style>
