<template>
  <ModalComponent :id="id" title="Register Mobile User">
    <template #body>
      <Form @submit="register" :validation-schema="schema" v-slot="{ errors }">
        <div class="p-6 space-y-3">
          <InputWithLabel
            label="First Name"
            type="text"
            name="firstname"
            :error="errors.firstname"
          />
          <InputWithLabel label="Last Name" type="text" name="lastname" />
          <InputWithLabel
            label="Phone Number"
            type="text"
            name="phonenumber"
            :error="errors.phonenumber"
          />
          <SelectSearchWithLabel
            label="Modem ID"
            labelColor="text-black"
            placeholder="Select Modem ID"
            :data="listModem.rows"
            :optionsValue="'name'"
            :optionsKey="'modem_id'"
            v-model:formControlName="modemid"
          />
          <div class="">
            <InputIsActive name="isactive" v-model="isactive" ket="Is Active" />
          </div>
        </div>

        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary">
              Register
            </ButtonComponent>
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import InputIsActive from "@/components/InputIsActive.vue";
import SelectSearchWithLabel from "@/components/SelectSearchWithLabel.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import * as Yup from "yup";
import { Form } from "vee-validate";
import { useMobileUserStore, useModemStore } from "@/store";
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    ModalComponent,
    Form,
    InputWithLabel,
    InputIsActive,
    SelectSearchWithLabel,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const mobileUserStore = useMobileUserStore();
    const modemStore = useModemStore();
    const { listModem } = storeToRefs(modemStore);

    const isactive = ref(false);
    const modemid = ref("");

    const schema = Yup.object().shape({
      firstname: Yup.string().required("First name is required"),
      phonenumber: Yup.string().required("Phone number is required"),
    });

    const getAllModem = async () => {
      await modemStore.getAllModem({ limit: 999, offset: 0 });
    };

    onMounted(() => {
      getAllModem();
    });

    const register = async (value) => {
      const { firstname, lastname, phonenumber } = value;
      await mobileUserStore.registerMobileUser(
        firstname,
        lastname,
        modemid.value,
        isactive.value,
        phonenumber
      );

      modemid.value = "";
      isactive.value = false;
    };

    return {
      schema,
      modemid,
      isactive,
      listModem,
      register,
    };
  },
};
</script>

<style></style>
