<template>
  <SidebarRight>
    <div v-if="!listModem.loading">
      <div class="px-8">
        <TitleSubIconGap
          :title="dataModem.name"
          titleColor="text-white"
          :subtitle="dataModem.modem_id"
          subtitleColor="text-white"
        >
          <IconModem color="white" size="w-9 h-9" />
        </TitleSubIconGap>

        <TittleBetween title="Mobile User" addClass="pt-8 pb-4" />
      </div>

      <div class="overflow-auto max-h-[55vh]">
        <div class="px-8 space-y-4" v-if="dataModem.mobile_users.length != 0">
          <TitleSubIcon
            v-for="db in dataModem.mobile_users"
            :key="db.mobile_id"
            :title="db.first_name"
            titleColor="text-white"
            titleSize="text-sm"
            subtitleColor="text-white"
            :subtitle="db.mobile_id"
            :subtitle2="db.phone_number"
          >
            <IconMobileUser color="white" :size="'w-6 h-6'" />
          </TitleSubIcon>
        </div>

        <div
          class="px-8 text-sm flex items-center justify-center text-white"
          v-if="dataModem.mobile_users.length == 0"
        >
          No Data
        </div>
      </div>

      <div class="px-8">
        <TittleBetween title="Basic Info" addClass="pt-8 pb-4" />
      </div>

      <div class="flex flex-col space-y-5 px-8 [&_svg]:w-5 [&_svg]:h-5">
        <div class="flex justify-between items-center">
          <TextIconGroup
            :textWhite="true"
            :text="dataModem.tele_id != null ? dataModem.tele_id : '-'"
            textSize="text-xs"
          >
            <IconTelegram />
          </TextIconGroup>

          <TextIconGroup
            :textWhite="true"
            :text="dataModem.tele_name != null ? dataModem.tele_name : '-'"
            textSize="text-xs"
          />
        </div>

        <div class="flex justify-between items-center">
          <TextIconGroup
            :textWhite="true"
            :text="dataModem.client_id != null ? dataModem.client_id : '-'"
            textSize="text-xs"
          >
            <IconClient color="white" />
          </TextIconGroup>

          <TextIconGroup
            :textWhite="true"
            :text="
              dataModem.client_secret != null ? dataModem.client_secret : '-'
            "
            textSize="text-xs"
          />
        </div>

        <div class="flex justify-between items-center">
          <TextIconGroup
            :textWhite="true"
            :text="date(dataModem.createdAt)"
            textSize="text-xs"
          >
            <IconDate />
          </TextIconGroup>

          <TextIconGroup
            :textWhite="true"
            :text="date(dataModem.updatedAt)"
            textSize="text-xs"
          />
        </div>
      </div>
    </div>
    <SkeletonDetailComponent v-if="listModem.loading" />
  </SidebarRight>
</template>

<script>
import SidebarRight from "@/components/SidebarRight.vue";
import TitleSubIconGap from "@/components/TitleSubIconGap.vue";
import TitleSubIcon from "@/components/TitleSubIcon.vue";
import IconModem from "@/components/icons/IconModem.vue";
import TittleBetween from "@/components/TittleBetween.vue";
import TextIconGroup from "@/components/TextIconGroup.vue";
import IconDate from "@/components/icons/IconDate.vue";
import SkeletonDetailComponent from "@/components/SkeletonDetailComponent.vue";
import IconMobileUser from "@/components/icons/IconMobileUser.vue";
import IconTelegram from "@/components/icons/IconTelegram.vue";
import IconClient from "@/components/icons/IconClient.vue";
import moment from "moment";
import { useModemStore, useSidebarStore } from "@/store";
import { computed, onMounted } from "vue";
import { storeToRefs } from "pinia";

export default {
  props: {
    modemId: Number,
  },
  components: {
    SidebarRight,
    TitleSubIconGap,
    TitleSubIcon,
    IconModem,
    TittleBetween,
    TextIconGroup,
    IconDate,
    SkeletonDetailComponent,
    IconMobileUser,
    IconTelegram,
    IconClient,
  },
  setup() {
    const sidebar = useSidebarStore();
    const modemStore = useModemStore();
    const { listModem } = storeToRefs(modemStore);

    const dataModem = computed(() => sidebar.getData);

    onMounted(() => {
      modemStore.getAllModem({});
    });

    const date = (date) => {
      return moment(date).format("DD MMM YYYY");
    };

    return {
      dataModem,
      listModem,
      date,
    };
  },
};
</script>

<style></style>
