import debounce from "debounce-promise";
import axios from "../config";
import { defineStore } from "pinia";
import { getData } from "@/utils/fetch";
import { useModalStore } from "./modal.store";
import { useErrorStore } from "./error.store";
import { reactive } from "vue";

let debouncedFetch = debounce(getData, 500);

export const useSeahubDevice = defineStore({
  id: "seahubDevice",
  state: () => ({
    seahubDevice: {},
    byId: reactive({
      serialNumber: "",
      mmsi: "",
      mode: 0,
    }),
    deviceId: "",
    page: 1,
    length: 0,
    size: 10,
    q: "",
  }),
  getters: {
    getDataDevice(state) {
      return state.seahubDevice.data;
    },
  },
  actions: {
    async registerDevice(serialNumber, mmsi, mode) {
      const modal = useModalStore();
      try {
        await axios.post("seahub_device", {
          serial_number: serialNumber,
          mmsi: mmsi,
          mode: mode,
        });
        modal.closeModal();
        this.page = 1;
        await this.getAllDevice({});
        this.length = this.seahubDevice.count;
      } catch (error) {
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },

    async getAllDevice(params) {
      this.seahubDevice = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        order: params?.order || "createdAt",
        ordertype: params?.ordertype || "ASC",
        q: params?.q,
      };

      try {
        const data = await debouncedFetch("seahub_device", dataparams);
        this.seahubDevice = { ...data.data };
      } catch (error) {
        this.seahubDevice = { error };
      }
    },

    async getDeviceById(device_id) {
      if (device_id) {
        this.deviceId = device_id;
        try {
          const res = await axios.get(`seahub_device/${device_id}`);
          this.byId.serialNumber = res.data.serial_number;
          this.byId.mmsi = res.data.mmsi;
          this.byId.mode = res.data.mode;
        } catch (error) {
          console.log(error);
        }
      }
    },

    async updateDevice(serialNumber, mmsi, mode) {
      const modal = useModalStore();
      try {
        await axios.patch(`seahub_device/${this.deviceId}`, {
          serial_number: serialNumber,
          mmsi: mmsi,
          mode: mode,
        });
        modal.closeModal();
        this.page = 1;
        await this.getAllDevice({});
        this.length = this.seahubDevice.count;
      } catch (error) {
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },

    async deleteDevice(device_id) {
      const modal = useModalStore();
      try {
        await axios.delete(`seahub_device/${device_id}`);
        modal.closeModal();
        this.page = 1;
        await this.getAllDevice({});
        this.length = this.seahubDevice.count;
      } catch (error) {
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.message = error.response.data.message;
        console.log(error);
      }
    },
  },
});
