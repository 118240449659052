<template>
  <svg
    width="16"
    height="20"
    :class="[addClass]"
    viewBox="0 0 16 20"
    fill="#14444f"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 9H7V12H4V14H7V17H9V14H12V12H9V9ZM10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM14 18H2V2H9V7H14V18Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    addClass: String,
  },
};
</script>
