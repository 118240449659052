<template>
  <svg xmlns="http://www.w3.org/2000/svg" :class="size" viewBox="0 0 24 24">
    <g
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    >
      <rect width="20" height="8" x="2" y="14" rx="2" />
      <path
        d="M6.01 18H6m4.01 0H10m5-8v4m2.84-6.83a4 4 0 0 0-5.66 0m8.48-2.83a8 8 0 0 0-11.31 0"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "w-7 h-7",
    },
    color: {
      type: String,
      default: "#062346",
    },
  },
};
</script>

<style></style>
