<template>
  <SelectMultiWithLabel
    :label="'Kapal'"
    :data="dataFetch"
    :optionsValue="'name'"
    :optionsKey="'mmsi'"
    v-model:formControlName="formName"
    placeholder="Pilih Kapal"
    :loading="loading"
    :labelColor="labelColor"
    ref="selectMulti"
  />
</template>

<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import SelectMultiWithLabel from "./SelectMultiWithLabel.vue";
// import axios from "axios";
import { useVesselReportStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  props: {
    formControlName: Array,
    labelColor: String,
  },
  components: {
    SelectMultiWithLabel,
  },
  setup(props, { emit }) {
    const dataFetch = ref([]);
    const loading = ref(false);
    const selectMulti = ref();
    const vesselReportStore = useVesselReportStore();
    const { dataKapal } = storeToRefs(vesselReportStore);

    const formName = computed({
      get() {
        return props.formControlName;
      },
      set(value) {
        emit("update:formControlName", value);
      },
    });

    // const fetchData = async () => {
    //   loading.value = true;
    //   try {
    //     const result = await axios.post(
    //       "http://183.91.67.210:50537/api/latest",
    //       {
    //         port: "[16,18]",
    //       }
    //     );
    //     const data = result.data.data.filter((item) => item.name != null);
    //     dataFetch.value = data;
    //     loading.value = false;
    //   } catch (error) {
    //     console.log(error);
    //     loading.value = false;
    //   }
    // };

    const reset = () => {
      selectMulti.value.reset();
    };

    onMounted(() => {
      // fetchData();
    });

    watchEffect(() => {
      loading.value = true;
      const data = dataKapal.value.filter((item) => item.name != null);
      dataFetch.value = data;
      loading.value = false;
    });

    return {
      dataFetch,
      loading,
      formName,
      reset,
      selectMulti,
    };
  },
};
</script>

<style></style>
