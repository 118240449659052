import { defineStore } from "pinia";
import debounce from "debounce-promise";
import { getData } from "@/utils/fetch";
import { reactive } from "vue";

let debouncedFetch = debounce(getData, 500);

export const useRawSkywaveStore = defineStore({
  id: "rawSkywave",
  state: () => ({
    skywave: {},
    filter: reactive({
      date: [],
    }),
  }),
  getters: {
    getDataSkywave(state) {
      return state.skywave.data;
    },
  },
  actions: {
    async getAll(params) {
      this.skywave = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        fromDate: params?.fromDate,
        toDate: params?.toDate,
        order: params?.order || "message_id",
        ordertype: params?.ordertype || "ASC",
        q: params?.q,
      };

      try {
        const data = await debouncedFetch("raw_skywave", dataparams);
        this.skywave = { ...data.data };
      } catch (error) {
        this.skywave = { error };
      }
    },
  },
});
