<template>
  <ModalComponent title="User Detail">
    <template #body>
      <div class="px-4 py-6">
        <table
          class="border border-collapse w-full text-sm text-left text-gray-500"
        >
          <tbody>
            <tr class="bg-white hover:bg-gray-100">
              <th class="border p-2">First Name</th>
              <td class="border p-2">{{ user.firstName ? user.firstName : user.given_name  }}</td>
            </tr>
            <tr class="bg-white hover:bg-gray-100">
              <th class="border p-2">Last Name</th>
              <td class="border p-2">{{ user.lastName }}</td>
            </tr>
            <tr class="bg-white hover:bg-gray-100">
              <th class="border p-2">Email</th>
              <td class="border p-2">{{ user.email }}</td>
            </tr>
            <tr class="bg-white hover:bg-gray-100">
              <th class="border p-2">Role</th>
              <td class="border p-2">{{ user.role }}</td>
            </tr>
            <tr class="bg-white hover:bg-gray-100">
              <th class="border p-2">Is Email Verified</th>
              <td class="border p-2">{{ user.IsEmailVerified }}</td>
            </tr>
            <tr class="bg-white hover:bg-gray-100">
              <th class="border p-2">Registered At</th>
              <td class="border p-2">{{ date(user.createdAt) }}</td>
            </tr>
            <!-- <tr class="bg-white hover:bg-gray-100">
              <th class="border p-2">Updated At</th>
              <td class="border p-2">{{ date(user.updatedAt) }}</td>
            </tr> -->
          </tbody>
        </table>
      </div>
      <ModalFooter colorClose="btn-darkGray" />
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import { useAuthStore } from "@/store";
import { storeToRefs } from "pinia";
import moment from "moment";

export default {
  components: {
    ModalComponent,
    ModalFooter,
  },
  setup() {
    const authStore = useAuthStore();
    const { user } = storeToRefs(authStore);

    const date = (date) => {
      return moment(date).format("DD MMMM YYYY, HH:mm:ss");
    };

    return {
      user,
      date,
    };
  },
};
</script>

<style></style>
