<template>
  <div class="w-full h-[585px] flex flex-col bg-gray-100">
    <div class="h-14 py-2 px-3 bg-greenSide flex items-center">
      <h1 class="text-base text-white font-semibold">Contact</h1>
    </div>
    <div class="px-4 pt-2">
      <SearchInput addClass="rounded-full h-9" id="contact" @input="search" />
    </div>
    <SkeletonContact v-show="result.loading" />
    <div class="overflow-y-auto flex-1 pt-2" v-if="result.rows?.length">
      <div
        class="px-3 py-2 flex items-center cursor-pointer"
        v-for="(contact, index) in result.rows"
        :key="index"
        :class="{ 'bg-gray-200': contact.chat_id == id }"
        @click="open(contact)"
      >
        <div class="rounded-full bg-gray-400 p-1">
          <IconUser color="#E5E7EB" size="w-6 h-6" />
        </div>
        <div class="flex-1 border-b h-12 border-gray-200 px-3">
          <div class="text-gray-600 flex justify-between">
            <p
              class="flex-1 text-ellipsis whitespace-nowrap overflow-hidden w-[70px] md:w-[140px] lg:w-[160px] xl:w-[200px]"
            >
              {{ contact.first_name }} {{ contact.last_name }}
            </p>
            <div v-for="count in unread" :key="count.contact">
              <span
                v-if="count.contact == contact.chat_id && count.unread != 0"
                class="bg-greenSide text-white text-xs font-medium px-1.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300"
                >{{ count.unread }}</span
              >
            </div>
          </div>
          <div v-for="last in last_chat" :key="last.id_message">
            <div
              v-if="last.contact == contact.chat_id"
              class="flex justify-between text-gray-500"
              :class="readState[last.contact] ? 'text-greenSide font-bold' : ''"
            >
              <div class="flex gap-1 justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  data-slot="icon"
                  class="w-4 h-4"
                  v-if="last.status == 1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>
                <small
                  class="text-clip whitespace-nowrap overflow-hidden w-[70px] md:w-[140px] lg:w-[160px] xl:w-[200px]"
                >
                  {{
                    chatStore.filterMessage(last.message) != ""
                      ? chatStore.filterMessage(last.message)
                      : last.message
                  }}
                </small>
              </div>
              <small>{{ date(last.createdAt) }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="h-full flex justify-center pt-10"
      v-if="result.rows?.length == 0"
    >
      <NoData size="w-8/12" />
    </div>
  </div>
</template>

<script>
import SkeletonContact from "@/components/SkeletonContact.vue";
import SearchInput from "@/components/SearchInput.vue";
import IconUser from "@/components/icons/IconUser.vue";
import NoData from "@/components/NoData.vue";
import { useChatStore, useTeleUserStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import moment from "moment";
import axios from "../../config";

export default {
  components: {
    SkeletonContact,
    SearchInput,
    IconUser,
    NoData,
  },
  setup() {
    const teleUserStore = useTeleUserStore();
    const { result } = storeToRefs(teleUserStore);
    const chatStore = useChatStore();
    const { id, unread, last_chat, readState } = storeToRefs(chatStore);

    onMounted(async () => {
      await teleUserStore.getAll({ limit: 999 });
      await chatStore.getChat();
      await chatStore.lastChat();
      await chatStore.unreadCount();
    });

    const open = async (value) => {
      chatStore.$patch(async (state) => {
        if (!state.open) {
          state.open = !state.open;
        }

        if (state.id != value.chat_id) {
          state.id = value.chat_id;
          state.contactById = value;
          await axios.delete(`unread_chat/${value.chat_id}`);
          await chatStore.unreadCount();
        }

        state.msg.scrollTo(0, state.msg.scrollHeight);
      });
    };

    const date = (date) => {
      return moment(date).format("HH:mm");
    };

    const search = async (value) => {
      await teleUserStore.getAll({ offset: 0, q: value.target.value });
    };

    return {
      result,
      id,
      unread,
      chatStore,
      open,
      date,
      search,
      last_chat,
      readState,
    };
  },
};
</script>

<style></style>
