<template>
  <div class="pt-5 pb-5">
    <div
      class="overflow-x-auto relative shadow-md rounded-lg"
      v-if="users.rows?.length"
    >
      <table class="w-full text-sm text-left text-gray-500">
        <tbody>
          <tr
            class="bg-white border-b"
            v-for="data in users.rows"
            :key="data.id"
            :class="[
              { 'hover:bg-lightGray': selected != data.id },
              { 'bg-gray100': selected == data.id },
            ]"
          >
            <th scope="row" class="py-3 px-6 font-medium text-gray-900">
              <div class="flex items-center space-x-3">
                <div class="bg-greenSide rounded-full p-2">
                  <IconUser :color="'white'" :size="'w-5 h-5'" />
                </div>
                <p class="text-md">{{ data.firstName }}</p>
              </div>
            </th>
            <td class="py-3 px-2 md:px-0">{{ data.lastName }}</td>
            <td class="py-3 px-2 md:px-0">{{ data.email }}</td>
            <td class="py-3 px-2 md:px-0 text-center">
              <div v-if="data.role == 'user'">
                <span
                  class="bg-indigo-200 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-lg dark:bg-indigo-700 dark:text-indigo-300 border border-indigo-200"
                  >{{ data.role.toUpperCase() }}</span
                >
              </div>
              <div v-else>
                <span
                  class="bg-red-200 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-lg dark:bg-red-700 dark:text-red-400 border border-red-200"
                  >{{ data.role.toUpperCase() }}</span
                >
              </div>
            </td>
            <td class="py-3 px-3 md:px-0">
              <div
                class="flex justify-center space-x-2.5 [&_svg]:w-3 [&_svg]:h-3"
              >
                <button
                  class="bg-blue-800 hover:bg-blue-900 rounded-full p-1"
                  @click="onClick(data)"
                >
                  <IconInfo />
                </button>
                <button
                  class="bg-amber hover:bg-amber500 rounded-full cursor-pointer p-1"
                  @click="onEdit(data.id)"
                >
                  <IconEdit color="fill-white" />
                </button>
                <button
                  class="bg-red-700 hover:bg-red-800 rounded-full cursor-pointer p-1"
                  v-if="user.id != data.id"
                  @click="onDelete(data.id)"
                >
                  <IconTrash />
                </button>
                <button
                  class="bg-red-500 rounded-full cursor-not-allowed p-1"
                  v-if="user.id == data.id"
                  @click="onDelete(data.id)"
                  disabled
                >
                  <IconTrash />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent
        v-model:length="length"
        v-model:size="size"
        v-model:page="page"
        @onSelectChange="changePage"
      />
    </div>
    <LoadingLottie v-if="users.loading" />
    <div class="flex justify-center" v-if="users.rows?.length == 0">
      <NoData size="w-full md:w-5/12" />
    </div>
    <AlertErrorComponent
      v-if="users.error"
      :message="users.error.response.data.message"
    />
  </div>
  <EditUser :id="2" />
  <DeleteConfirmation
    :id="3"
    :userId="selectUserId"
    @onDeleteData="deleteUser"
  />
</template>

<script>
import IconUser from "@/components/icons/IconUser.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import EditUser from "./EditUser.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import {
  useAuthStore,
  useModalStore,
  useSidebarStore,
  useUserStore,
} from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import moment from "moment";

export default {
  props: {
    userId: String,
  },
  components: {
    IconUser,
    IconInfo,
    IconEdit,
    IconTrash,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
    EditUser,
    PaginationComponent,
    DeleteConfirmation,
  },
  setup() {
    const modal = useModalStore();
    const userStore = useUserStore();
    const sidebarStore = useSidebarStore();
    const authStore = useAuthStore();
    const { user } = storeToRefs(authStore);
    const { users, selected, page, length } = storeToRefs(userStore);

    const size = ref(10);
    const offset = ref(0);
    const selectUserId = ref("");

    onMounted(async () => {
      userStore.dateFilter = [];
      userStore.page = 1;
      await userStore.getAll({ offset: offset.value });
      userStore.length = userStore.users.count;
      userStore.select();
    });

    const onClick = (value) => {
      userStore.selected = value.id;
      sidebarStore.$patch((state) => {
        if (!state.open) {
          state.open = !state.open;
        }

        if (state.id != value.id) {
          state.id = value.id;
          state.data = value;
        }
      });
    };

    const changePage = async (value) => {
      userStore.page = value;
      offset.value = (userStore.page - 1) * size.value;
      let dataParams = { offset: offset.value };

      if (userStore.dateFilter != null && userStore.dateFilter.length != 0) {
        dataParams = {
          ...dataParams,
          fromDate: moment(userStore.dateFilter[0]).format("YYYY-MM-DD"),
          toDate: moment(userStore.dateFilter[1]).format("YYYY-MM-DD"),
        };
      }
      if (userStore.dateFilter == null) {
        dataParams;
      }

      if (userStore.q != "") {
        dataParams = {
          ...dataParams,
          q: userStore.q,
        };
      }
      await userStore.getAll(dataParams);
      userStore.select();
    };

    const onEdit = async (id) => {
      modal.showModal(2);
      userStore.selected = id;
      await userStore.userById(id);
    };

    const onDelete = async (id) => {
      modal.showModal(3);
      selectUserId.value = id;
    };

    const deleteUser = async () => {
      await userStore.deleteUser(selectUserId.value);
      userStore.select();
    };

    return {
      users,
      user,
      size,
      length,
      page,
      selected,
      onClick,
      changePage,
      onEdit,
      onDelete,
      deleteUser,
      selectUserId,
    };
  },
};
</script>

<style></style>
