<template>
  <div
    class="h-12 px-3 py-2 bg-[#D9D9D9] flex flex-row justify-between items-center"
  >
    <p>{{ byMMSI.name }}</p>
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="w-6 h-6 cursor-pointer"
        @click="toShip"
      >
        <path
          fill="#0a0a0b"
          d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0zm128-80a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { useChatVesselStore, useErrorStore } from "@/store";
import { storeToRefs } from "pinia";
import router from "@/router";

export default {
  setup() {
    const chatVesselStore = useChatVesselStore();
    const { byMMSI } = storeToRefs(chatVesselStore);
    const errorStore = useErrorStore();

    const toShip = () => {
      console.log(Object.keys(chatVesselStore.ship).length != 0);
      if (Object.keys(chatVesselStore.ship).length != 0) {
        if (
          chatVesselStore.ship.find(
            (item) => item["mmsi"] === byMMSI.value.mmsi
          )
        ) {
          const data = chatVesselStore.ship.find(
            (item) => item["mmsi"] === byMMSI.value.mmsi
          );
          localStorage.setItem("centerLat", data.lat);
          localStorage.setItem("centerLng", data.lon);
          localStorage.setItem("zoom", 15);
          router.push("/maps");
        } else {
          console.log("no data");
          errorStore.open = true;
          errorStore.retry = false;
          errorStore.message = "Data kapal tidak tersedia";
        }
      }
    };

    return {
      byMMSI,
      toShip,
    };
  },
};
</script>

<style></style>
