import mqtt from "precompiled-mqtt";
import { defineStore } from "pinia";
import axios from "../config";
import { reactive } from "vue";
import { useAuthStore } from "./auth.store";

export const useChatVesselStore = defineStore({
  id: "vesselChat",
  state: () => ({
    id: 0,
    open: false,
    ship: {},
    ships: null,
    byMMSI: {},
    loading: false,
    chatHistory: [],
    lastChat: [],
    unreadChat: [],
    readState: [],
    URL: "ws://test.mosquitto.org:8081",
    clien: { connected: false },
    options: reactive({
      clientId: "vts" + Math.random().toString(16).substring(2, 8),
      clean: true,
      connectTimeout: 30 * 1000,
      reconnectPeriod: 4000,
      keepAlive: 240,
    }),
    message: "",
    roomChat: null,
    map: null,
  }),
  getters: {},
  actions: {
    async createConnection() {
      const auth = JSON.parse(localStorage.getItem("token"));
      if (auth) {
        this.clien = await mqtt.connect(this.URL, this.options);
        this.clien.on("connect", () => {
          console.log(`Connect to ${this.URL} success`);
        });
        this.clien.on("error", (err) => {
          console.log(`Connection error ${err}`);
        });
      }
    },

    disconnect() {
      if (this.clien.connected) {
        try {
          this.clien.end(false, () => {
            console.log("Disconnected successfully");
          });
        } catch (error) {
          console.log("Disconnect error: ", error);
        }
      }
    },

    subscribe() {
      const topic = `vts/in/message/#`;
      this.clien.subscribe(topic, (error) => {
        if (error) {
          console.log("Subscribe error: ", error);
        }
        this.clien.on("message", async (topic, message) => {
          console.log("Subscribe message ", message.toString());
          const data = JSON.parse(message.toString());
          try {
            await axios.post("vessel_chat_history", {
              message: data.message,
              mmsi: data.mmsi,
              name: data.name,
              sender_id: data.sender_id,
              sender_name: data.sender_name,
              receiver_id: data.receiver_id,
              receiver_name: data.receiver_name,
            });
            if (this.id != data.sender_id) {
              await axios.post("unread_vessel_chat", {
                mmsi: data.mmsi,
                name: data.name,
                message: data.message,
              });
            }

            await this.getChatHistory();
            this.getLastChatHistory();
            this.countUnreadChat();

            if (this.roomChat != null) {
              this.roomChat.scrollTo(0, this.roomChat.scrollHeight);
            }
          } catch (error) {
            console.log(error);
          }
        });
      });
    },

    publish() {
      console.log(this.byMMSI);
      const authStore = useAuthStore();
      const topic = "vts/out/message";
      let payload = `{"message": "${this.message}","mmsi": ${this.byMMSI.mmsi}, "name": "${this.byMMSI.name}", "sender_id": "${authStore.user.id}", "sender_name": "${authStore.user.firstName}", "receiver_id": "${this.byMMSI.mmsi}", "receiver_name": "${this.byMMSI.name}"}`;

      if (!this.clien.connected) {
        this.clien.reconnect();
      }

      this.clien.publish(topic, payload, async (error) => {
        if (error) {
          console.log("Publish error: ", error);
        } else {
          console.log(`Publish message ${payload} to topic ${topic}`);
          const data = JSON.parse(payload);

          try {
            await axios.post("vessel_chat_history", {
              mmsi: data.mmsi,
              name: data.name,
              message: data.message,
              sender_id: data.sender_id,
              sender_name: data.sender_name,
              receiver_id: data.receiver_id,
              receiver_name: data.receiver_name,
            });
            await this.getChatHistory();
            this.getLastChatHistory();
            this.roomChat.scrollTo(0, this.roomChat.scrollHeight);
          } catch (error) {
            console.log(error);
          }
        }
      });
      this.message = "";
    },

    async getShip() {
      try {
        this.loading = true;
        const data = await axios.post("http://183.91.67.210:50537/api/latest", {
          port: "[16, 18]",
        });
        this.ship = data.data.data;
        this.ships = data.data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async getChatHistory() {
      const result = await axios.get("vessel_chat_history");
      this.chatHistory = result.data;
    },

    async getLastChatHistory() {
      const result = await axios.get("vessel_chat_history/last_vessel_chat");
      this.lastChat = result.data;
    },

    async countUnreadChat() {
      const result = await axios.get("unread_vessel_chat");
      this.unreadChat = result.data;
      this.readState = this.unreadChat.reduce((status, data) => {
        status[data.mmsi] = data.total;
        return status;
      }, {});
    },

    filterItems(q, data) {
      var query = q.toLowerCase();
      return data.filter(function (item) {
        if (item.name !== null) {
          return item.name.toLowerCase().indexOf(query) >= 0;
        }
      });
    },
  },
});
