<template>
  <div
    class="bg-greenSide w-full justify-between items-center text-white font-medium py-2 rounded-t-sm flex px-3"
  >
    Daftar Area Geofence

    <button
      class="bg-gray-100 hover:bg-gray-200 rounded-md p-1"
      @click="modal.showModal(1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="#195563"
        class="w-4 h-4"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 4.5v15m7.5-7.5h-15"
        />
      </svg>
    </button>
    <NewAreaGeofence :id="1" />
  </div>
  <div v-if="area.rows?.length" class="-mb-3 max-h-[393px] overflow-y-auto">
    <table class="w-full text-sm text-left text-gray-500 font-medium">
      <tbody>
        <tr class="bg-white border-b text-gray-900">
          <th scope="row" class="py-2.5 px-3 text-center">#</th>
          <th scope="row" class="py-2.5 px-6">Area</th>
          <th scope="row" class="py-2.5 px-6 text-center">Kategori Area</th>
          <th scope="row" class="py-2.5 px-6 text-center">Aksi</th>
        </tr>

        <tr
          class="bg-white border-b"
          v-for="(data, index) in area.rows"
          :key="index"
        >
          <td class="py-2.5 px-3 text-center">{{ index + 1 }}</td>
          <td class="py-2.5 px-6">{{ data.area }}</td>
          <td class="py-2.5 px-6 text-center">{{ data.kategori_area }}</td>
          <td class="py-2.5 px-6 text-center">
            <button
              class="bg-green-700 hover:bg-green-800 rounded-md p-1"
              @click="editArea(data.id)"
            >
              <IconEdit size="w-3.5 h-3.5" color="fill-white" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <EditAreaGeofence :id="3" />
  </div>
  <LoadingLottie v-if="area.loading" />
  <div class="flex justify-center" v-if="area.rows?.length == 0">
    <NoData size="w-full md:w-5/12" />
  </div>
  <AlertErrorComponent
    v-if="area.error"
    :message="area.error.response.data.message"
  />
</template>

<script>
import IconEdit from "@/components/icons/IconEdit.vue";
import NewAreaGeofence from "@/components/NewAreaGeofence.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import EditAreaGeofence from "@/components/EditAreaGeofence.vue";
import { useAreaGeofenceStore, useModalStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

export default {
  components: {
    IconEdit,
    NewAreaGeofence,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
    EditAreaGeofence,
  },
  setup() {
    const modal = useModalStore();
    const areaStore = useAreaGeofenceStore();
    const { area } = storeToRefs(areaStore);

    onMounted(async () => {
      await areaStore.getAll({});
    });

    const editArea = async (id) => {
      await areaStore.areaById(id);
      modal.showModal(3);
    };

    return {
      modal,
      area,
      length,
      editArea,
    };
  },
};
</script>

<style></style>
