<template>
  <div class="main">
    <HeaderTitle title="Seahub Device">
      <IconMobile color="#14444f" size="w-9 h-9" />
    </HeaderTitle>
    <MainContent>
      <div class="pt-5 flex justify-between">
        <SearchInput @input="search" id="device" />
        <ButtonComponent
          vClass="text-xs text-greenPrimary bg-gray-300 hover:bg-[#c6cbd2] focus:ring-0 px-3 py-2"
          @click="modal.showModal(1)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Register Device</span>
        </ButtonComponent>
        <RegisterDevice :id="1" />
      </div>
      <TableDevice />
    </MainContent>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import MainContent from "@/components/MainContent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import SearchInput from "@/components/SearchInput.vue";
import IconMobile from "@/components/icons/IconMobile.vue";
import RegisterDevice from "./RegisterDevice.vue";
import TableDevice from "./TableDevice.vue";
import { useModalStore, useSeahubDevice } from "@/store";
import { ref } from "vue";

export default {
  components: {
    HeaderTitle,
    MainContent,
    ButtonComponent,
    SearchInput,
    IconMobile,
    RegisterDevice,
    TableDevice,
  },
  setup() {
    const modal = useModalStore();
    const deviceStore = useSeahubDevice();

    const offset = ref(0);

    const search = async (value) => {
      offset.value = 0;
      deviceStore.page = 1;

      await deviceStore.getAllDevice({
        offset: offset.value,
        q: value.target.value,
      });
      deviceStore.q = value.target.value;
      deviceStore.length = deviceStore.seahubDevice.count;
    };

    return {
      modal,
      search,
    };
  },
};
</script>

<style></style>
