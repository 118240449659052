<template>
  <CardComponent
    :scale="true"
    :addClass="[
      { 'hover:bg-gray-200': !selected },
      'cursor-pointer border-gray-300',
    ]"
    :selected="selected"
  >
    <div class="flex justify-between">
      <TitleSubIconGap
        :title="firstname"
        :subtitle="lastname"
        subtitleColor="text-gray-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#14444f"
          class="w-12 h-12"
        >
          <path
            fill-rule="evenodd"
            d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
            clip-rule="evenodd"
          />
        </svg>
      </TitleSubIconGap>

      <div>
        <span
          v-if="isactive"
          class="bg-blue-700 text-white text-[10px] font-medium px-1.5 py-0.5 rounded dark:bg-blue-100 dark:text-blue-300"
        >
          Active
        </span>

        <span
          v-if="!isactive"
          class="bg-red-700 text-white text-[10px] font-medium px-1.5 py-0.5 rounded dark:bg-red-100 dark:text-red-300"
        >
          Inactive
        </span>
      </div>
    </div>

    <div class="py-4">
      <hr />
    </div>

    <div class="space-y-4 px-3">
      <div class="flex items-center space-x-3">
        <IconMobileId :color="'#e69400'" />
        <h3 class="text-sm text-gray-600">{{ mobileid }}</h3>
      </div>

      <div class="flex items-center space-x-3">
        <IconDatePlus />
        <h3 class="text-sm text-gray-600">{{ date }}</h3>
      </div>
    </div>
  </CardComponent>
</template>

<script>
import CardComponent from "./CardComponent.vue";
import TitleSubIconGap from "./TitleSubIconGap.vue";
import IconMobileId from "./icons/IconMobileId.vue";
import IconDatePlus from "./icons/IconDatePlus.vue";

export default {
  components: {
    CardComponent,
    TitleSubIconGap,
    IconMobileId,
    IconDatePlus,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    firstname: String,
    lastname: String,
    mobileid: Number,
    date: String,
    isactive: Boolean,
  },
};
</script>

<style></style>
