<template>
  <div class="mb-6">
    <label
      :for="name"
      class="block mb-2 text-sm font-medium"
      :class="[labelColor]"
      >{{ label }}</label
    >
    <SelectMulti
      :data="data"
      :optionsValue="optionsValue"
      :optionsKey="optionsKey"
      v-model:formControlName="formName"
      :placeholder="placeholder"
      :loading="loading"
      ref="selectMulti"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import SelectMulti from "./SelectMulti.vue";

export default {
  components: {
    SelectMulti,
  },
  props: {
    labelColor: {
      type: String,
      default: "text-gray-900",
    },
    name: String,
    label: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    placeholder: String,
    optionsValue: {
      type: String,
      required: true,
    },
    optionsKey: String,
    loading: Boolean,
    formControlName: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectMulti = ref();
    const formName = computed({
      get() {
        return props.formControlName;
      },
      set(value) {
        emit("update:formControlName", value);
      },
    });

    const reset = () => {
      selectMulti.value.reset();
    };

    return {
      formName,
      selectMulti,
      reset,
    };
  },
};
</script>

<style></style>
