<template>
  <SidebarRight>
    <div v-if="!sos.loading">
      <div class="px-8">
        <TitleSubIcon
          :title="data.message_id"
          titleColor="text-white"
          :subtitle="data.mobile_id"
          :subtitle2="data.sin"
          :subtitle3="JSON.parse(data.message_json).Payload.MIN"
          subtitleColor="text-white"
        >
          <IconSOS />
        </TitleSubIcon>
        <TittleBetween addClass="pt-8 pb-5" title="Basic Info" />
      </div>
      <div class="flex flex-col px-8 space-y-5 [&_svg]:w-6 [&_svg]:h-6">
        <!-- Message Size -->
        <TextIconGroup
          :text="data.message_size"
          textSize="text-md"
          :textWhite="true"
        >
          <IconMemory />
        </TextIconGroup>
        <!-- Sender -->
        <TextIconGroup
          :text="JSON.parse(data.message_json).Payload.Fields[0].Value"
          textSize="text-md"
          :textWhite="true"
        >
          <IconSend />
        </TextIconGroup>
        <!-- Destination -->
        <TextIconGroup
          :text="JSON.parse(data.message_json).Payload.Fields[1].Value"
          textSize="text-md"
          :textWhite="true"
        >
          <IconReceived />
        </TextIconGroup>
        <!-- Region Name -->
        <TextIconGroup
          :text="JSON.parse(data.message_json).RegionName"
          textSize="text-md"
          :textWhite="true"
        >
          <IconRegionName />
        </TextIconGroup>
        <!-- Mobile Owner ID -->
        <TextIconGroup
          :text="JSON.parse(data.message_json).MobileOwnerID"
          textSize="text-md"
          :textWhite="true"
        >
          <IconId />
        </TextIconGroup>
        <!-- Customer ID -->
        <TextIconGroup
          :text="JSON.parse(data.message_json).CustomerID"
          textSize="text-md"
          :textWhite="true"
        >
          <IconUser />
        </TextIconGroup>
        <!-- Transport -->
        <TextIconGroup
          :text="JSON.parse(data.message_json).Transport"
          textSize="text-md"
          :textWhite="true"
        >
          <IconTransport />
        </TextIconGroup>
        <!-- Message Sent -->
        <TextIconGroup
          :text="date(JSON.parse(data.message_json).MessageUTC)"
          textSize="text-md"
          :textWhite="true"
        >
          <IconDatePlus />
        </TextIconGroup>
        <!-- Message Receive -->
        <TextIconGroup
          :text="date(JSON.parse(data.message_json).ReceiveUTC)"
          textSize="text-md"
          :textWhite="true"
        >
          <IconDate />
        </TextIconGroup>
      </div>
    </div>
    <SkeletonDetailComponent v-if="sos.loading" />
  </SidebarRight>
</template>

<script>
import SidebarRight from "@/components/SidebarRight.vue";
import TitleSubIcon from "@/components/TitleSubIcon.vue";
import IconSOS from "@/components/icons/IconSOS.vue";
import TittleBetween from "@/components/TittleBetween.vue";
import TextIconGroup from "@/components/TextIconGroup.vue";
import IconMemory from "@/components/icons/IconMemory.vue";
import IconSend from "@/components/icons/IconSend.vue";
import IconReceived from "@/components/icons/IconReceived.vue";
import IconRegionName from "@/components/icons/IconRegionName.vue";
import IconId from "@/components/icons/IconId.vue";
import IconUser from "@/components/icons/IconUser.vue";
import IconTransport from "@/components/icons/IconTransport.vue";
import IconDatePlus from "@/components/icons/IconDatePlus.vue";
import IconDate from "@/components/icons/IconDate.vue";
import SkeletonDetailComponent from "@/components/SkeletonDetailComponent.vue";
import moment from "moment";
import { useSidebarStore, useSOSStore } from "@/store";
import { computed, onMounted } from "vue";
import { storeToRefs } from "pinia";

export default {
  components: {
    SidebarRight,
    TitleSubIcon,
    IconSOS,
    TittleBetween,
    TextIconGroup,
    IconMemory,
    IconSend,
    IconReceived,
    IconRegionName,
    IconId,
    IconUser,
    IconTransport,
    IconDatePlus,
    IconDate,
    SkeletonDetailComponent,
  },
  setup() {
    const sidebar = useSidebarStore();
    const sosStore = useSOSStore();
    const { sos } = storeToRefs(sosStore);

    const data = computed(() => sidebar.getData);
    onMounted(() => {
      sosStore.getAll({});
    });
    const date = (date) => {
      return moment(date).format("DD MMMM YYYY, HH:mm:ss");
    };

    return {
      sos,
      data,
      date,
    };
  },
};
</script>

<style></style>
