<template>
  <SidebarRight>
    <div v-if="!mobileUsers.loading">
      <div class="px-8">
        <div class="flex items-center">
          <TitleSubIconGap
            :title="data.first_name"
            titleColor="text-white"
            :subtitle="data.last_name"
            subtitleColor="text-white"
          >
            <IconUser size="w-10 h-10" />
          </TitleSubIconGap>
        </div>

        <TittleBetween
          title="Basic Info"
          addClass="pt-8 pb-5 flex items-center"
        >
          <div class="flex gap-2">
            <IconEdit
              class="cursor-pointer"
              color="fill-white"
              size="w-5 h-5"
              @click="onEdit(data.mobile_id)"
            />

            <IconTrash
              class="cursor-pointer"
              color="white"
              size="w-5 h-5"
              @click="onDelete(data.mobile_id)"
            />
          </div>
        </TittleBetween>
      </div>

      <div class="flex flex-col px-8 space-y-5">
        <TextIconGroup
          :text="data.mobile_id"
          :textWhite="true"
          textSize="text-md"
        >
          <IconMobileId color="white" size="w-7 h-7" />
        </TextIconGroup>

        <TextIconGroup
          :text="data.mobile_device_id"
          :textWhite="true"
          textSize="text-md"
        >
          <IconMobileDeviceId color="white" size="w-7 h-7" />
        </TextIconGroup>

        <TextIconGroup
          :text="data.modem.name"
          :textWhite="true"
          textSize="text-md"
        >
          <IconModem color="white" size="w-6 h-6" />
        </TextIconGroup>

        <TextIconGroup
          :text="data.phone_number != null ? data.phone_number : '-'"
          :textWhite="true"
          textSize="text-md"
        >
          <IconPhone color="white" size="w-6 h-6" />
        </TextIconGroup>

        <TextIconGroup
          :text="data.is_active ? 'Active' : 'Inactive'"
          :textWhite="true"
          textSize="text-md"
        >
          <IconCheck size="w-6 h-6" />
        </TextIconGroup>

        <TextIconGroup
          :text="date(data.createdAt)"
          :textWhite="true"
          textSize="text-md"
        >
          <IconDatePlus size="w-6 h-6" />
        </TextIconGroup>

        <TextIconGroup
          :text="data.updatedAt != null ? date(data.updatedAt) : '-'"
          :textWhite="true"
          textSize="text-md"
        >
          <IconDate size="w-6 h-6" />
        </TextIconGroup>
      </div>
    </div>

    <SkeletonDetailComponent v-if="mobileUsers.loading" />
  </SidebarRight>

  <EditMobileUser :id="2" />
  <DeleteConfirmation
    :id="3"
    :mobileId="selectMobileId"
    @onDeleteData="onDeleteMobileUser"
  />
</template>

<script>
import SidebarRight from "@/components/SidebarRight.vue";
import TextIconGroup from "@/components/TextIconGroup.vue";
import TittleBetween from "@/components/TittleBetween.vue";
import TitleSubIconGap from "@/components/TitleSubIconGap.vue";
import IconUser from "@/components/icons/IconUser.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconMobileId from "@/components/icons/IconMobileId.vue";
import IconModem from "@/components/icons/IconModem.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import IconDate from "@/components/icons/IconDate.vue";
import IconDatePlus from "@/components/icons/IconDatePlus.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import SkeletonDetailComponent from "@/components/SkeletonDetailComponent.vue";
import EditMobileUser from "./EditMobileUser.vue";
import IconPhone from "@/components/icons/IconPhone.vue";
import IconMobileDeviceId from "@/components/icons/IconMobileDeviceId.vue";
import { useMobileUserStore, useModalStore, useSidebarStore } from "@/store";
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";

export default {
  props: {
    mobileId: Number,
  },
  components: {
    SidebarRight,
    TextIconGroup,
    TittleBetween,
    TitleSubIconGap,
    IconUser,
    IconTrash,
    IconEdit,
    IconMobileId,
    IconModem,
    IconCheck,
    IconDate,
    IconDatePlus,
    DeleteConfirmation,
    SkeletonDetailComponent,
    EditMobileUser,
    IconPhone,
    IconMobileDeviceId,
  },
  setup() {
    const modal = useModalStore();
    const mobileUserStore = useMobileUserStore();
    const sidebar = useSidebarStore();
    const data = computed(() => sidebar.getData);
    const { mobileUsers } = storeToRefs(mobileUserStore);

    const selectMobileId = ref(0);

    const date = (date) => {
      return moment(date).format("DD MMMM YYYY");
    };

    onMounted(() => {
      mobileUserStore.getAll({});
    });

    const onEdit = async (id) => {
      modal.showModal(2);
      await mobileUserStore.getMobileUserById(id);
    };

    const onDelete = async (mobileId) => {
      modal.showModal(3);
      selectMobileId.value = mobileId;
    };

    const onDeleteMobileUser = async () => {
      await mobileUserStore.deleteMobileUser(selectMobileId.value);
      mobileUserStore.$state.length = mobileUserStore.mobileUsers.count;
      mobileUserStore.$state.page = 1;
      sidebar.$patch((state) => {
        if (
          mobileUserStore.mobileUsers.loading ||
          mobileUserStore.mobileUsers?.count != 0
        ) {
          if (screen.width >= 768) {
            state.open = true;
            mobileUserStore.$state.selected =
              mobileUserStore.mobileUsers.rows[0].mobile_id;
          }

          if (state.id != mobileUserStore.mobileUsers.rows[0].mobile_id) {
            state.id = mobileUserStore.mobileUsers.rows[0].mobile_id;
            state.data = mobileUserStore.mobileUsers.rows[0];
          }
        } else {
          state.open = false;
        }
      });
    };

    return {
      mobileUsers,
      data,
      selectMobileId,
      date,
      onEdit,
      onDelete,
      onDeleteMobileUser,
    };
  },
};
</script>

<style></style>
