<template>
  <ModalComponent :id="id" title="Register Seahub Device">
    <template #body>
      <Form @submit="register">
        <div class="p-6 space-y-3">
          <InputWithLabel
            label="Serial Number"
            type="text"
            name="serialNumber"
          />
          <InputWithLabel label="MMSI" type="text" name="mmsi" />
          <InputWithLabel label="Mode" type="text" name="mode" />
        </div>
        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary">
              Register
            </ButtonComponent>
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import { Form } from "vee-validate";
import ModalComponent from "@/components/ModalComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useSeahubDevice } from "@/store";

export default {
  props: {
    id: Number,
  },
  components: {
    ModalComponent,
    Form,
    InputWithLabel,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const seahubDeviceStore = useSeahubDevice();

    const register = async (value) => {
      const { serialNumber, mmsi, mode } = value;
      seahubDeviceStore.registerDevice(serialNumber, mmsi, mode);
    };

    return {
      register,
    };
  },
};
</script>

<style></style>
