import { getData } from "@/utils/fetch";
import debounce from "debounce-promise";
import { defineStore } from "pinia";

let debouncedFetch = debounce(getData, 500);

export const useLogbookStore = defineStore({
  id: "logbook",
  state: () => ({
    logbook: {},
  }),
  getters: {
    getDataLogbook(state) {
      return state.logbook.data;
    },
  },
  actions: {
    async getAll(params) {
      this.logbook = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        order: params?.order || "message_id",
        ordertype: params?.ordertype || "ASC",
        q: params?.q,
        fromDate: params?.fromDate,
        toDate: params?.toDate,
      };

      try {
        const data = await debouncedFetch("logbook", dataparams);
        this.logbook = { ...data.data };
      } catch (error) {
        this.logbook = { error };
      }
    },
  },
});
