<template>
  <div class="pt-5 pb-5">
    <div
      class="overflow-x-auto relative shadow-md rounded-lg"
      v-if="listModem.rows?.length"
    >
      <table class="w-full text-sm text-left text-gray-500">
        <tbody>
          <tr
            class="bg-white border-b"
            v-for="data in listModem.rows"
            :key="data.modem_id"
            :class="[
              { 'hover:bg-lightGray': selected != data.modem_id },
              { 'bg-gray100': selected == data.modem_id },
            ]"
          >
            <th scope="row" class="py-3 px-6 font-medium text-gray-900">
              <div class="flex items-center space-x-3">
                <div class="bg-greenSide rounded-full p-2">
                  <IconModem color="white" size="w-6 h-6" />
                </div>
                <p class="text-md">{{ data.modem_id }}</p>
              </div>
            </th>
            <td class="py-3 px-2 md:px-0">{{ data.name }}</td>
            <td class="py-3 px-2 md:px-0 text-center">
              <div v-if="data.status == '1'">
                <span
                  class="bg-green-200 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-lg dark:bg-green-700 dark:text-green-300 border border-green-200"
                >
                  Active
                </span>
              </div>
              <div v-else>
                <span
                  class="bg-red-200 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-lg dark:bg-red-700 dark:text-red-400 border border-red-200"
                >
                  Inactive
                </span>
              </div>
            </td>
            <td class="py-3 px-2 md:px-0">{{ data.type }}</td>
            <td class="py-3 px-3 md:px-0">
              <div
                class="flex justify-center space-x-2.5 [&_svg]:w-3 [&_svg]:h-3"
              >
                <button
                  class="bg-blue-800 hover:bg-blue-900 rounded-full p-1"
                  @click="onClick(data)"
                >
                  <IconInfo />
                </button>
                <button
                  class="bg-amber hover:bg-amber500 rounded-full cursor-pointer p-1"
                  @click="onEdit(data.modem_id)"
                >
                  <IconEdit color="fill-white" />
                </button>
                <button
                  class="bg-red-700 hover:bg-red-800 rounded-full cursor-pointer p-1"
                  @click="onDelete(data.modem_id)"
                >
                  <IconTrash />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent
        v-model:length="length"
        v-model:size="size"
        v-model:page="page"
        @onSelectChange="changePage"
      />
    </div>
    <LoadingLottie v-if="listModem.loading" />
    <div class="flex justify-center" v-if="listModem.rows?.length == 0">
      <NoData size="w-full md:w-5/12" />
    </div>
    <AlertErrorComponent
      v-if="listModem.error"
      :message="listModem.error.response.data.message"
    />
  </div>
  <EditModem :id="2" />
  <DeleteConfirmation
    :id="3"
    :modemId="selectModemId"
    @onDeleteData="deleteModem"
  />
</template>

<script>
import IconModem from "@/components/icons/IconModem.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import EditModem from "./EditModem.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import moment from "moment";
import { useModalStore, useModemStore, useSidebarStore } from "@/store";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { onMounted } from "vue";

export default {
  props: {
    modemId: String,
  },
  components: {
    IconModem,
    IconEdit,
    IconInfo,
    IconTrash,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
    EditModem,
    PaginationComponent,
    DeleteConfirmation,
  },
  setup() {
    const modal = useModalStore();
    const modemStore = useModemStore();
    const sidebarStore = useSidebarStore();
    const { listModem, length, page, selected } = storeToRefs(modemStore);

    const size = ref(10);
    const offset = ref(0);
    const selectModemId = ref("");

    onMounted(async () => {
      modemStore.dateFilter = [];
      modemStore.page = 1;
      await modemStore.getAllModem({});
      modemStore.length = modemStore.listModem.count;
      modemStore.select();
    });

    const onClick = async (value) => {
      modemStore.selected = value.modem_id;

      sidebarStore.$patch((state) => {
        if (!state.open) {
          state.open = !state.open;
        }

        if (state.id != value.modem_id) {
          state.id = value.modem_id;
          state.data = value;
        }
      });
    };

    const changePage = async (value) => {
      modemStore.page = value;
      offset.value = (modemStore.page - 1) * size.value;
      let dataParams = { offset: offset.value };

      if (modemStore.q != "") {
        dataParams = {
          ...dataParams,
          q: modemStore.q,
        };
      }
      await modemStore.getAllModem(dataParams);
      modemStore.select();
    };

    const onEdit = async (id) => {
      modal.showModal(2);
      modemStore.selected = id;
      await modemStore.getModemById(id);
    };

    const onDelete = async (id) => {
      modal.showModal(3);
      selectModemId.value = id;
    };

    const deleteModem = async () => {
      await modemStore.deleteModem(selectModemId.value);
      modemStore.select();
    };

    const date = (date) => {
      return moment(date).value("DD MMM YYYY");
    };

    return {
      listModem,
      size,
      length,
      page,
      selected,
      selectModemId,
      onClick,
      changePage,
      onEdit,
      onDelete,
      deleteModem,
      date,
    };
  },
};
</script>

<style></style>
