<template>
  <ModalComponent :id="id" title="Register Modem">
    <template #body>
      <Form
        @submit="acceptModal"
        :validation-schema="schema"
        v-slot="{ errors }"
      >
        <div class="p-6 space-y-3 max-h-[450px] overflow-y-auto">
          <InputWithLabel
            label="Modem ID *"
            type="text"
            name="modemid"
            :error="errors.modemid"
          />
          <InputWithLabel
            label="Name *"
            type="text"
            name="name"
            :error="errors.name"
          />

          <div class="mb-4">
            <label
              for="status"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              Status
            </label>

            <div class="flex items-center space-x-5">
              <div v-for="s in statusModem" :key="s.value">
                <div class="flex items-center space-x-2">
                  <input
                    id="status"
                    type="radio"
                    name="status"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    :value="s.value"
                    v-model="status"
                  />
                  <label
                    for="status"
                    class="ms-2 text-sm text-gray-900 dark:text-gray-300"
                  >
                    {{ s.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <label
              for="type"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              Type
            </label>

            <div class="flex items-center space-x-5">
              <div v-for="t in typeModem" :key="t.value">
                <div class="flex items-center space-x-2">
                  <input
                    id="type"
                    type="radio"
                    name="type"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    v-model="type"
                    :value="t.value"
                  />
                  <label
                    for="type"
                    class="ms-2 text-sm text-gray-900 dark:text-gray-300"
                  >
                    {{ t.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <InputWithLabel label="Telegram ID" type="text" name="teleid" />
          <InputWithLabel label="Telegram Name" type="text" name="telename" />
          <InputWithLabel label="Client ID" type="text" name="clientid" />
          <InputWithLabel
            label="Client Secret"
            type="text"
            name="clientsecret"
          />
        </div>

        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary">
              Save
            </ButtonComponent>
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import * as Yup from "yup";
import { Form } from "vee-validate";
import { useModemStore } from "@/store";
import { ref } from "vue";

export default {
  props: {
    id: Number,
  },
  components: {
    ModalComponent,
    ModalFooter,
    ButtonComponent,
    InputWithLabel,
    Form,
  },
  setup() {
    const modemStore = useModemStore();

    const status = ref("");
    const type = ref("");

    const statusModem = [
      { name: "Active", value: "1" },
      { name: "Inactive", value: "0" },
    ];

    const typeModem = [
      { name: "1", value: "1" },
      { name: "2", value: "2" },
      { name: "3", value: "3" },
      { name: "4", value: "4" },
    ];

    const schema = Yup.object().shape({
      modemid: Yup.string().required("Modem ID is required"),
      name: Yup.string().required("Modem name is required"),
    });

    const acceptModal = async (value) => {
      const { modemid, name, teleid, telename, clientid, clientsecret } = value;

      await modemStore.registerModem(
        modemid,
        name,
        status.value,
        type.value,
        teleid,
        telename,
        clientid,
        clientsecret
      );
      status.value = "";
      type.value = "";
    };

    return {
      schema,
      status,
      type,
      statusModem,
      typeModem,
      acceptModal,
    };
  },
};
</script>

<style></style>
