<template>
  <ModalFormGenerator :id="id" title="Add Form Field" @close="close">
    <Form
      id="form"
      @submit="acceptModal"
      :validation-schema="schema"
      v-slot="{ errors }"
    >
      <div class="p-6 space-y-3">
        <InputWithLabel
          label="Name *"
          type="text"
          name="name"
          :error="errors.name"
        />
        <SelectSearchWithLabel
          label="Type"
          :data="types"
          v-model:formControlName="type"
          name="types"
          optionsKey="value"
          optionsValue="label"
          placeholder="Please Select Type Field"
        />
        <div
          class="max-h-36 overflow-y-auto"
          v-if="type == 'radio' || type == 'select' || type == 'checkbox'"
        >
          <p class="text-sm font-medium text-gray-900">Options</p>
          <div class="grid grid-cols-2 items-center">
            <div
              class="flex items-center justify-between"
              v-for="(option, index) in options"
              :key="index"
            >
              <input
                type="text"
                v-model="option.value"
                placeholder="option value"
                class="border-2 border-gray-200 focus:ring-0"
              />
              <div class="flex space-x-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2.5"
                  stroke="#FFA400"
                  class="w-5 h-5 cursor-pointer"
                  @click="addMore"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <IconTrash
                  color="red"
                  size="w-5 h-5"
                  class="cursor-pointer"
                  @click="remove(index)"
                />
              </div>
            </div>
          </div>
        </div>
        <InputWithLabel label="Validation" type="text" name="validation" />
      </div>
      <div
        class="flex justify-end items-center p-6 space-x-2 rounded-b border-t border-gray-200"
      >
        <ButtonComponent
          vClass="bg-greenSide hover:bg-greenPrimary"
          :disabled="errors.name || type == '' ? true : false"
          >Save</ButtonComponent
        >

        <ButtonComponent vClass="btn-white" @click="close"
          >Close</ButtonComponent
        >
      </div>
    </Form>
  </ModalFormGenerator>
</template>

<script>
import ModalFormGenerator from "@/components/ModalFomGenerator.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import SelectSearchWithLabel from "@/components/SelectSearchWithLabel.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { reactive, ref } from "vue";
import { useFormStore, useModalStore } from "@/store";

export default {
  components: {
    ModalFormGenerator,
    InputWithLabel,
    SelectSearchWithLabel,
    ButtonComponent,
    Form,
    IconTrash,
  },
  props: {
    id: Number,
  },
  setup() {
    const formStore = useFormStore();
    const modal = useModalStore();

    const schema = Yup.object().shape({
      name: Yup.string().required("Name is required"),
    });

    const types = reactive([
      { label: "Text", value: "text" },
      { label: "Number", value: "number" },
      { label: "Password", value: "password" },
      { label: "Textarea", value: "textarea" },
      { label: "Date", value: "date" },
      { label: "Time", value: "time" },
      { label: "Radio", value: "radio" },
      { label: "Select", value: "select" },
      { label: "Checkbox", value: "checkbox" },
    ]);

    const type = ref("");
    const properties = ref([]);
    const options = ref([{ value: "" }]);

    const close = () => {
      modal.closeModal();
      document.getElementById("form").reset();
      type.value = "";
      options.value = [];
    };

    const acceptModal = async (value) => {
      const { name, validation } = value;
      let data = options.value;

      const formattedData = (data) =>
        data.map((obj) => ({
          label: obj.value,
          ...obj,
        }));
      properties.value = {
        name: name,
        type: type.value,
        validation: validation,
      };
      if (
        type.value == "radio" ||
        type.value == "select" ||
        type.value == "checkbox"
      ) {
        properties.value = {
          ...properties.value,
          options: formattedData(data),
        };
      }

      formStore.formProperties.push(properties.value);
      modal.closeModal();
      type.value = "";
      options.value = [{ value: "" }];
    };

    const addMore = () => {
      options.value.push({ value: "" });
    };

    const remove = (index) => {
      options.value.splice(index, 1);
    };

    return {
      type,
      acceptModal,
      options,
      addMore,
      remove,
      types,
      schema,
      close,
    };
  },
};
</script>

<style></style>
