<template>
  <card-component
    :scale="true"
    :addClass="[
      { 'hover:bg-gray-200': !selected },
      'cursor-pointer border-gray-300',
    ]"
    :selected="selected"
  >
    <TitleSubIconGap
      :title="username"
      :subtitle="first_name"
      :subtitle2="last_name"
      subtitleColor="text-gray-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#14444f"
        class="w-12 h-12"
      >
        <path
          fill-rule="evenodd"
          d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
          clip-rule="evenodd"
        />
      </svg>
    </TitleSubIconGap>
    <div class="py-4">
      <hr />
    </div>
    <div class="space-y-4 px-3">
      <div class="flex items-center space-x-3">
        <IconMessageId :color="'#143f4f'" />
        <h3 class="text-sm text-gray-600">{{ chat_id }}</h3>
      </div>
      <div class="flex items-center space-x-3">
        <IconCheck color="#e69400" />
        <h3 class="text-sm text-gray-600">{{ status }}</h3>
      </div>
      <div class="flex items-center space-x-3">
        <IconDatePlus />
        <h3 class="text-sm text-gray-600">{{ date }}</h3>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from "./CardComponent.vue";
import TitleSubIconGap from "./TitleSubIconGap.vue";
import IconMessageId from "./icons/IconMessageId.vue";
import IconCheck from "./icons/IconCheck.vue";
import IconDatePlus from "./icons/IconDatePlus.vue";

export default {
  components: {
    CardComponent,
    TitleSubIconGap,
    IconMessageId,
    IconCheck,
    IconDatePlus,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    username: String,
    first_name: String,
    last_name: String,
    chat_id: Number,
    status: String,
    date: String,
  },
};
</script>

<style></style>
