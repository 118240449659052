<template>
  <ModalComponent :id="id" title="Update Modem">
    <template #body>
      <Form @submit="acceptModal">
        <div class="p-6 space-y-3 max-h-[450px] overflow-y-auto">
          <InputWithLabel
            label="Modem ID *"
            type="text"
            name="modemid"
            :value="byId.modem_id"
            readonly
          />

          <InputWithLabel
            label="Name *"
            type="text"
            name="name"
            :value="byId.name"
          />

          <div class="mb-4">
            <label
              for="status"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              Status
            </label>

            <div class="flex items-center space-x-5">
              <div v-for="s in status" :key="s.value">
                <div class="flex items-center space-x-2 px-1">
                  <input
                    id="status"
                    type="radio"
                    name="status"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    :value="s.value"
                    v-model="byId.status"
                  />
                  <label
                    for="status"
                    class="ms-2 text-sm text-gray-900 dark:text-gray-300"
                  >
                    {{ s.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <label
              for="type"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              Type
            </label>

            <div class="flex items-center space-x-5">
              <div v-for="t in type" :key="t.value">
                <div class="flex items-center space-x-2 px-1">
                  <input
                    id="type"
                    type="radio"
                    name="type"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    v-model="byId.type"
                    :value="t.value"
                  />
                  <label
                    for="type"
                    class="ms-2 text-sm text-gray-900 dark:text-gray-300"
                  >
                    {{ t.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <InputWithLabel
            label="Telegram ID"
            type="text"
            name="teleid"
            :value="byId.tele_id"
          />
          <InputWithLabel
            label="Telegram Name"
            type="text"
            name="telename"
            :value="byId.tele_name"
          />
          <InputWithLabel
            label="Client ID"
            type="text"
            name="clientid"
            :value="byId.client_id"
          />
          <InputWithLabel
            label="Client Secret"
            type="text"
            name="clientsecret"
            :value="byId.client_secret"
          />
        </div>

        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary">
              Save
            </ButtonComponent>
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { Form } from "vee-validate";
import { useModemStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    ModalComponent,
    ModalFooter,
    ButtonComponent,
    InputWithLabel,
    Form,
  },
  setup() {
    const modemStore = useModemStore();
    const { byId } = storeToRefs(modemStore);

    const status = [
      { name: "Active", value: "1" },
      { name: "Inactive", value: "0" },
    ];

    const type = [
      { name: "1", value: "1" },
      { name: "2", value: "2" },
      { name: "3", value: "3" },
      { name: "4", value: "4" },
    ];

    const acceptModal = async (value) => {
      const { name, teleid, telename, clientid, clientsecret } = value;

      await modemStore.updateModem(
        name,
        modemStore.byId.status,
        modemStore.byId.type,
        teleid,
        telename,
        clientid,
        clientsecret
      );
    };

    return {
      byId,
      status,
      type,
      acceptModal,
    };
  },
};
</script>

<style></style>
