<template>
  <div
    class="absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] z-10 w-96"
  >
    <div class="bg-white rounded-sm px-3 pt-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-4 h-4 float-right cursor-pointer"
        @click="onClose"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
      <h1 class="font-bold text-base px-3 mt-2 mb-4">
        Pilih Kapal & Rentang Waktu
      </h1>

      <fwb-tabs v-model="activeTab" directive="show" class="p-5 pr-7 w-[380px]">
        <fwb-tab name="kapal" title="Kapal">
          <TrackByVessel ref="vesselTab" @onPlayVessel="playVessel" />
        </fwb-tab>
        <fwb-tab name="grup" title="Grup">
          <TrackByGrupVessel ref="grupTab" @onPlayGrup="playGrup" />
        </fwb-tab>
      </fwb-tabs>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { FwbTab, FwbTabs } from "flowbite-vue";
import { useShipStore } from "@/store";
import { storeToRefs } from "pinia";
import TrackByVessel from "@/components/TrackByVessel.vue";
import TrackByGrupVessel from "@/components/TrackByGrupVessel.vue";

export default {
  components: {
    FwbTab,
    FwbTabs,
    TrackByVessel,
    TrackByGrupVessel,
  },
  setup(props, { emit }) {
    const shipStore = useShipStore();
    const { vesselToTrack, grupToTrack } = storeToRefs(shipStore);

    const selectShip = ref(null);

    const activeTab = ref("kapal");

    const vesselTab = ref(null);
    const grupTab = ref(null);

    const onClose = () => {
      activeTab.value = "kapal";
      reset();
      resetGrup();
      emit("onClose");
    };

    const reset = () => {
      vesselTab.value.reset();
    };

    const resetGrup = () => {
      activeTab.value = "kapal";
      grupTab.value.reset();
    };

    const playVessel = (t1, t2) => {
      emit("onPlayVessel", t1, t2);
    };

    const playGrup = (t1, t2) => {
      emit("onPlayGrup", t1, t2);
    };

    return {
      activeTab,
      vesselToTrack,
      grupToTrack,
      onClose,
      selectShip,
      reset,
      vesselTab,
      grupTab,
      playVessel,
      playGrup,
      resetGrup,
    };
  },
};
</script>

<style>
label.ampm-switch {
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin: 0;
  cursor: pointer;
  background-color: #d1d5db;
}
label.ampm-switch:before,
label.ampm-switch:after {
  box-sizing: border-box;
  display: block;
  width: 40px;
  height: 20px;
  font-size: 12px;
  /* font-weight: bold; */
  text-align: center;
  line-height: 20px;
  color: black;
}
label.ampm-switch:before {
  content: "AM";
}

label.ampm-switch:after {
  content: "PM";
}

label.ampm-switch input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: 0;
  padding: 0;
  border: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0.1);
  filter: alpha(opacity=0.1);
  opacity: 0.001;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
}

label.ampm-switch .toggle-button {
  z-index: 2;
  display: inline-block;
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  width: 40px;
  height: 20px;
  background: transparent;
  box-sizing: border-box;
  background-clip: padding-box;
  white-space: nowrap;
  border: 1px solid #dee1e8;
  color: #454545;
  background-color: #dee1e8;
  -webkit-transition: top 0.05s ease-in-out;
  -moz-transition: top 0.05s ease-in-out;
  -o-transition: top 0.05s ease-in-out;
  -ms-transition: top 0.05s ease-in-out;
  transition: top 0.05s ease-in-out;
  background: #fbfcfd;
}

label.ampm-switch input:checked + .toggle-button {
  top: 20px;
}

label.ampm-switch .toggle-button:before {
  box-sizing: border-box;
  display: block;
  width: 40px;
  height: 20px;
  font-size: 12px;
  /* font-weight: bold; */
  text-align: center;
  line-height: 20px;
  color: #ced1f5;
}
label.ampm-switch .toggle-button:before {
  content: "AM";
}

label.ampm-switch input:checked + .toggle-button:before {
  content: "PM";
}
</style>
