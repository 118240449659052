<template>
  <ModalComponent title="Edit User" :id="id">
    <template #body>
      <Form @submit="acceptModal">
        <div class="p-6 space-y-4">
          <InputWithLabel
            label="First Name *"
            type="text"
            name="firstName"
            :value="byId.firstName"
          />
          <InputWithLabel
            label="Last Name"
            type="text"
            name="lastName"
            :value="byId.lastName"
          />
          <InputWithLabel
            label="Email *"
            type="email"
            name="email"
            :value="byId.email"
          />
          <SelectSearchWithLabel
            label="Role *"
            labelColor="text-black"
            placholder="Select User Role"
            :data="roleUser"
            optionsValue="desc"
            optionsKey="name"
            v-model:formControlName="byId.role"
          />
        </div>
        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary"
              >Update</ButtonComponent
            >
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import { Form } from "vee-validate";
import ModalComponent from "@/components/ModalComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import SelectSearchWithLabel from "@/components/SelectSearchWithLabel.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useUserStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    Form,
    ModalComponent,
    InputWithLabel,
    SelectSearchWithLabel,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const userStore = useUserStore();
    const { byId } = storeToRefs(userStore);

    const roleUser = [
      { name: "user", desc: "User" },
      { name: "admin", desc: "Admin" },
    ];

    const acceptModal = async (value) => {
      const { firstName, lastName, email } = value;
      await userStore.updateUser(
        firstName,
        lastName,
        email,
        userStore.byId.role
      );
    };

    return {
      roleUser,
      byId,
      acceptModal,
    };
  },
};
</script>

<style></style>
