<template>
  <div
    class="absolute top-[35%] left-[58%] transform -translate-x-[50%] -translate-y-[50%] z-10 w-96"
  >
    <div class="bg-white rounded-sm px-3 pt-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-4 h-4 float-right cursor-pointer"
        @click="$emit('onClose')"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
      <h1 class="font-bold text-base mt-2 mb-4">Pilih Rentang Waktu</h1>
      <div class="">
        <small>Pilih rentang data</small>
        <div class="flex justify-between gap-3 mt-1">
          <Datepicker
            :enable-time-picker="false"
            calendar-class-name="p-3"
            placeholder="Tanggal Mulai"
            v-model="startDate"
            :format="format"
          />
          <Datepicker
            :enable-time-picker="false"
            calendar-class-name="p-3"
            placeholder="Tanggal Akhir"
            v-model="endDate"
            :format="format"
          />
        </div>
        <div class="flex gap-2 items-center mt-4">
          <div class="py-2 flex items-center">
            <input
              size="4"
              placeholder="00"
              v-model="startHour"
              class="text-center border"
            />
            <span class="px-2">:</span>
            <input
              size="4"
              placeholder="00"
              v-model="startMinute"
              class="text-center border"
            />
            <div class="ml-2">
              <label class="ampm-switch">
                <input type="checkbox" v-model="startAMPM" />
                <div class="toggle-button"></div>
              </label>
            </div>
          </div>
          -
          <div class="py-2 flex items-center">
            <input
              size="4"
              placeholder="00"
              v-model="endHour"
              class="text-center border"
            />
            <span class="px-2">:</span>
            <input
              size="4"
              placeholder="00"
              v-model="endMinute"
              class="text-center border"
            />
            <div class="ml-2">
              <label class="ampm-switch">
                <input type="checkbox" v-model="endAMPM" />
                <div class="toggle-button"></div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-end mt-4">
        <button
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 flex justify-end"
          @click="playTrackback()"
        >
          Play
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import { ref } from "vue";
import moment from "moment";

export default {
  emits: ["onPlay", "onClose"],
  components: {
    Datepicker,
  },
  setup(props, { emit }) {
    const startDate = ref();
    const startHour = ref();
    const startMinute = ref();
    const startAMPM = ref(true);
    const endDate = ref();
    const endHour = ref();
    const endMinute = ref();
    const endAMPM = ref(true);

    const format = (datepick) => {
      const date = datepick;
      let value = "";

      if (date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        value = value.concat(`${day}/${month}/${year}`);
      }

      return value;
    };

    const reset = () => {
      startDate.value = "";
      startHour.value = "";
      startMinute.value = "";
      startAMPM.value = true;
      endDate.value = "";
      endHour.value = "";
      endMinute.value = "";
      endAMPM.value = true;
    };

    const convertTime12to24 = (time12) => {
      const [time, modifier] = time12.split(" ");

      let [hours, minutes, seconds] = time.split(":");

      if (hours === "12") {
        hours = "00";
      }

      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}:${seconds}`;
    };

    const playTrackback = () => {
      console.log("plau");
      const date1 = moment(startDate.value).format("YYYY-MM-DD");
      const date2 = moment(endDate.value).format("YYYY-MM-DD");
      const ampm1 = startAMPM.value ? "AM" : "PM";
      const ampm2 = endAMPM.value ? "AM" : "PM";
      const time1 = convertTime12to24(
        `${startHour.value}:${startMinute.value}:00 ${ampm1}`
      );
      const time2 = convertTime12to24(
        `${endHour.value}:${endMinute.value}:00 ${ampm2}`
      );

      const t1 = `${date1} ${time1}`;
      const t2 = `${date2} ${time2}`;

      emit("onPlay", t1, t2);
      //   console.log(t1, t2);
    };

    return {
      startDate,
      startHour,
      startMinute,
      startAMPM,
      endDate,
      endHour,
      endMinute,
      endAMPM,
      format,
      reset,
      playTrackback,
    };
  },
};
</script>

<style>
label.ampm-switch {
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin: 0;
  cursor: pointer;
  background-color: #d1d5db;
}
label.ampm-switch:before,
label.ampm-switch:after {
  box-sizing: border-box;
  display: block;
  width: 40px;
  height: 20px;
  font-size: 12px;
  /* font-weight: bold; */
  text-align: center;
  line-height: 20px;
  color: black;
}
label.ampm-switch:before {
  content: "AM";
}

label.ampm-switch:after {
  content: "PM";
}

label.ampm-switch input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: 0;
  padding: 0;
  border: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0.1);
  filter: alpha(opacity=0.1);
  opacity: 0.001;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
}

label.ampm-switch .toggle-button {
  z-index: 2;
  display: inline-block;
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  width: 40px;
  height: 20px;
  background: transparent;
  box-sizing: border-box;
  background-clip: padding-box;
  white-space: nowrap;
  border: 1px solid #dee1e8;
  color: #454545;
  background-color: #dee1e8;
  -webkit-transition: top 0.05s ease-in-out;
  -moz-transition: top 0.05s ease-in-out;
  -o-transition: top 0.05s ease-in-out;
  -ms-transition: top 0.05s ease-in-out;
  transition: top 0.05s ease-in-out;
  background: #fbfcfd;
}

label.ampm-switch input:checked + .toggle-button {
  top: 20px;
}

label.ampm-switch .toggle-button:before {
  box-sizing: border-box;
  display: block;
  width: 40px;
  height: 20px;
  font-size: 12px;
  /* font-weight: bold; */
  text-align: center;
  line-height: 20px;
  color: #ced1f5;
}
label.ampm-switch .toggle-button:before {
  content: "AM";
}

label.ampm-switch input:checked + .toggle-button:before {
  content: "PM";
}
</style>
