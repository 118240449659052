<template>
  <div class="logo">
    <img src="../../assets/logo-seahub.png" width="150" />
  </div>
  <div id="map" class="w-full h-[98vh]"></div>

  <div
    class="absolute right-2.5 top-28 z-10 bg-[#ffffff] border-2 border-black/25 rounded-sm flex flex-col w-8 px-4 py-1 items-center"
  >
    <button
      type="button"
      class="border-b border-black/25 p-1 rounded-sm inline-flex items-center me-2"
      @click="openFilter"
    >
      <IconFilter />
    </button>
    <button
      type="button"
      class="border-b border-black/25 p-1 rounded-sm inline-flex items-center me-2"
      @click="openPop"
    >
      <IconPlay />
    </button>
    <button
      type="button"
      class="border-b border-black/25 p-1 rounded-sm inline-flex items-center me-2"
      @click="openLegend"
    >
      <IconDetail />
    </button>
    <button
      type="button"
      class="p-1 rounded-sm inline-flex items-center me-2"
      @click="openGeofence"
    >
      <IconEye v-if="!showGeofence" />
      <IconEyeHidden v-if="showGeofence" />
    </button>
  </div>

  <VesselGroup
    v-show="showGroup"
    v-model:valueAll="viewAll"
    v-model:valueGrup="viewGrup"
  />
  <PopupPlayback
    @onPlayVessel="openPlay"
    @onPlayGrup="trackGrup"
    @onClose="closePop"
    v-show="showPopTrack"
    ref="popup"
  />
  <InfoLegend v-show="showLegend" />
  <NewVesselGroup v-show="showVesselGroup" />
  <PopupPlaybackKapal
    ref="popPlaybackKapal"
    v-show="showPlaybackKapal"
    @onPlay="playbackKapal"
    @onClose="closePlaybackKapal"
  />
  <NewAreaGeofence :id="1" />
  <EditAreaGeofence :id="2" />
</template>

<style>
#map {
  z-index: 1;
}
.logo {
  position: absolute;
  z-index: 2;
  right: 100px;
  top: 30px;
}
.leaflet-popup-close-button {
  margin-top: 3px;
  margin-right: 3px;
  margin-bottom: 3px;
}
.leaflet-container .leaflet-control-search {
  position: absolute !important;
  left: 40px;
  top: 3px;
  color: black !important;
}
.leaflet-control-search.search-exp {
  display: flex;
  gap: 3px;
}
.leaflet-control-search .search-input {
  width: 250px;
  border: 1px solid transparent !important;
}
.leaflet-control-search .search-input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.leaflet-control-search .search-tooltip {
  background-color: #f9fafb !important;
  margin-top: 1px;
  width: 288px;
}
.leaflet-control-search .search-tip {
  background: #f9fafb !important;
}
.leaflet-control-search .search-tip:hover {
  background-color: #e5e7eb !important;
}
@keyframes fade {
  from {
    opacity: 0.5;
  }
}

.blink {
  animation: fade 1s infinite alternate;
}
</style>

<script>
import L from "leaflet";
import { onMounted, ref, watchEffect } from "vue";
import moment from "moment";
import {
  useAreaGeofenceStore,
  useErrorStore,
  useModalStore,
  useShipStore,
  useVesselGroupStore,
  useVesselReportStore,
} from "@/store";
import { storeToRefs } from "pinia";
import IconFilter from "@/components/icons/IconFilter.vue";
import IconDetail from "@/components/icons/IconDetail.vue";
import IconPlay from "@/components/icons/IconPlay.vue";
import InfoLegend from "./InfoLegend.vue";
import PopupPlayback from "./PopupPlayback.vue";
import VesselGroup from "./VesselGroup.vue";
import NewVesselGroup from "./NewVesselGroup.vue";
import PopupPlaybackKapal from "./PopupPlaybackKapal.vue";
import NewAreaGeofence from "@/components/NewAreaGeofence.vue";
import EditAreaGeofence from "@/components/EditAreaGeofence.vue";
import IconEye from "@/components/icons/IconEye.vue";
import IconEyeHidden from "@/components/icons/IconEyeHidden.vue";
import axios from "axios";
import GeoJSON from "geojson";

export default {
  components: {
    IconFilter,
    IconDetail,
    IconPlay,
    InfoLegend,
    PopupPlayback,
    VesselGroup,
    NewVesselGroup,
    PopupPlaybackKapal,
    NewAreaGeofence,
    EditAreaGeofence,
    IconEye,
    IconEyeHidden,
  },
  setup() {
    const shipStore = useShipStore();
    const { geomData, showVesselGroup, showGroup } = storeToRefs(shipStore);
    const modal = useModalStore();
    const areaStore = useAreaGeofenceStore();
    const vesselGroupStore = useVesselGroupStore();
    const vesselReportStore = useVesselReportStore();
    const { geofence } = storeToRefs(areaStore);

    const map = ref();
    const center = ref([-1.476796, 121.001893]);
    const zoom = ref(5);
    const track = ref();
    const control = ref(null);
    const geom = ref();
    const areaLayer = ref();
    const areaGroup = ref();

    const showLegend = ref(false);
    const showPopTrack = ref(false);
    const showPlaybackKapal = ref(false);
    const trackOpen = ref(false);
    const viewAll = ref(true);
    const viewGrup = ref(false);
    const showGeofence = ref(true);

    const mmsiForTrack = ref(0);

    const geojsonData = ref({});

    const pin = {
      30: "fishing",
      31: "other",
      40: "high_speed_craft",
      41: "high_speed_craft",
      42: "high_speed_craft",
      43: "high_speed_craft",
      44: "high_speed_craft",
      45: "high_speed_craft",
      46: "high_speed_craft",
      47: "high_speed_craft",
      48: "high_speed_craft",
      49: "high_speed_craft",
      52: "tug",
      60: "passenger",
      61: "passenger",
      62: "passenger",
      63: "passenger",
      64: "passenger",
      65: "passenger",
      66: "passenger",
      67: "passenger",
      68: "passenger",
      69: "passenger",
      70: "cargo",
      71: "cargo",
      72: "cargo",
      73: "cargo",
      74: "cargo",
      75: "cargo",
      76: "cargo",
      77: "cargo",
      78: "cargo",
      79: "cargo",
      80: "tanker",
      81: "tanker",
      82: "tanker",
      83: "tanker",
      84: "tanker",
      85: "tanker",
      86: "tanker",
      87: "tanker",
      88: "tanker",
      89: "tanker",
      90: "other",
      91: "other",
      92: "other",
      93: "other",
      94: "other",
      95: "other",
      96: "other",
      97: "other",
      98: "other",
      99: "other",
    };

    const data = ref();

    var source = new EventSource("http://183.91.67.210:50537/api/get-latest");
    source.addEventListener(
      "open",
      function () {
        console.log("Connections to the server established..<br/>");
      },
      false
    );

    source.onmessage = function (e) {
      const y = JSON.parse(e.data);
      if (data.value.length != 0) {
        data.value.filter((item) => {
          if (y[item.pid] != undefined) {
            if (item.mmsi == y[item.pid].mmsi) {
              item = y[item.pid];
            }
          }
          return item;
        });
        geojsonData.value = GeoJSON.parse(data.value, {
          Point: ["lat", "lon"],
        });
      }
    };

    var drawnItems = ref();

    const createMap = () => {
      map.value = L.map("map", { zoomAnimation: false }).setView(
        center.value,
        zoom.value
      );
      var base = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        minZoom: 5,
        maxZoom: 18,
      }).addTo(map.value);

      console.log("Bounds:", map.value.getBounds().toBBoxString());
      var hidros = L.tileLayer
        .wms(
          "https://map.seahub.id/mapproxy/service?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap",
          {
            layers: "hidros",
            format: "image/png",
            transparent: true,
            version: "1.3.0",
            attribution: "Seahub.id © 2012 ENC MAP",
            // bounds: map.value.getBounds().toBBoxString()
          }
        )
        .addTo(map.value);

      var baseLayers = {
        "Base:": base,
      };

      var overlayers = {
        // Granos: wmsGranos,
        Hidros: hidros,
      };

      L.control
        .layers(baseLayers, overlayers, { collapsed: true })
        .addTo(map.value);
      L.control.scale().addTo(map.value);
      L.control.ruler().addTo(map.value);

      var drawnMarker = L.Icon.extend({
        options: {
          shadowUrl: null,
          iconAnchor: new L.Point(12, 12),
          iconSize: new L.Point(24, 24),
          iconUrl: require("../../assets/marker.png"),
        },
      });

      // var drawnItems = new L.FeatureGroup();
      drawnItems.value = new L.FeatureGroup();
      map.value.addLayer(drawnItems.value);
      var drawControl = new L.Control.Draw({
        draw: {
          marker: {
            icon: new drawnMarker(),
          },
        },
        edit: {
          featureGroup: drawnItems.value,
        },
      });
      map.value.addControl(drawControl);

      map.value.on("draw:created", function (e) {
        var type = e.layerType,
          layer = e.layer;

        drawnItems.value.addLayer(layer);

        var shape = layer.toGeoJSON();
        console.log(JSON.stringify(shape));

        if (type == "circle" || type == "circlemarker") {
          const radius = layer.getRadius();
          shape.properties = { type: type, radius: radius };
        }
        if (type == "marker") {
          shape.properties = { type: type };
          console.log(shape);
        }

        areaStore.drawArea = shape;
        modal.showModal(1);
      });

      map.value.on("draw:edited", function (e) {
        var layers = e.layers;
        layers.eachLayer(async function (layer) {
          if (layer instanceof L.Marker) {
            areaStore.geoEdited = layer.toGeoJSON();
            areaStore.areaId = areaStore.geoEdited.properties.id;
            areaStore.dataById.area = areaStore.geoEdited.properties.area;
            areaStore.dataById.kategori_area =
              areaStore.geoEdited.properties.kategori_area;
          } else if (
            layer instanceof L.Circle ||
            layer instanceof L.CircleMarker
          ) {
            areaStore.geoEdited = layer.toGeoJSON();
            areaStore.areaId = areaStore.geoEdited.properties.id;
            const radius = layer.getRadius();
            areaStore.geoEdited.properties.radius = radius;
            areaStore.dataById.area = areaStore.geoEdited.properties.area;
            areaStore.dataById.kategori_area =
              areaStore.geoEdited.properties.kategori_area;
          } else {
            areaStore.geoEdited = layer.toGeoJSON();
            await areaStore.areaById(areaStore.geoEdited.properties.id);
          }
          modal.showModal(2);
        });
      });

      // map.value.on("contextmenu", (e) => {
      //   L.popup()
      //     .setLatLng(e.latlng)
      //     .setContent("<pre>Hello</pre>")
      //     .addTo(map)
      //     .openOn(map);
      // });
    };

    const trackControl = (value) => {
      trackOpen.value = true;
      const url = pin[value[0].stype]
        ? require(`../../assets/vessel/${pin[value[0].stype]}.png`)
        : require(`../../assets/vessel/unknown.png`);

      track.value = L.trackplayback(value, map.value, {
        targetOptions: {
          useImg: true,
          imgUrl: url,
        },
      });

      control.value = L.trackplaybackcontrol(track.value);
      control.value.addTo(map.value);

      map.value.setView([value[0].lat, value[0].lng], 8);

      control.value._closeBtn.addEventListener("click", () => {
        trackOpen.value = false;
        zoom.value = localStorage.getItem("zoom");
        center.value = [
          localStorage.getItem("centerLat"),
          localStorage.getItem("centerLng"),
        ];

        map.value.setView(center.value, zoom.value);
        map.value.addLayer(geom.value);
        map.value.closePopup(popShip);
        map.value.eachLayer(function (layer) {
          if (layer.options.pane === "tooltipPane") layer.removeFrom(map.value);
        });
      });

      L.tooltip({
        offset: [0, 80],
        direction: "center",
        opacity: 0.7,
        permanent: true,
      })
        .setLatLng([value[0].lat, value[0].lng])
        .setContent(
          `
        <div class="px-3 py-2">
          <p class="text-sm font-bold">${value[0].name}</p>
          <p class="text-sm mt-3">Update: ${moment(
            new Date(value[value.length - 1].time_stamp)
          ).format("YYYY-MM-DD HH:mm:ss")}</p>
          <p>Speed: ${value[0].speed_over_ground}Kn</p>
          <div class="flex space-x-5">
            <p class="text-sm">Status Mesin</p>
            <span class="bg-green-100 text-green-800 text-[10px] font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">ON</span>
          </div>
          <div class="flex space-x-5 mt-1">
            <p class="text-sm">Status Alarm</p>
            <span class="bg-red-100 text-red-800 text-[11px] font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">Lost GNSS Signal</span>
          </div>
        </div>
        `
        )
        .addTo(map.value);

      var popShip = L.popup({ maxWidth: 380 });

      map.value.on("click", (e) => {
        if (trackOpen.value) {
          popShip
            .setLatLng(e.latlng)
            .setContent(
              `
          <div class="px-1 py-1">
            <small>Vessel Name</small>
            <h2 class="font-bold">${value[0].name}</h2>
            <small>Update:  ${moment(
              new Date(value[value.length - 1].time_stamp)
            ).format("YYYY-MM-DD HH:mm:ss")} WIB</small>
          </div>
          <div class="flex justify-between border-t border-b mt-2">
            <div class="mt-1.5 mb-1.5 flex gap-1 items-center">
              <small>Status Mesin </small>
              <span class="bg-green-100 text-green-800 text-[10px] font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">ON</span>
            </div>
            <div class="mt-1.5 mb-1.5 flex gap-1 items-center">
              <small>Status Alarm </small>
              <span class="bg-red-100 text-red-800 text-[11px] font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">Lost GNSS Signal</span>
            </div>
          </div>
          <div class="flex justify-between mt-2">
            <table>
          <tr>
            <td class="py-2">Longitude</td>
            <td class="px-3 py-2">${value[value.length - 1].lng}</td>
          </tr
          <tr>
            <td class="py-2">Latitude</td>
            <td class="px-3 py-2">${value[value.length - 1].lat}</td>
          </tr>
          <tr>
            <td class="py-2">Northing</td>
            <td class="px-3 py-2">1\u00B0 08' 50.9"</td>
          </tr>
          <tr>
            <td class="py-2">Easthing</td>
            <td class="px-3 py-2">104\u00B0 16' 19.5"</td>
          </tr>
        </table>

        <table>
          <tr>
            <td class="px-3 py-2">Speed</td>
            <td class="py-2">${value[0].speed_over_ground} Kn</td>
          </tr
          <tr>
            <td class="px-3 py-2">Heading</td>
            <td class="py-2">${value[0].true_heading}\u00B0</td>
          </tr>
          <tr>
            <td class="px-3 py-2">Objek Terdekat</td>
            <td class="py-2">Pelabuhan A</td>
          </tr>
          <tr>
            <td class="px-3 py-2">Jarak Objek</td>
            <td class="py-2">29.000 m</td>
          </tr>
        </table>
          </div>
          `
            )
            .openOn(map.value);
        }
      });
    };

    const eachFeature = (feature, layer) => {
      drawnItems.value.addLayer(layer);
    };
    areaGroup.value = L.layerGroup();

    const showArea = async () => {
      await areaStore.areaCoordinate();

      const cor = geofence.value.rows;

      cor.filter(async (x) => {
        if (x.koordinat.geometry.type == "Point") {
          areaLayer.value = L.geoJson(x.koordinat, {
            pointToLayer: function (feature, latlng) {
              if (feature.properties.type == "circlemarker") {
                return L.circleMarker(latlng, {
                  fillColor: "#062346",
                  stroke: false,
                  fillOpacity: 0.5,
                });
              } else if (feature.properties.type == "circle") {
                return L.circle(latlng, {
                  radius: feature.properties.radius,
                  fillOpacity: 0.5,
                  stroke: false,
                });
              } else {
                return L.marker(latlng, {
                  icon: L.icon({
                    iconUrl: require("../../assets/marker.png"),
                    iconSize: [24, 24],
                    iconAnchor: [12, 12],
                  }),
                });
              }
            },
            onEachFeature: eachFeature,
          });
          areaGroup.value.addLayer(areaLayer.value);
          // map.value.addLayer(areaLayer.value);
        }
        if (
          x.koordinat.geometry.type == "Polygon" ||
          x.koordinat.geometry.type == "LineString"
        ) {
          // console.log(x.koordinat.geometry.type);
          areaLayer.value = L.geoJSON(x.koordinat, {
            style: {
              opacity: 0.5,
            },
            onEachFeature: eachFeature,
          });
          // map.value.addLayer(areaLayer.value);
          areaGroup.value.addLayer(areaLayer.value);
        }
      });
      areaGroup.value.addTo(map.value);
    };

    onMounted(async () => {
      if (localStorage.getItem("zoom") != null) {
        zoom.value = localStorage.getItem("zoom");
      }
      if (
        localStorage.getItem("centerLat") != null &&
        localStorage.getItem("centerLng") != null
      ) {
        center.value = [
          localStorage.getItem("centerLat"),
          localStorage.getItem("centerLng"),
        ];
      }

      await createMap();
      const dat = await axios.post("http://183.91.67.210:50537/api/latest", {
        port: "[16, 18]",
      });

      data.value = dat.data.data;
      await showArea();
    });

    const getGeom = async () => {
      await shipStore.getGeoJSON();
    };

    const onEachFeature = async (feature, layer) => {
      if (feature.properties) {
        const div = document.createElement("div");
        const header = document.createElement("div");
        header.setAttribute("class", "flex justify-between items-end");
        header.innerHTML = `
        <div class="flex flex-col">
          <small>Vessel Name</small>
          <h2 class="font-bold">${feature.properties.name}</h2>
          <small>Update:  ${moment(new Date(feature.properties.ts)).format(
            "YYYY-MM-DD HH:mm:ss"
          )} WIB</small>
        </div>`;

        const btnContainer = document.createElement("div");
        btnContainer.setAttribute("class", "flex gap-3");

        const btnSorot = document.createElement("button");
        btnSorot.setAttribute(
          "class",
          "mt-1 flex gap-1 items-center text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-sm text-xs px-2 py-1.5"
        );
        btnSorot.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
        </svg>
        Sorot`;

        const btnPlayback = document.createElement("button");
        btnPlayback.setAttribute(
          "class",
          "mt-1 flex gap-1 items-center text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-sm text-xs px-2 py-1.5"
        );
        btnPlayback.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
        </svg>
        Playback`;

        btnContainer.appendChild(btnSorot);
        btnContainer.appendChild(btnPlayback);

        header.appendChild(btnContainer);

        const status = document.createElement("div");
        status.setAttribute(
          "class",
          " flex justify-between border-t border-b mt-2"
        );
        status.innerHTML = `
        <div class="mt-1.5 mb-1.5 flex gap-1 items-center">
          <small>Status Mesin </small>
          <span class="bg-green-100 text-green-800 text-[10px] font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">ON</span>
        </div>
        <div class="mt-1.5 mb-1.5 flex gap-1 items-center">
          <small>Status Alarm </small>
          <span class="bg-red-100 text-red-800 text-[11px] font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">Lost GNSS Signal</span>
        </div>`;

        const info = document.createElement("div");
        info.setAttribute("class", "flex justify-between mt-2");
        info.innerHTML = `
        <table>
          <tr>
            <td class="py-2">Longitude</td>
            <td class="px-3 py-2">${feature.geometry.coordinates[0]}</td>
          </tr
          <tr>
            <td class="py-2">Latitude</td>
            <td class="px-3 py-2">${feature.geometry.coordinates[1]}</td>
          </tr>
          <tr>
            <td class="py-2">Northing</td>
            <td class="px-3 py-2">1\u00B0 08' 50.9"</td>
          </tr>
          <tr>
            <td class="py-2">Easthing</td>
            <td class="px-3 py-2">104\u00B0 16' 19.5"</td>
          </tr>
        </table>

        <table>
          <tr>
            <td class="px-3 py-2">Speed</td>
            <td class="py-2">${feature.properties.sog / 10} Kn</td>
          </tr
          <tr>
            <td class="px-3 py-2">Heading</td>
            <td class="py-2">${feature.properties.hdg}\u00B0</td>
          </tr>
          <tr>
            <td class="px-3 py-2">Objek Terdekat</td>
            <td class="py-2">Pelabuhan A</td>
          </tr>
          <tr>
            <td class="px-3 py-2">Jarak Objek</td>
            <td class="py-2">29.000 m</td>
          </tr>
        </table>`;

        btnPlayback.onclick = async function () {
          await map.value.removeLayer(geom.value);
          mmsiForTrack.value = feature.properties.mmsi;
          showPlaybackKapal.value = true;
        };

        btnSorot.onclick = async function () {
          const lat = feature.geometry.coordinates[1];
          const lng = feature.geometry.coordinates[0];
          map.value.setView([lat, lng], 11);
        };

        div.appendChild(header);
        div.appendChild(status);
        div.appendChild(info);
        layer.bindPopup(div, {
          maxWidth: 380,
        });

        layer.bindTooltip(
          `
        <div class="flex flex-col">
          <h1 class="font-bold">${feature.properties.name} at ${
            feature.properties.sog / 10
          } Kn / ${feature.properties.hdg}\u00B0</h1>
          <small>Destination: ${feature.properties.dest}</small>
          <small>Updated: ${moment(new Date(feature.properties.ts)).format(
            "YYYY-MM-DD HH:mm:ss"
          )}</small>
        </div>
        `,
          {
            direction: "top",
          }
        );
        var circle = null;

        layer.on("mouseover", () => {
          const lat = feature.geometry.coordinates[1];
          const lng = feature.geometry.coordinates[0];
          circle = L.circleMarker([lat, lng], {
            radius: 10,
            color: "#9B1C1C",
            width: 2,
            dashOffset: "40",
          }).addTo(map.value);
        });
        layer.on("mouseout", function () {
          circle.remove();
        });
      }
    };

    const popup = ref();
    var mmsi = ref([]);

    const openPlay = async (t1, t2) => {
      showPopTrack.value = false;

      for (let i = 0; i < shipStore.$state.vesselToTrack.length; i++) {
        mmsi.value.push(shipStore.$state.vesselToTrack[i].mmsi);
      }

      const vessel = mmsi.value.length != 0 ? mmsi.value.toString() : "";

      await shipStore.history(vessel, t1, t2);

      if (shipStore.$state.historyVessel.length != 0) {
        shipStore.$state.historyVessel.forEach((element) => {
          element["lat"] = element["latitude"];
          element["lng"] = element["longitude"];
          element["time"] = moment(element["time_stamp"]).unix();
          delete element["latitude"];
          delete element["longitude"];
        });

        const x = Object.values(
          shipStore.$state.historyVessel.reduce(shipStore.trackByMMSI, {})
        );

        await map.value.removeLayer(geom.value);

        popup.value.reset();

        track.value = L.trackplayback(x, map.value, {
          targetOptions: {
            useImg: true,
            imgUrl: require("../../assets/vessel/unknown.png"),
          },
        });

        control.value = L.trackplaybackcontrol(track.value);
        control.value.addTo(map.value);

        control.value._closeBtn.addEventListener("click", () => {
          trackOpen.value = false;
          zoom.value = localStorage.getItem("zoom");
          center.value = [
            localStorage.getItem("centerLat"),
            localStorage.getItem("centerLng"),
          ];
          map.value.setView(center.value, zoom.value);
          map.value.addLayer(geom.value);
          mmsi.value = [];
        });
      } else {
        mmsi.value = [];
        popup.value.reset();
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.retry = false;
        errorMessage.message = "Sorry, there is no data for playback";
      }
    };

    const trackGrup = async (t1, t2) => {
      var mmsi = [];
      showPopTrack.value = false;

      await vesselGroupStore.vesselByGroup(shipStore.$state.grupToTrack);

      for (let i = 0; i < vesselGroupStore.$state.byGroup.length; i++) {
        mmsi.push(vesselGroupStore.$state.byGroup[i].mmsi);
      }

      const vessel = mmsi.length != 0 ? mmsi.toString() : "";

      await shipStore.history(vessel, t1, t2);

      if (shipStore.$state.historyVessel.length != 0) {
        shipStore.$state.historyVessel.forEach((element) => {
          element["lat"] = element["latitude"];
          element["lng"] = element["longitude"];
          element["time"] = moment(element["time_stamp"]).unix();
          delete element["latitude"];
          delete element["longitude"];
        });

        const x = Object.values(
          shipStore.$state.historyVessel.reduce(shipStore.trackByMMSI, {})
        );

        popup.value.resetGrup();

        await map.value.removeLayer(geom.value);

        track.value = L.trackplayback(x, map.value, {
          targetOptions: {
            useImg: true,
            imgUrl: require("../../assets/vessel/unknown.png"),
          },
        });

        control.value = L.trackplaybackcontrol(track.value);
        control.value.addTo(map.value);

        control.value._closeBtn.addEventListener("click", () => {
          trackOpen.value = false;
          zoom.value = localStorage.getItem("zoom");
          center.value = [
            localStorage.getItem("centerLat"),
            localStorage.getItem("centerLng"),
          ];
          map.value.setView(center.value, zoom.value);
          map.value.addLayer(geom.value);
          mmsi = [];
        });
      } else {
        mmsi = [];
        popup.value.reset();
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.retry = false;
        errorMessage.message = "Sorry, there is no data for playback";
      }
    };

    const openPop = () => {
      showPopTrack.value = true;
    };

    const closePop = () => {
      showPopTrack.value = false;
      mmsi.value = [];
    };

    const openFilter = () => {
      showGroup.value = true;
    };

    const createShip = () => {
      const result = vesselReportStore.vesselReport.reduce(function (r, a) {
        r[a.mmsi] = r[a.mmsi] || [];
        r[a.mmsi].push(a);
        return r;
      }, Object.create(null));

      geom.value = L.geoJSON(geojsonData.value, {
        pointToLayer: function (feature, latlng) {
          const url = pin[feature.properties.stype]
            ? require(`../../assets/vessel/${
                pin[feature.properties.stype]
              }.png`)
            : require(`../../assets/vessel/unknown.png`);
          const className = result[feature.properties.mmsi] ? "blink" : "";
          return L.marker(latlng, {
            icon: L.icon({
              iconUrl: url,
              iconSize: [5, 10],
              iconAnchor: [5, 8],
              className: className,
            }),
            rotationAngle: (feature.properties.hdg * 180) / Math.PI,
          });
        },

        onEachFeature: onEachFeature,
      });
      map.value.addLayer(geom.value);
    };

    const openLegend = () => {
      showLegend.value = !showLegend.value;
    };

    const searchBox = () => {
      var searchControl = new L.Control.Search({
        layer: geom.value ? geom.value : "",
        propertyName: "name",
        marker: false,
        moveToLocation: function (latlng, title, map) {
          var zoom = 8;
          map.setView(latlng, zoom);
        },
        collapsed: false,
      });

      searchControl
        .on("search:locationfound", function (e) {
          if (e.layer._popup) e.layer.openPopup();
        })
        .on("search:collapsed", function () {
          map.value.setView(center.value, zoom.value);
        })
        .on("search:cancel", function (e) {
          e.target._map.closePopup();
          map.value.setView(center.value, zoom.value);
        });

      map.value.addControl(searchControl);
    };

    const report = async () => {
      // let dataParams = {
      //   fromDate: moment(new Date()).format("YYYY-MM-DD"),
      //   toDate: moment(new Date()).format("YYYY-MM-DD"),
      // };

      await vesselReportStore.checkReportExist();
      await vesselReportStore.saveReport();
    };

    watchEffect(async () => {
      if (viewAll.value && !viewGrup.value) {
        setInterval(await getGeom(), 1000 * 60 * 60);
        if (map.value.hasLayer(geom.value)) {
          await map.value.removeLayer(geom.value);
        }

        geojsonData.value = shipStore.$state.geojsonData;
        setInterval(await report(), 5000);
        // await report();
        await createShip();
        searchBox();
      } else if (!viewAll.value && viewGrup.value) {
        await vesselGroupStore.vesselByGroup(
          vesselGroupStore.$state.grupSelect
        );

        if (map.value.hasLayer(geom.value)) {
          await map.value.removeLayer(geom.value);
        }

        const filtered = vesselReportStore.$state.dataKapal.filter(
          (firstData) => {
            return vesselGroupStore.$state.byGroup.filter(
              (secondData) => firstData.mmsi === secondData.mmsi
            ).length;
          }
        );

        const res = GeoJSON.parse(JSON.parse(JSON.stringify(filtered)), {
          Point: ["lat", "lon"],
        });

        geojsonData.value = res;

        await report();
        await createShip();
        searchBox();
      } else {
        if (map.value.hasLayer(geom.value)) {
          await map.value.removeLayer(geom.value);
          geom.value.clearLayers();
        }
      }

      map.value.on("zoomend", function (e) {
        localStorage.setItem("zoom", e.target._zoom);
      });

      map.value.on("dragend", function () {
        const curentCenter = map.value.getCenter();
        localStorage.setItem("centerLat", curentCenter.lat);
        localStorage.setItem("centerLng", curentCenter.lng);
      });
    });

    const popPlaybackKapal = ref();
    const playbackKapal = async (t1, t2) => {
      const vessel = `[${mmsiForTrack.value}]`;
      await shipStore.history(vessel, t1, t2);
      console.log(shipStore.$state.historyVessel);

      if (shipStore.$state.historyVessel.length != 0) {
        shipStore.$state.historyVessel.forEach((el) => {
          el["lat"] = el["latitude"];
          el["lng"] = el["longitude"];
          el["time"] = moment(el["time_stamp"]).unix();
          delete el["latitude"];
          delete el["longitude"];
        });
      }
      // await map.value.removeLayer(geom.value);
      popPlaybackKapal.value.reset();
      showPlaybackKapal.value = false;
      trackControl(shipStore.$state.historyVessel);
    };

    const closePlaybackKapal = () => {
      showPlaybackKapal.value = false;
      popPlaybackKapal.value.reset();
      createShip();
    };

    const openGeofence = () => {
      showGeofence.value = !showGeofence.value;

      if (!showGeofence.value) {
        map.value.removeLayer(areaGroup.value);
      } else {
        map.value.addLayer(areaGroup.value);
      }
    };

    return {
      showLegend,
      openLegend,
      geomData,
      openPlay,
      showPopTrack,
      openPop,
      closePop,
      showGroup,
      openFilter,
      showVesselGroup,
      viewAll,
      viewGrup,
      popup,
      trackGrup,
      showPlaybackKapal,
      closePlaybackKapal,
      playbackKapal,
      popPlaybackKapal,
      openGeofence,
      showGeofence,
    };
  },
};
</script>
