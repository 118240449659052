<template>
  <SelectGroup
    :data="groupBy?.length ? groupBy : []"
    :optionsValue="'desc'"
    :optionsKey="'name'"
    v-model:formControlName="formName"
    placeholder="Select Group"
    @onSelectChange="changeData"
    @onClose="close"
  />
</template>

<script>
import SelectGroup from "./SelectGroup.vue";
import { computed } from "vue";

export default {
  components: { SelectGroup },
  props: {
    formControlName: [String, Number],
    theme: String,
    labelColor: String,
    optionsKey: String,
  },
  setup(props, { emit }) {
    const groupBy = [
      { name: "message_id", desc: "Message ID" },
      { name: "ota_message_size", desc: "Message Size" },
      { name: "message_utc", desc: "Message Date" },
    ];

    const formName = computed({
      get() {
        return props.formControlName;
      },
      set(value) {
        emit("update:formControlName", value);
      },
    });

    const changeData = (value) => {
      emit("onSelectChange", value);
    };

    const close = () => {
      emit("onClose");
    };

    return {
      groupBy,
      formName,
      changeData,
      close,
    };
  },
};
</script>
