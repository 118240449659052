<template>
  <ModalComponent
    :id="id"
    title="Report"
    :widthMax="'max-w-xl max-h-[620px] overflow-y-auto'"
  >
    <template #body>
      <div class="px-8 py-2">
        <p class="text-center text-base font-semibold text-gray-900">
          {{ reportByChat.report_name }}
        </p>

        <div
          class="pt-3"
          v-for="(data, index) in reportByChat.report_data"
          :key="index"
        >
          <div
            v-if="
              data.type != 'textarea' &&
              data.type != 'radio' &&
              data.type != 'checkbox' &&
              data.type != 'select'
            "
          >
            <div class="mb-1">
              <label
                :for="data.name"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                {{ data.name }}
              </label>
              <input
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg transition duration-300 block w-full p-2.5"
                :type="data.type"
                :value="data.value"
                readonly
              />
            </div>
          </div>

          <div v-if="data.type == 'textarea'">
            <div class="mb-1">
              <label
                :for="data.name"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                {{ data.name }}
              </label>
              <textarea
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg transition duration-300 block w-full p-2.5 resize-none"
                v-model="data.value"
                readonly
              ></textarea>
            </div>
          </div>

          <div v-if="data.type == 'radio'">
            <p class="block mb-2 text-sm font-medium text-gray-900">
              {{ data.name }}
            </p>
            <div class="grid grid-cols-3">
              <div
                v-for="(opt, index) in data.options"
                :key="index"
                class="flex items-center space-x-1.5"
              >
                <input
                  type="radio"
                  :id="opt.label"
                  :name="data.name"
                  :value="opt.value"
                  disabled
                  :checked="data.value == opt.value ? true : false"
                />
                <label :for="opt.label">{{ opt.label }}</label>
              </div>
            </div>
          </div>

          <div v-if="data.type == 'checkbox'">
            <p class="block mb-2 text-sm font-medium text-gray-900">
              {{ data.name }}
            </p>
            <div class="grid grid-cols-3">
              <div
                v-for="(opt, index) in data.options"
                :key="index"
                class="flex items-center space-x-1.5"
              >
                <input
                  type="checkbox"
                  :id="opt.label"
                  :name="opt.label"
                  :value="data.value"
                  disabled
                  :checked="data.value.includes(opt.value)"
                />
                <label :for="opt.label">{{ opt.label }}</label>
              </div>
            </div>
          </div>

          <div v-if="data.type == 'select'">
            <label :for="data.name" class="block mb-2 text-sm font-medium">
              {{ data.name }}
            </label>
            <div class="relative">
              <input
                :value="data.value"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg transition duration-300 block w-full p-2.5"
                readonly
              />
              <span class="absolute inset-y-0 right-0 pr-3 flex items-center">
                <svg
                  class="w-5 h-5 text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="reportByChat.loading"
        class="flex justify-center items-center h-[190px]"
      >
        <LoadingLoad size="w-20 h-20" />
      </div>
      <ModalFooter :colorClose="'btn-amber'" />
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import LoadingLoad from "@/components/LoadingLoad.vue";
import { useReportStore } from "@/store";
import { storeToRefs } from "pinia";
import { watchEffect } from "vue";

export default {
  components: {
    ModalComponent,
    ModalFooter,
    LoadingLoad,
  },
  props: {
    id: Number,
  },
  setup() {
    const reportStore = useReportStore();
    const { reportByChat } = storeToRefs(reportStore);

    watchEffect(async () => {
      if (reportStore.chatId != "") {
        await reportStore.getReportByChat(reportStore.chatId);
      }
    });

    return {
      reportByChat,
    };
  },
};
</script>

<style></style>
