import { defineStore } from "pinia";
import debounce from "debounce-promise";
import { getData } from "@/utils/fetch";
import { useAreaGeofenceStore } from "./areaGeofence.store";
import axios from "../config";
import L from "leaflet";
import { ref } from "vue";
import moment from "moment";

let debouncedFetch = debounce(getData, 500);

export const useVesselReportStore = defineStore({
  id: "vessel_report",
  state: () => ({
    reports: {},
    dataDownload: {},
    dataArea: [],
    dataKapal: [],
    unique: null,
    reportExist: [],
    vesselReport: [],
    dateFilter: ref([
      moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"),
    ]),
    page: ref(1),
    length: ref(0),
  }),
  getters: {},
  actions: {
    async getArea() {
      try {
        const areaStore = useAreaGeofenceStore();
        const data = [];
        await areaStore.areaCoordinate();
        areaStore.geofence.rows.map((item) => {
          if (item.koordinat.geometry.coordinates.length == 1) {
            data.push({
              id: item.koordinat.properties.id,
              type: item.koordinat.geometry.type,
              area: item.koordinat.properties.area,
              kategori_area: item.koordinat.properties.kategori_area,
              koordinat: item.koordinat.geometry.coordinates[0],
            });
          }
          if (item.koordinat.geometry.coordinates.length == 2) {
            data.push({
              id: item.koordinat.properties.id,
              type: item.koordinat.geometry.type,
              radius: item.koordinat.properties.radius,
              area: item.koordinat.properties.area,
              kategori_area: item.koordinat.properties.kategori_area,
              koordinat: [item.koordinat.geometry.coordinates],
            });
          }
        });
        this.dataArea = data;
      } catch (error) {
        console.log(error);
      }
    },

    async getReport(params) {
      this.reports = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        order: params?.order || "entry_time",
        ordertype: params?.ordertype || "ASC",
        fromDate: params?.fromDate,
        toDate: params?.toDate,
      };

      try {
        const data = await debouncedFetch("vessel_report", dataparams);
        this.reports = { ...data.data };
      } catch (error) {
        console.log(error);
        this.reports = { error };
      }
    },

    async download(params) {
      this.dataDownload = { loading: true };

      const dataparams = {
        order: params?.order || "entry_time",
        ordertype: params?.ordertype || "ASC",
        fromDate: params?.fromDate,
        toDate: params?.toDate,
      };

      try {
        await debouncedFetch("vessel_report/report/download", dataparams);
      } catch (error) {
        console.log(error);
      }
    },

    async checkReportExist(params) {
      const dataparams = {
        order: params?.order || "name",
        ordertype: params?.ordertype || "ASC",
      };
      try {
        const data = await debouncedFetch(
          "vessel_report/report/check",
          dataparams
        );
        this.reportExist = data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async updateReport(mmsi, areaid, body) {
      await axios.patch(`vessel_report/${mmsi}?areaid=${areaid}`, body);
    },

    async deleteDuplicateReport() {
      await axios.delete("vessel_report");
    },

    async createReport(data) {
      await axios.post("vessel_report", {
        mmsi: data.mmsi,
        name: data.name,
        latitude: data.lat,
        longitude: data.lon,
        sog: data.sog,
        hdg: data.hdg,
        imo: data.imo,
        call: data.call,
        stype: data.stype,
        dest: data.dest,
        ccode: data.ccode,
        ndesc: data.ndesc,
        sclass: data.sclass,
        cname: data.cname,
        pid: data.pid,
        area_id: data.areaid,
        area_name: data.area,
        entry_time: new Date(),
      });
    },

    async saveReport() {
      await this.getArea();
      var polyPoints = [];
      var circlePoints = [];

      await this.dataArea.map((item) => {
        if (item.type == "Polygon") {
          item.koordinat.map((data) => {
            polyPoints.push({
              lat: parseFloat(data[1]),
              lng: parseFloat(data[0]),
              area: item.area,
              areaid: item.id,
            });
          });
        }

        if (item.type == "Point" && item.radius != 0) {
          item.koordinat.map((data) => {
            circlePoints.push({
              lat: data[1],
              lng: data[0],
              radius: item.radius,
              area: item.area,
              areaid: item.id,
            });
          });
        }
      });

      var x = 0;
      var y = 0;
      this.vesselReport = [];

      if (this.dataKapal.length != 0) {
        this.dataKapal.map(async (koord) => {
          var inside = false;
          x = koord.lat;
          y = koord.lon;
          var area = [];

          for (
            var i = 0, j = polyPoints.length - 1;
            i < polyPoints.length;
            j = i++
          ) {
            var xi = polyPoints[i].lat,
              yi = polyPoints[i].lng;
            var xj = polyPoints[j].lat,
              yj = polyPoints[j].lng;

            var intersect =
              yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

            if (intersect) {
              inside = !inside;
              area.push({
                ...koord,
                area: polyPoints[j].area,
                areaid: polyPoints[j].areaid,
                waktu_awal: new Date(),
                waktu_akhir: new Date(),
              });
            }
          }

          if (inside) {
            this.unique = [
              ...new Map(area.map((item) => [item["mmsi"], item])).values(),
            ];

            this.vesselReport.push(this.unique[0]);
          }

          for (let i = 0; i < circlePoints.length; i++) {
            const d = L.latLng([koord.lat, koord.lon]).distanceTo([
              circlePoints[i].lat,
              circlePoints[i].lng,
            ]);

            if (d < circlePoints[i].radius) {
              this.vesselReport.push({
                ...koord,
                area: circlePoints[i].area,
                areaid: circlePoints[i].areaid,
                waktu_awal: new Date(),
                waktu_akhir: new Date(),
              });
            }
          }
        });
      }

      console.log("vesselReport", this.vesselReport);
      console.log("ifExist", this.reportExist);
      var filter2 = [];
      var filter3 = [];

      if (this.reportExist.rows == 0) {
        this.vesselReport.map((item) => {
          this.createReport(item);
        });
      } else {
        //ada di database ada di area

        this.reportExist.rows.map((data) => {
          this.vesselReport.filter(async (item) => {
            if (
              data.mmsi == item.mmsi &&
              data.area_id == item.areaid &&
              data.exit_time != null
            ) {
              await this.createReport(item);
              this.deleteDuplicateReport();
            }
          });
        });

        //ada di area tapi tidak ada di database
        filter2 = this.vesselReport.filter((data) => {
          return !this.reportExist.rows.some((item) => {
            return data.mmsi === item.mmsi;
          });
        });
        console.log("filter 2", filter2);
        if (filter2.length != 0) {
          filter2.map(async (item) => {
            this.createReport(item);
          });
        }

        //ada di database tidak ada di area
        filter3 = this.reportExist.rows.filter((data) => {
          return !this.vesselReport.some((item) => {
            return data.mmsi == item.mmsi && data.area_id == item.areaid;
          });
        });
        console.log("filter 3", filter3);
        if (filter3.length != 0) {
          filter3.map(async (data) => {
            if (data.exit_time === null) {
              await this.updateReport(data.mmsi, data.area_id, {
                exit_time: new Date(),
              });
              console.log("edit");
            }
          });
        }
        filter2 = [];
        filter3 = [];
        // this.deleteDuplicateReport();
      }
    },
  },
});
