<template>
  <teleport to="#modal">
    <div
      v-if="modal.getOpen && modal.id == id"
      aria-hidden="true"
      class="overflow-y-auto overflow-x-hidden flex backdrop-blur-sm bg-primary bg-opacity-30 items-center justify-center fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
    >
      <div class="relative w-full h-full md:h-auto" :class="[widthMax]">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div class="flex justify-between items-start px-4 py-2 rounded-t">
            <button
              type="button"
              @click="modal.closeModal"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <div class="flex flex-col justify-center items-center">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              class="w-12 h-12"
              viewBox="0 0 512.000000 512.000000"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#b12020"
                stroke="none"
              >
                <path
                  d="M2390 4895 c-550 -148 -532 -141 -570 -220 -27 -57 -25 -107 11 -236
17 -61 29 -112 27 -114 -2 -1 -217 -59 -478 -129 -261 -70 -499 -136 -528
-148 -62 -24 -152 -103 -176 -152 -9 -19 -16 -51 -16 -72 0 -49 98 -415 116
-434 12 -13 31 -10 152 21 316 83 3441 921 3485 935 39 13 47 20 47 41 0 33
-96 387 -115 423 -32 61 -123 100 -234 100 -53 0 -163 -26 -558 -132 -271 -72
-496 -129 -499 -125 -3 3 -18 52 -34 110 -31 115 -47 152 -82 184 -31 30 -90
53 -130 52 -18 0 -206 -47 -418 -104z m445 -68 c3 -7 18 -60 34 -118 l29 -106
-77 -21 c-598 -160 -793 -212 -799 -212 -10 0 -65 204 -60 227 3 15 78 38 418
130 228 61 423 112 433 112 10 1 20 -5 22 -12z"
                />
                <path
                  d="M1585 3423 c-297 -80 -541 -147 -543 -148 -1 -1 58 -663 132 -1470
131 -1422 136 -1470 161 -1521 30 -60 79 -108 144 -138 46 -21 48 -21 1211
-21 1163 0 1165 0 1211 21 65 30 114 78 144 139 26 52 30 92 170 1622 79 862
147 1589 150 1616 l6 47 -1123 -1 -1123 -1 -540 -145z m349 -590 c8 -62 156
-1679 156 -1704 0 -18 -10 -19 -165 -19 -92 0 -165 4 -165 9 0 5 -36 400 -80
878 -44 477 -80 871 -80 876 0 4 74 7 164 7 l164 0 6 -47z m926 -365 c0 -227
-3 -626 -7 -885 l-6 -473 -165 0 -165 0 6 662 c4 364 7 762 7 885 l0 223 165
0 165 0 0 -412z m860 398 c0 -19 -148 -1639 -156 -1703 l-6 -53 -164 0 c-128
0 -164 3 -164 13 0 7 36 404 80 881 44 478 80 870 80 872 0 2 74 4 165 4 134
0 165 -3 165 -14z"
                />
              </g>
            </svg>

            <h1 class="mt-3 text-2xl font-bold">Delete Data</h1>
            <h3 class="mt-1 font-normal text-gray-500">
              Are you sure you want to delete this data ?
            </h3>
          </div>
          <div class="flex justify-end items-center p-6 space-x-2 rounded-b">
            <ButtonComponent
              vClass="bg-darkGreen hover:bg-greenPrimary"
              @click="$emit('onDeleteData')"
            >
              YES
            </ButtonComponent>
            <ButtonComponent @click="modal.closeModal" vClass="btn-darkGray">
              NO
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import ButtonComponent from "./ButtonComponent.vue";
export default {
  emits: ["onDeleteData"],
  props: {
    widthMax: {
      type: String,
      default: "max-w-sm",
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  inheritAttrs: false,
  components: {
    ButtonComponent,
  },
  setup() {
    const { useModalStore } = require("@/store");

    const modal = useModalStore();

    return {
      modal,
    };
  },
};
</script>
