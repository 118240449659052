<template>
  <svg
    viewBox="0 0 384 512"
    xmlns="http://www.w3.org/2000/svg"
    :class="size"
    :fill="color"
  >
    <path
      d="m336 0h-288c-26.5 0-48 21.5-48 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-416c0-26.5-21.5-48-48-48zm0 464h-288v-416h288zm-192-352h96c8.8 0 16-7.2 16-16s-7.2-16-16-16h-96c-8.8 0-16 7.2-16 16s7.2 16 16 16zm48 176c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "w-7 h-7",
    },
    color: {
      type: String,
      default: "#062346",
    },
  },
};
</script>

<style></style>
