import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "@vuepic/vue-datepicker/dist/main.css";
import "vue3-circle-progress/dist/circle-progress.css";
import VueApexCharts from "vue3-apexcharts";
import { plugin, defaultConfig } from "@formkit/vue";
import "@formkit/themes/genesis";
import "leaflet/dist/leaflet.css";
import "leaflet-plugin-trackplayback";
import "leaflet-plugin-trackplayback/dist/control.playback.css";
import "leaflet-plugin-trackplayback/dist/control.trackplayback.js";
import "leaflet-plugin-trackplayback/dist/leaflet.trackplayback.js";
import "leaflet-ruler/src/leaflet-ruler.css";
import "leaflet-ruler/src/leaflet-ruler.js";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw/dist/leaflet.draw.js";
import vue3GoogleLogin from "vue3-google-login";
import "leaflet-rotatedmarker/leaflet.rotatedMarker.js";
import "leaflet-search/dist/leaflet-search.src.js";
import "leaflet-search/src/leaflet-search.css";

import "./index.css";

const pinia = createPinia();
createApp(App)
  .use(router)
  .use(pinia)
  .use(VueApexCharts)
  .use(plugin, defaultConfig)
  .use(vue3GoogleLogin, {
    clientId:
      "868947359684-74l8dv23vjd2aboer8qaadi585car6i6.apps.googleusercontent.com",
  })
  .mount("#app");
