<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    :stroke="color"
    :class="size"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "w-5 h-5",
    },
    color: {
      type: String,
      default: "#1F2937",
    },
  },
};
</script>

<style></style>
