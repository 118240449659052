import { getData } from "@/utils/fetch";
import debounce from "debounce-promise";
import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import GeoJSON from "geojson";
import { useVesselReportStore } from "./vessel_report.store";

let debouncedFetch = debounce(getData, 500);

export const useShipStore = defineStore({
  id: "ship",
  state: () => ({
    geomGroup: {},
    geomData: {},
    bymmsi: {},
    showVesselGroup: false,
    showGroup: false,
    showPopTrack: false,
    vesselToTrack: ref([]),
    grupToTrack: 0,
    dataTrack: [],
    geojsonData: {},
    dataKapal: {},
    historyVessel: null,
  }),
  getters: {
    getDataShip(state) {
      return state.ships.data;
    },
  },
  actions: {
    trackByMMSI(index, item) {
      const { mmsi } = item;
      const key = [mmsi];

      (index[key] ??= []).push(item);

      return index;
    },
    async getGeom() {
      try {
        const data = await debouncedFetch("latest_position");
        this.geomData = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async geomByGrup(groupId) {
      try {
        const data = await debouncedFetch("latest_position/" + groupId);
        this.geomGroup = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async byMMSI(mmsi) {
      try {
        const data = await debouncedFetch(`latest_position/latest/${mmsi}`);
        this.bymmsi = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getGeoJSON() {
      const vesselReportStore = useVesselReportStore();
      try {
        const data = await axios.post("http://183.91.67.210:50537/api/latest", {
          port: "[16, 18]",
        });

        this.geojsonData = await GeoJSON.parse(data.data.data, {
          Point: ["lat", "lon"],
        });
        vesselReportStore.dataKapal = data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getVessel() {
      this.dataKapal = { loading: true };
      try {
        const result = await axios.post(
          "http://183.91.67.210:50537/api/latest",
          {
            port: "[16, 18]",
          }
        );

        const data = result.data.data.filter((item) => item.name != null);
        this.dataKapal = data;
      } catch (error) {
        console.log(error);
      }
    },
    async history(vessel, time1, time2) {
      try {
        const data = await axios.post(
          "http://183.91.67.210:50537/api/history",
          {
            mmsi: `[${vessel}]`,
            t1: time1,
            t2: time2,
          }
        );
        this.historyVessel = data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
