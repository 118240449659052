<template>
  <ModalComponent :id="id" title="Pengaturan Alarm">
    <template #body>
      <Form @submit="save">
        <div class="p-6 space-y-3">
          <SelectSearchLabel
            label="Jenis Alarm"
            :data="jenisAlarm"
            optionsKey="name"
            optionsValue="desc"
            v-model:formControlName="alarmById.jenis_alarm"
          />

          <div class="flex justify-between items-center">
            <div class="inline-flex space-x-2">
              <span
                class="ms-3 block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Notifikasi
              </span>
              <label class="cursor-pointer mb-4">
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="alarmById.notifikasi"
                />
                <div
                  class="relative w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                ></div>
              </label>
            </div>

            <div class="inline-flex space-x-2">
              <span
                class="ms-3 block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Status Respon
              </span>
              <label class="cursor-pointer mb-4">
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="alarmById.status_respon"
                />
                <div
                  class="relative w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                ></div>
              </label>
            </div>
          </div>

          <div v-if="alarmById.status_respon">
            <span
              class="ms-3 block text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Template Respon
            </span>

            <div class="flex flex-col space-y-2 max-h-36 overflow-y-auto">
              <div
                class="flex space-x-3 items-center justify-between"
                v-for="(respon, index) in alarmById.template_respon"
                :key="index"
              >
                <input
                  type="text"
                  v-model="respon.respon"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg transition duration-300 block w-full p-2.5 focus:border-2"
                />

                <div class="flex space-x-1">
                  <div
                    class="bg-[#f6a823] hover:bg-amber500 rounded-full p-1 cursor-pointer"
                    @click="addMore"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      class="w-5 h-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>

                  <div
                    class="bg-red-700 hover:bg-red-800 rounded-full p-1"
                    :class="
                      index == 0 ? 'cursor-not-allowed' : 'cursor-pointer'
                    "
                    @click="index == 0 ? '' : remove(index)"
                  >
                    <IconTrash color="white" size="w-5 h-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bgGreenPrimary">
              Save
            </ButtonComponent>
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "./ModalComponent.vue";
import SelectSearchLabel from "./SelectSearchWithLabel.vue";
import IconTrash from "./icons/IconTrash.vue";
import ModalFooter from "./ModalFooter.vue";
import ButtonComponent from "./ButtonComponent.vue";
import { Form } from "vee-validate";
import { ref } from "vue";
import { useAlarmStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    ModalComponent,
    SelectSearchLabel,
    IconTrash,
    Form,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const alarmStore = useAlarmStore();
    const { alarmById } = storeToRefs(alarmStore);

    const jenisAlarm = ref([
      { name: "Power Off", desc: "Power Off" },
      { name: "Lost Telementry", desc: "Lost Telementry" },
      { name: "Lost GNSS Signal", desc: "Lost GNSS Signal" },
      { name: "Lost Sensor Signal", desc: "Lost Sensor Signal" },
      { name: "Prohibited", desc: "Prohibited" },
      { name: "Batas Kecepatan", desc: "Batas Kecepatan" },
      { name: "Emergency", desc: "Emergency" },
    ]);

    const addMore = () => {
      alarmById.value.template_respon.push({
        respon: "",
      });
    };

    const remove = (index) => {
      alarmById.value.template_respon.splice(index, 1);
    };

    const save = async () => {
      var respon = [];
      alarmById.value.template_respon.map(function (item) {
        if (item.respon != "") {
          respon.push(item.respon);
        } else {
          respon = null;
        }
      });

      await alarmStore.updateAlarm(
        alarmById.value.jenis_alarm,
        alarmById.value.notifikasi,
        alarmById.value.status_respon,
        respon
      );
    };

    return {
      alarmById,
      jenisAlarm,
      addMore,
      remove,
      save,
    };
  },
};
</script>

<style></style>
