<template>
  <ModalComponent :id="id" title="Edit Batas Kecepatan">
    <template #body>
      <Form @submit="acceptModal">
        <div class="p-6 space-y-3">
          <SelectSearchWithLabel
            label="Area"
            :data="area.rows"
            optionsKey="id"
            optionsValue="area"
            v-model:formControlName="dataById.area"
          />
          <InputWithLabel
            label="Batas Min"
            name="batasMin"
            type="text"
            :value="dataById.batas_min"
          />
          <InputWithLabel
            label="Batas Max"
            name="batasMax"
            type="text"
            :value="dataById.batas_max"
          />
          <div clas="mb-2">
            <span class="block mb-0.5 text-sm font-medium text-gray-900">
              Rentang Waktu
            </span>
            <div class="flex justify-between items-center gap-2">
              <div class="py-2">
                <input
                  class="text-center border h-8 rounded-lg"
                  size="5"
                  placeholder="00"
                  v-model="dataById.startHour"
                />
                <span class="px-2">:</span>
                <input
                  class="text-center border h-8 rounded-lg"
                  size="5"
                  placeholder="00"
                  v-model="dataById.startMinute"
                />
              </div>
              -
              <div class="py-2">
                <input
                  class="text-center border h-8 rounded-lg"
                  size="5"
                  placeholder="00"
                  v-model="dataById.endHour"
                />
                <span class="px-2">:</span>
                <input
                  class="text-center border h-8 rounded-lg"
                  size="5"
                  placeholder="00"
                  v-model="dataById.endMinute"
                />
              </div>
            </div>
          </div>
        </div>
        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary">
              Save
            </ButtonComponent>
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "./ModalComponent.vue";
import { Form } from "vee-validate";
import SelectSearchWithLabel from "./SelectSearchWithLabel.vue";
import InputWithLabel from "./InputWithLabel.vue";
import ModalFooter from "./ModalFooter.vue";
import ButtonComponent from "./ButtonComponent.vue";
import { onMounted } from "vue";
import * as Yup from "yup";
import { useAreaGeofenceStore, useSpeedLimitStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    ModalComponent,
    Form,
    SelectSearchWithLabel,
    InputWithLabel,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const speedStore = useSpeedLimitStore();
    const { dataById } = storeToRefs(speedStore);
    const areaStore = useAreaGeofenceStore();
    const { area } = storeToRefs(areaStore);

    const schema = Yup.object().shape({
      batasMin: Yup.string().required("Batas Min is required"),
      batasMax: Yup.string().required("Batas Max is required"),
    });

    const getArea = async () => {
      areaStore.getAll({ limit: 999 });
    };

    onMounted(async () => {
      await getArea();
    });

    const acceptModal = async (value) => {
      const { batasMin, batasMax } = value;
      const waktuAwal = `${dataById.value.startHour}:${dataById.value.startMinute}`;
      const waktuAkhir = `${dataById.value.endHour}:${dataById.value.endMinute}`;
      console.log(
        dataById.value.area,
        batasMin,
        batasMax,
        waktuAwal,
        waktuAkhir
      );
      await speedStore.updateSpeed(
        dataById.value.area,
        batasMin,
        batasMax,
        waktuAwal,
        waktuAkhir
      );
    };

    return {
      dataById,
      area,
      schema,
      acceptModal,
    };
  },
};
</script>

<style></style>
