import { getData } from "@/utils/fetch";
import debounce from "debounce-promise";
import { defineStore } from "pinia";
import axios from "../config";
import { useErrorStore } from "./error.store";

let debouncedFetch = debounce(getData, 500);

export const useReportStore = defineStore({
  id: "report",
  state: () => ({
    reports: [],
    reportByChat: {},
    chatId: "",
  }),
  getters: {
    getData(state) {
      return state;
    },
  },
  actions: {
    async saveReport(formName, formData, chat) {
      try {
        await axios.post("report", {
          report_name: formName,
          report_data: formData,
          chat: chat,
        });
      } catch (error) {
        console.log(error);
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },
    async getAllReport(params) {
      this.reports = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        role: params?.role,
        order: params?.order || "createdAt",
        ordertype: params?.ordertype || "ASC",
      };

      try {
        const data = await debouncedFetch("report", dataparams);

        this.reports = { ...data.data };
      } catch (error) {
        this.report = { error };
      }
    },
    async getReportByChat(chat) {
      this.reportByChat = { loading: true };
      try {
        const data = await debouncedFetch(`report/${chat}`);
        this.reportByChat = data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
