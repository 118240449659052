<template>
  <div class="msg flex-1 overflow-y-auto bg-[#dad3cc]">
    <div class="py-2 px-5 mt-2">
      <div class="flex flex-col mb-2">
        <div v-for="data in chatHistory" :key="data.id_message">
          <div
            class="flex mb-2"
            v-if="data.sender == chatStore.id || data.receiver == chatStore.id"
            :class="data.sender == chatStore.id ? '' : 'justify-end'"
          >
            <div
              class="rounded py-2 px-2 flex flex-col max-w-xs"
              :class="
                data.sender == chatStore.id ? 'bg-[#f2f2f2]' : 'bg-[#e2f7cb]'
              "
            >
              <div v-if="data.status == 1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#374151"
                  class="w-8 h-8"
                  @click="openReport(data.id_message)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>
              </div>

              <p v-if="data.status == 2" class="text-sm mt-1 text-gray-800">
                {{
                  chatStore.filterMessage(data.message) != ""
                    ? chatStore.filterMessage(data.message)
                    : data.message
                }}
              </p>
              <p
                class="text-[9px] text-gray-600 mt-0.5"
                :class="
                  data.sender == chatStore.id ? 'text-right' : 'text-left'
                "
              >
                {{ date(data.createdAt) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ReportChat :id="1" />
      <!----->
    </div>
  </div>
</template>

<script>
import ReportChat from "@/views/tele_message/ReportChat.vue";
import { useChatStore, useModalStore, useReportStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import moment from "moment";

export default {
  components: {
    ReportChat,
  },
  setup() {
    const modal = useModalStore();
    const chatStore = useChatStore();
    const { chatHistory } = storeToRefs(chatStore);
    const reportStore = useReportStore();

    onMounted(() => {
      chatStore.msg = document.querySelector(".msg");
    });

    const date = (date) => {
      return moment(date).format("DD/MM/YY, HH:mm");
    };

    const openReport = (chatId) => {
      reportStore.chatId = chatId;
      modal.showModal(1);
    };

    return {
      modal,
      chatStore,
      chatHistory,
      date,
      openReport,
    };
  },
};
</script>

<style></style>
