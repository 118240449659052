<template>
  <small>Cari dan pilih kapal yang akan di track</small>
  <SelectShip
    :label="''"
    labelColor="text-gray-800"
    v-model:formControlName="vesselToTrack"
    ref="selectShip"
    class="mb-3"
  />
  <small>Pilih rentang data</small>
  <div class="flex justify-between gap-3 mt-1">
    <Datepicker
      :enable-time-picker="false"
      calendar-class-name="p-3"
      placeholder="Tanggal Mulai"
      v-model="startDate"
      :format="format"
    />
    <Datepicker
      :enable-time-picker="false"
      calendar-class-name="p-3"
      placeholder="Tanggal Akhir"
      v-model="endDate"
      :format="format"
    />
  </div>
  <div class="flex gap-2 items-center mt-4">
    <div class="py-2 flex items-center">
      <input
        size="4"
        placeholder="00"
        v-model="startHour"
        class="text-center border"
      />
      <span class="px-2">:</span>
      <input
        size="4"
        placeholder="00"
        v-model="startMinute"
        class="text-center border"
      />
      <div class="ml-2">
        <label class="ampm-switch">
          <input type="checkbox" v-model="startAMPM" />
          <div class="toggle-button"></div>
        </label>
      </div>
    </div>
    -
    <div class="py-2 flex items-center">
      <input
        size="4"
        placeholder="00"
        v-model="endHour"
        class="text-center border"
      />
      <span class="px-2">:</span>
      <input
        size="4"
        placeholder="00"
        v-model="endMinute"
        class="text-center border"
      />
      <div class="ml-2">
        <label class="ampm-switch">
          <input type="checkbox" v-model="endAMPM" />
          <div class="toggle-button"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="flex justify-end mt-4">
    <button
      type="button"
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 flex justify-end"
      :class="disabled ? 'bg-red-500 hover:bg-red-500 cursor-not-allowed' : ''"
      @click="disabled ? '' : playVessel()"
      :disabled="disabled ? true : false"
    >
      Play
    </button>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import SelectShip from "@/components/SelectShip.vue";
import { useShipStore } from "@/store";
import { storeToRefs } from "pinia";
import { ref, watchEffect } from "vue";
import moment from "moment";

export default {
  emits: ["onPlayVessel"],
  components: {
    Datepicker,
    SelectShip,
  },
  setup(props, { emit }) {
    const shipStore = useShipStore();
    const { vesselToTrack } = storeToRefs(shipStore);

    const selectShip = ref(null);

    const startDate = ref("");
    const endDate = ref("");
    const startHour = ref("");
    const startMinute = ref("");
    const endHour = ref("");
    const endMinute = ref("");
    const startAMPM = ref(true);
    const endAMPM = ref(true);
    const disabled = ref(false);

    const format = (datePick) => {
      const date = datePick;
      let value = "";

      if (date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        value = value.concat(`${day}/${month}/${year}`);
      }

      return value;
    };

    const reset = () => {
      selectShip.value.reset();
      startDate.value = "";
      endDate.value = "";
      startHour.value = "";
      startMinute.value = "";
      startAMPM.value = true;
      endHour.value = "";
      endMinute.value = "";
      endAMPM.value = true;
    };

    const convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");

      let [hours, minutes, seconds] = time.split(":");

      if (hours === "12") {
        hours = "00";
      }

      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}:${seconds}`;
    };

    const playVessel = () => {
      const date1 = moment(startDate.value).format("YYYY-MM-DD");
      const date2 = moment(endDate.value).format("YYYY-MM-DD");
      const ampm1 = startAMPM.value ? "AM" : "PM";
      const ampm2 = endAMPM.value ? "AM" : "PM";
      const time1 = convertTime12to24(
        `${startHour.value}:${startMinute.value}:00 ${ampm1}`
      );
      const time2 = convertTime12to24(
        `${endHour.value}:${endMinute.value}:00 ${ampm2}`
      );
      const t1 = `${date1} ${time1}`;
      const t2 = `${date2} ${time2}`;

      emit("onPlayVessel", t1, t2);
    };

    watchEffect(() => {
      if (
        startDate.value == "" ||
        startHour.value == "" ||
        startMinute.value == "" ||
        endDate.value == "" ||
        endHour.value == "" ||
        endMinute.value == "" ||
        vesselToTrack.value.length == 0
      ) {
        disabled.value = true;
      } else {
        disabled.value = false;
      }
    });

    return {
      vesselToTrack,
      selectShip,
      startDate,
      endDate,
      startHour,
      startMinute,
      startAMPM,
      endHour,
      endMinute,
      endAMPM,
      format,
      reset,
      playVessel,
      disabled,
    };
  },
};
</script>

<style>
label.ampm-switch {
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin: 0;
  cursor: pointer;
  background-color: #d1d5db;
}
label.ampm-switch:before,
label.ampm-switch:after {
  box-sizing: border-box;
  display: block;
  width: 40px;
  height: 20px;
  font-size: 12px;
  /* font-weight: bold; */
  text-align: center;
  line-height: 20px;
  color: black;
}
label.ampm-switch:before {
  content: "AM";
}

label.ampm-switch:after {
  content: "PM";
}

label.ampm-switch input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: 0;
  padding: 0;
  border: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0.1);
  filter: alpha(opacity=0.1);
  opacity: 0.001;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
}

label.ampm-switch .toggle-button {
  z-index: 2;
  display: inline-block;
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  width: 40px;
  height: 20px;
  background: transparent;
  box-sizing: border-box;
  background-clip: padding-box;
  white-space: nowrap;
  border: 1px solid #dee1e8;
  color: #454545;
  background-color: #dee1e8;
  -webkit-transition: top 0.05s ease-in-out;
  -moz-transition: top 0.05s ease-in-out;
  -o-transition: top 0.05s ease-in-out;
  -ms-transition: top 0.05s ease-in-out;
  transition: top 0.05s ease-in-out;
  background: #fbfcfd;
}

label.ampm-switch input:checked + .toggle-button {
  top: 20px;
}

label.ampm-switch .toggle-button:before {
  box-sizing: border-box;
  display: block;
  width: 40px;
  height: 20px;
  font-size: 12px;
  /* font-weight: bold; */
  text-align: center;
  line-height: 20px;
  color: #ced1f5;
}
label.ampm-switch .toggle-button:before {
  content: "AM";
}

label.ampm-switch input:checked + .toggle-button:before {
  content: "PM";
}
</style>
