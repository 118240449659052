<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :class="size"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="color"
      stroke="none"
    >
      <path
        d="M2410 4815 l0 -305 150 0 150 0 0 305 0 305 -150 0 -150 0 0 -305z"
      />
      <path
        d="M757 4362 l-107 -107 208 -208 c114 -114 212 -207 217 -207 13 0 205
192 205 205 0 6 -93 104 -208 218 l-207 207 -108 -108z"
      />
      <path
        d="M4047 4262 c-114 -114 -207 -212 -207 -217 0 -13 192 -205 205 -205
6 0 104 93 218 208 l207 207 -108 108 -107 107 -208 -208z"
      />
      <path
        d="M2370 3895 c-519 -73 -957 -455 -1106 -964 -51 -175 -54 -227 -54
-1163 l0 -868 1350 0 1350 0 0 868 c0 936 -3 988 -54 1163 -65 223 -178 413
-345 580 -305 304 -717 443 -1141 384z m340 -1335 l0 -450 -150 0 -150 0 0
450 0 450 150 0 150 0 0 -450z m0 -900 l0 -150 -150 0 -150 0 0 150 0 150 150
0 150 0 0 -150z"
      />
      <path d="M0 2560 l0 -150 305 0 305 0 0 150 0 150 -305 0 -305 0 0 -150z" />
      <path
        d="M4510 2560 l0 -150 305 0 305 0 0 150 0 150 -305 0 -305 0 0 -150z"
      />
      <path
        d="M1115 582 c-62 -21 -114 -60 -150 -111 -46 -67 -55 -118 -55 -303 l0
-168 1651 0 1651 0 -4 187 c-3 167 -5 193 -24 234 -28 60 -93 125 -153 153
l-46 21 -1410 2 c-1317 2 -1413 1 -1460 -15z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: String,
      default: "w-8 h-8",
    },
  },
};
</script>

<style></style>
