<template>
  <SidebarRight>
    <div v-if="!ogwsData.loading">
      <div class="px-8">
        <TitleSubIcon
          :title="data.message_id"
          titleColor="text-white"
          :subtitle="data.mobile_id"
          :subtitle2="data.sin"
          subtitleColor="text-white"
        >
          <IconChat color="white" />
        </TitleSubIcon>

        <TittleBetween addClass="pt-8 pb-5" title="Basic Info" />
      </div>

      <div class="flex flex-col px-8 space-y-5 [&_svg]:w-6 [&_svg]:h-6">
        <!-- Message Size -->
        <TextIconGroup
          :text="data.ota_message_size"
          :textWhite="true"
          textSize="text-md"
        >
          <IconMemory color="white" />
        </TextIconGroup>

        <!-- Sender -->
        <TextIconGroup
          :text="data.sender.replace(/[^a-zA-Z0-9]/g, '')"
          :textWhite="true"
          textSize="text-md"
        >
          <IconSend />
        </TextIconGroup>

        <!-- Destination -->
        <TextIconGroup
          :text="data.destination"
          :textWhite="true"
          textSize="text-md"
        >
          <IconReceived />
        </TextIconGroup>

        <!-- Mobile Owner ID -->
        <TextIconGroup
          :text="data.mobile_id"
          :textWhite="true"
          :textSize="'text-md'"
        >
          <IconId />
        </TextIconGroup>

        <!-- Transport -->
        <TextIconGroup
          :text="data.transport"
          :textWhite="true"
          :textSize="'text-md'"
        >
          <IconTransport />
        </TextIconGroup>

        <!-- Message Sent -->
        <TextIconGroup
          :text="date(data.message_utc)"
          :textWhite="true"
          textSize="text-md"
        >
          <IconDatePlus />
        </TextIconGroup>

        <!-- Message Receive -->
        <TextIconGroup
          :text="date(data.receive_utc)"
          :textWhite="true"
          textSize="text-md"
        >
          <IconDate />
        </TextIconGroup>
      </div>
    </div>
    <SkeletonDetailComponent v-if="ogwsData.loading" />
  </SidebarRight>
</template>

<script>
import SidebarRight from "@/components/SidebarRight.vue";
import TitleSubIcon from "@/components/TitleSubIcon.vue";
import IconChat from "@/components/icons/IconChat.vue";
import TittleBetween from "@/components/TittleBetween.vue";
import TextIconGroup from "@/components/TextIconGroup.vue";
import IconMemory from "@/components/icons/IconMemory.vue";
import IconSend from "@/components/icons/IconSend.vue";
import IconReceived from "@/components/icons/IconReceived.vue";
import IconDate from "@/components/icons/IconDate.vue";
import IconDatePlus from "@/components/icons/IconDatePlus.vue";
import IconTransport from "@/components/icons/IconTransport.vue";
import IconId from "@/components/icons/IconId.vue";
import SkeletonDetailComponent from "@/components/SkeletonDetailComponent.vue";
import { useRawOgwsStore, useSidebarStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { computed } from "vue";
import moment from "moment";

export default {
  components: {
    SidebarRight,
    TitleSubIcon,
    IconChat,
    TittleBetween,
    TextIconGroup,
    IconMemory,
    IconSend,
    IconReceived,
    IconDate,
    IconDatePlus,
    IconTransport,
    IconId,
    SkeletonDetailComponent,
  },
  setup() {
    const sidebar = useSidebarStore();
    const ogwsStore = useRawOgwsStore();
    const { ogwsData } = storeToRefs(ogwsStore);

    onMounted(() => {
      ogwsStore.getAll({});
    });

    const data = computed(() => sidebar.getData);

    const date = (date) => {
      return moment(date).format("DD MMM YYYY, HH:mm:ss");
    };

    const dateNoTime = (date) => {
      return moment(date).format("DD MMMM YYYY");
    };

    return {
      ogwsData,
      data,
      date,
      dateNoTime,
    };
  },
};
</script>

<style></style>
