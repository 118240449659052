<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="size"
    viewBox="0 0 24 24"
    fill="none"
    :stroke="color"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="icon icon-tabler icons-tabler-outline icon-tabler-user-scan"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M10 9a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
    <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
    <path d="M4 16v2a2 2 0 0 0 2 2h2" />
    <path d="M16 4h2a2 2 0 0 1 2 2v2" />
    <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
    <path d="M8 16a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2" />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "w-7 h-7",
    },
    color: {
      type: String,
      default: "#062346",
    },
  },
};
</script>

<style></style>
