<template>
  <card-component
    :addClass="[
      { 'hover:bg-gray-200': !selected },
      'cursor-pointer border-gray-300',
    ]"
    :selected="selected"
  >
    <div class="flex justify-between items-center">
      <div class="flex items-center space-x-2 md:space-x-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#14444f"
          class="w-9 h-9"
        >
          <path
            fill-rule="evenodd"
            d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
            clip-rule="evenodd"
          />
        </svg>

        <h1 class="text-sm md:text-lg font-bold text-greenPrimary">
          {{ message }}
        </h1>
      </div>
      <h1 class="text-sm md:text-lg font-bold text-greenPrimary">
        {{ size }}
      </h1>
    </div>

    <div
      class="flex flex-col space-y-2 md:flex-row md:space-y-0 justify-end pt-6 p-2 md:pl-1"
    >
      <div class="inline-flex items-center gap-2">
        <IconDatePlus size="w-6 h-6" />
        <h3 class="text-sm md:text-base text-gray-600">
          {{ messageSent }}
        </h3>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from "./CardComponent.vue";
import IconDatePlus from "./icons/IconDatePlus.vue";

export default {
  components: {
    CardComponent,
    IconDatePlus,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    message: String,
    size: Number,
    sender: String,
    destination: String,
    regionName: String,
    messageSent: String,
  },
};
</script>
