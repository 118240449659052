<template>
  <div class="absolute z-10 top-28 right-12 w-72">
    <div class="bg-white rounded-sm px-3 py-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-4 h-4 float-right cursor-pointer"
        @click="onClose"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
      <h1 class="font-bold text-base px-3 mt-2 mb-4">Group Kapal</h1>

      <div class="flex items-center mb-4 gap-1">
        <input
          id="all"
          type="checkbox"
          v-model="valueName"
          class="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          @click="setAll"
        />
        <label
          for="all"
          class="ms-2 text-xs font-medium text-gray-900 dark:text-gray-300"
          >Tampilkan Semua Kapal</label
        >
      </div>

      <div class="flex justify-between gap-5">
        <div class="flex gap-1.5">
          <input
            id="group"
            type="checkbox"
            v-model="grupView"
            class="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            @click="setGrup"
          />
          <div class="flex items-center mb-4 gap-1">
            <div class="flex flex-col gap-4">
              <SelectGroupVessel
                :data="dataFetch"
                :optionsValue="'name'"
                :optionsKey="'id'"
                v-model:formControlName="grupSelect"
                placeholder="Pilih Grup Kapal"
                :loading="loading"
                @onSelectChange="change"
              />

              <SelectMultiVessel
                :data="dataVessel"
                :optionsValue="'name'"
                :optionsKey="'mmsi'"
                v-model:formControlName="selectedVessel"
                placeholder="Pilih Kapal"
                :loading="loadingVessel"
                :isEditing="isEditing"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col items-end">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="#FFA400"
            class="w-4 h-4 cursor-pointer mb-1"
            @click="refresh"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>

          <small
            class="underline text-blue-700 cursor-pointer"
            v-if="isEditing"
            @click="cancel"
          >
            Cancel
          </small>
          <small
            v-if="role == 'admin'"
            class="underline text-blue-700 cursor-pointer"
            @click="!isEditing ? editGroup() : saveUpdate()"
          >
            {{ !isEditing ? "Edit" : "Save" }}
          </small>
        </div>
      </div>
      <small
        v-if="role == 'admin'"
        class="underline text-blue-700 cursor-pointer"
        @click="onCreate"
        >Buat Group Kapal Baru</small
      >
    </div>
  </div>
</template>

<script>
import SelectGroupVessel from "@/components/SelectGroupVessel.vue";
import SelectMultiVessel from "@/components/SelectMultiVessel.vue";
import { computed, onMounted, ref, watchEffect } from "vue";
import {
  useAuthStore,
  useShipStore,
  useVesselGroupStore,
  useVesselReportStore,
} from "@/store";
import axios from "../../config";
import { storeToRefs } from "pinia";

export default {
  props: {
    valueAll: Boolean,
    valueGrup: Boolean,
  },
  components: {
    SelectGroupVessel,
    SelectMultiVessel,
  },
  setup(props, { emit }) {
    const authStore = useAuthStore();
    const shipStore = useShipStore();
    const vesselGroupStore = useVesselGroupStore();
    const { grupSelect } = storeToRefs(vesselGroupStore);
    const vesselReportStore = useVesselReportStore();

    const grup = ref(1);
    const dataFetch = ref([]);
    const dataVessel = ref([]);
    const loading = ref(false);
    const loadingVessel = ref(false);
    const selectedVessel = ref([]);
    const isEditing = ref(false);

    const role = ref("");

    const valueName = computed({
      get() {
        return props.valueAll;
      },
      set(value) {
        emit("update:valueAll", value);
      },
    });

    const grupView = computed({
      get() {
        return props.valueGrup;
      },
      set(value) {
        emit("update:valueGrup", value);
      },
    });

    const fetchData = async () => {
      await vesselGroupStore.getVesselGroup();
      dataFetch.value = vesselGroupStore.$state.vesselGroup;
    };

    const getVessels = async () => {
      // await shipStore.getVessel();
      const data = vesselReportStore.dataKapal.filter(
        (item) => item.name != null
      );
      dataVessel.value = data;
      // dataVessel.value = shipStore.$state.dataKapal;
    };

    const getVesselByGroup = async (groupId) => {
      loadingVessel.value = true;
      await vesselGroupStore.vesselByGroup(groupId);
      loadingVessel.value = false;
      selectedVessel.value = vesselGroupStore.$state.byGroup;
    };

    const change = async (value) => {
      grup.value = value;
      vesselGroupStore.$state.grupSelect = grup.value;
      if (grup.value != 0) {
        getVesselByGroup(value);
      }
    };

    onMounted(async () => {});

    watchEffect(async () => {
      role.value = authStore.$state.user.role;
      vesselGroupStore.$state.grupSelect = 1;
      await fetchData();
      await getVessels();
      getVesselByGroup(grup.value);
    });

    const refresh = async () => {
      selectedVessel.value = [];
      role.value = authStore.$state.user.role;
      vesselGroupStore.$state.grupSelect = 1;
      await fetchData();
      await getVessels();
      getVesselByGroup(vesselGroupStore.$state.grupSelect);
    };

    const editGroup = () => {
      isEditing.value = true;
    };

    const cancel = () => {
      isEditing.value = false;
    };

    const saveUpdate = async () => {
      isEditing.value = false;
      await axios.delete("vessel_group/" + grup.value);

      try {
        for (let i = 0; i < selectedVessel.value.length; i++) {
          await axios.patch("vessel_group/" + grup.value, {
            group_id: grup.value,
            mmsi: selectedVessel.value[i].mmsi,
            name: selectedVessel.value[i].name,
          });
        }
        selectedVessel.value = [];
        getVesselByGroup(grup.value);
      } catch (error) {
        console.log(error);
      }
    };

    const setAll = () => {
      grupView.value = false;
      valueName.value = true;
    };

    const setGrup = () => {
      valueName.value = false;
      grupView.value = true;
    };

    const onClose = () => {
      shipStore.$state.showGroup = false;
    };

    const onCreate = () => {
      shipStore.$state.showVesselGroup = true;
      shipStore.$state.showGroup = false;
    };

    return {
      grup,
      fetchData,
      dataFetch,
      loading,
      change,
      selectedVessel,
      dataVessel,
      editGroup,
      isEditing,
      saveUpdate,
      cancel,
      valueName,
      grupView,
      grupSelect,
      loadingVessel,
      setAll,
      setGrup,
      onClose,
      onCreate,
      role,
      refresh,
    };
  },
};
</script>

<style></style>
