<template>
  <div class="main">
    <HeaderTitle title="Telegram Message">
      <IconTeleMessage size="w-11 h-11" />
    </HeaderTitle>
    <MainContent>
      <div class="flex flex-row">
        <div class="basis-5/12 border-r-2 border-gray-300">
          <ContactListView />
        </div>
        <div class="basis-full">
          <ChatView :id="chatStore.id" />
        </div>
      </div>
    </MainContent>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import IconTeleMessage from "@/components/icons/IconTeleMessage.vue";
import MainContent from "@/components/MainContent.vue";
import ContactListView from "./ContactListView.vue";
import ChatView from "./ChatView.vue";
import { useChatStore } from "@/store";
import { ref, watchEffect } from "vue";

export default {
  components: {
    HeaderTitle,
    IconTeleMessage,
    MainContent,
    ContactListView,
    ChatView,
  },
  setup() {
    const chatStore = useChatStore();
    const id = ref();

    watchEffect(() => {
      id.value = chatStore.$state.id;
      window.addEventListener("keydown", (event) => {
        if (event.key === "Escape") {
          chatStore.$patch((state) => {
            state.id = 0;
            state.open = false;
          });
        }
      });
    });
    return {
      chatStore,
      id,
    };
  },
};
</script>

<style></style>
