import { getData } from "@/utils/fetch";
import debounce from "debounce-promise";
import { defineStore } from "pinia";

let debouncedFetch = debounce(getData, 500);

export const useSOSStore = defineStore({
  id: "SOS",
  state: () => ({
    sos: {},
  }),
  getters: {
    getDataSOS(state) {
      return state.sos.data;
    },
  },
  actions: {
    async getAll(params) {
      this.sos = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        order: params?.order || "message_id",
        ordertype: params?.ordertype || "ASC",
        q: params?.q,
        fromDate: params?.fromDate,
        toDate: params?.toDate,
      };

      try {
        const data = await debouncedFetch("/logbook/sos", dataparams);
        this.sos = { ...data.data };
      } catch (error) {
        this.sos = { error };
      }
    },
  },
});
