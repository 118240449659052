<template>
  <ModalComponent :id="id" title="Edit Defined Message">
    <template #body>
      <Form @submit="acceptModal">
        <div class="p-6 space-y-3">
          <InputWithLabel
            label="Text Defined *"
            type="text"
            name="text_defined"
            :value="textDefined"
          />
        </div>
        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary"
              >Save</ButtonComponent
            >
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import { Form } from "vee-validate";
import ModalComponent from "@/components/ModalComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useDefinedMessage } from "@/store";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    Form,
    ModalComponent,
    InputWithLabel,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const definedStore = useDefinedMessage();
    const { textDefined } = storeToRefs(definedStore);

    const acceptModal = async (value) => {
      const { text_defined } = value;

      await definedStore.updateDefinedMessage(text_defined);
    };

    return {
      textDefined,
      acceptModal,
    };
  },
};
</script>

<style></style>
