import { defineStore } from "pinia";
import router from "@/router";
import { useAlertStore, useChatStore, useErrorStore } from "@/store";
import axios from "axios";
import { googleLogout } from "vue3-google-login";
import { ref } from "vue";

const baseUrl = process.env.VUE_APP_API_URL;

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // user: JSON.parse(localStorage.getItem("auth")),
    user: JSON.parse(localStorage.getItem("user")),
    tokens: ref(JSON.parse(localStorage.getItem("token"))),
    returnUrl: null,
  }),
  actions: {
    async login(email, password, google = false, credential = {}) {
      try {
        if (!google) {
          let user = await axios.post(baseUrl + "auth/login", {
            email: email,
            password: password,
          });
          this.user = user.data.user;
          localStorage.setItem("user", JSON.stringify(user.data.user));
          localStorage.setItem("token", JSON.stringify(user.data.tokens));
        } else {
          this.user = credential;

          localStorage.setItem("user", JSON.stringify(credential));
          localStorage.setItem("token", JSON.stringify(credential));
        }

        router.push(this.returnUrl || "/");
        const chatStore = useChatStore();
        await chatStore.createConnection();
        chatStore.subscribe();
      } catch (error) {
        console.log(error);
        if (error) {
          const alertStore = useAlertStore();
          alertStore.error(error);
        } else {
          this.result = {};
          const errorMessage = useErrorStore();
          errorMessage.open = true;
        }
      }
    },
    logout() {
      const chatStore = useChatStore();
      this.user = null;
      console.log("Logout google account");
      googleLogout();

      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("auth");
      chatStore.disconnect();

      console.log(chatStore.clien);
      router.push("/login");
    },
    async refreshToken() {
      try {
        const newToken = await axios.post(baseUrl + "auth/refresh-tokens", {
          refreshToken: this.tokens.refresh.token,
        });
        const refresh = newToken.data;
        this.tokens = refresh;

        localStorage.setItem("token", JSON.stringify(refresh));
        return Promise.resolve();
      } catch (error) {
        console.log(error);
      }
    },
  },
});
