<template>
  <div class="px-4 py-4 flex items-center rounded-r-lg">
    <div class="flex-1 mx-3">
      <textarea
        class="inputVts w-full border border-gray-300 bg-[#D9D9D9] rounded-full px-3 py-2 resize-none focus:ring-0 focus:border-[#D9D9D9]"
        rows="1"
        placeholder="Ketik sesuatu ..."
        v-model="message"
      ></textarea>
    </div>

    <div class="cursor-pointer mb-2 absolute right-[105px]" @click="publish">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#195563"
        class="w-7 h-7"
      >
        <path
          d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { useChatVesselStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

export default {
  setup() {
    const chatVesselStore = useChatVesselStore();
    const { message } = storeToRefs(chatVesselStore);

    const input = ref(null);

    const publish = () => {
      chatVesselStore.publish();
    };

    const keyboardListen = (evt) => {
      if (evt.key === "Enter" && evt.shiftKey) {
        input.value + "\n";
        return true;
      }
      if (evt.key === "Enter" && !evt.shiftKey) {
        evt.preventDefault();
        publish();
      }
    };

    onMounted(async () => {
      input.value = document.querySelector(".inputVts");
      input.value.addEventListener("keydown", keyboardListen);
    });

    return {
      message,
      publish,
    };
  },
};
</script>

<style></style>
