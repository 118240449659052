<template>
  <div class="main">
    <HeaderTitle title="Telegram User">
      <IconContact size="w-10 h-10" color="#14444f" />
    </HeaderTitle>
    <MainContent>
      <div class="pt-5 relative">
        <div
          class="flex flex-col md:flex-row space-y-2 md:space-y-0 justify-between pb-5"
        >
          <SearchInput @input="search" id="tele_user" />
          <DatePickerNoLabel
            v-model:formControlName="dateFilter"
            @onClickApply="onClickApply"
          />
        </div>
        <div v-if="result.rows?.length">
          <div class="pb-3" v-if="dateDisplay != ''">
            <span
              class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
              >{{ dateDisplay }}</span
            >
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5 mb-3">
            <CardTeleUser
              :selected="selected == db.chat_id"
              v-for="db in result.rows"
              :key="db.chat_id"
              :username="db.username"
              :first_name="db.first_name"
              :last_name="db.last_name"
              :chat_id="db.chat_id"
              :status="db.status ? db.status : '0'"
              :date="date(db.createdAt)"
              @click="onClick(db)"
            />
          </div>
          <PaginationComponent
            v-model:size="size"
            v-model:length="length"
            v-model:page="page"
            @onSelectChange="changePage"
          />
        </div>
        <div
          class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-5"
          v-if="result.loading"
        >
          <SkeletonComponent width="h-56" v-for="index in 4" :key="index" />
        </div>
        <div class="flex justify-center" v-if="result.rows?.length == 0">
          <NoData size="w-full md:w-5/12" />
        </div>
        <AlertErrorComponent
          v-if="result.error"
          :message="result.error.response.data.message"
        />
      </div>
    </MainContent>
  </div>
  <TeleUserDetail v-if="!result.error" />
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import MainContent from "@/components/MainContent.vue";
import IconContact from "@/components/icons/IconContact.vue";
import SearchInput from "@/components/SearchInput.vue";
import DatePickerNoLabel from "@/components/DatePickerNoLabel.vue";
import CardTeleUser from "@/components/CardTeleUser.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import SkeletonComponent from "@/components/SkeletonComponent.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import TeleUserDetail from "./TeleUserDetail.vue";
import { useSidebarStore, useTeleUserStore } from "@/store";
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";

export default {
  components: {
    HeaderTitle,
    MainContent,
    IconContact,
    SearchInput,
    DatePickerNoLabel,
    CardTeleUser,
    PaginationComponent,
    SkeletonComponent,
    NoData,
    AlertErrorComponent,
    TeleUserDetail,
  },
  setup() {
    const sidebarStore = useSidebarStore();
    const teleUserStore = useTeleUserStore();
    const { result, length, page, selected } = storeToRefs(teleUserStore);

    const offset = ref(0);
    const size = ref(10);
    const q = ref("");
    const dateFilter = ref([]);
    const dateDisplay = ref("");

    const select = () => {
      sidebarStore.$patch((state) => {
        if (teleUserStore.result?.count != 0) {
          if (screen.width >= 768) {
            state.open = true;
            selected.value = teleUserStore.result.rows[0].chat_id;
          }

          if (state.id != teleUserStore.result.rows[0].chat_id) {
            state.id = teleUserStore.result.rows[0].chat_id;
            state.data = teleUserStore.result.rows[0];
          }
        } else {
          state.open = false;
        }
      });
    };

    onMounted(async () => {
      await teleUserStore.getAll({ offset: offset.value });
      teleUserStore.length = teleUserStore.result.count;
      select();
    });

    const search = async (value) => {
      offset.value = 0;
      page.value = 1;
      let dataParams = {
        offset: offset.value,
        q: value.target.value,
      };

      if (dateFilter.value != null) {
        if (dateFilter.value.length != 0) {
          dataParams = {
            ...dataParams,
            fromDate: moment(dateFilter.value[0]).format("YYYY-MM-DD"),
            toDate: moment(dateFilter.value[1]).format("YYYY-MM-DD"),
          };
        }
      }
      await teleUserStore.getAll(dataParams);
      q.value = value.target.value;
      length.value = teleUserStore.result.count;
      select();
    };

    const onClickApply = async () => {
      offset.value = 0;
      page.value = 1;
      let dataParams = { offset: offset.value };

      if (q.value) {
        dataParams = {
          ...dataParams,
          q: q.value,
        };
      }

      if (dateFilter.value != null) {
        dateDisplay.value = `${moment(dateFilter.value[0]).format(
          "DD MMM YYYY"
        )} - ${moment(dateFilter.value[1]).format("DD MMM YYYY")}`;
        dataParams = {
          ...dataParams,
          fromDate: moment(dateFilter.value[0]).format("YYYY-MM-DD"),
          toDate: moment(dateFilter.value[1]).format("YYYY-MM-DD"),
        };
      } else {
        dateDisplay.value = "";
        dataParams;
      }

      await teleUserStore.getAll(dataParams);
      length.value = teleUserStore.result.count;
      select();
    };

    const onClick = (value) => {
      selected.value = value.chat_id;
      sidebarStore.$patch((state) => {
        if (!state.open) {
          state.open = !state.open;
        }

        if (state.id != value.chat_id) {
          state.id = value.chat_id;
          state.data = value;
        }
      });
    };

    const changePage = async (value) => {
      page.value = value;
      offset.value = (page.value - 1) * size.value;
      let dataParams = { offset: offset.value };

      if (q.value) {
        dataParams = {
          ...dataParams,
          q: q.value,
        };
      }

      if (dateFilter.value != null && dateFilter.value.length != 0) {
        dataParams = {
          ...dataParams,
          fromDate: moment(dateFilter.value[0]).format("YYYY-MM-DD"),
          toDate: moment(dateFilter.value[1]).format("YYYY-MM-DD"),
        };
      }
      if (dateFilter.value == null) {
        dataParams;
      }

      await teleUserStore.getAll(dataParams);
      select();
    };

    const date = (value) => {
      return moment(value).format("DD MMMM YYYY, HH:mm:ss");
    };

    return {
      result,
      page,
      length,
      size,
      selected,
      dateFilter,
      dateDisplay,
      search,
      onClickApply,
      onClick,
      changePage,
      date,
    };
  },
};
</script>
