<template>
  <div
    className="flex pt-16 justify-center min-w-full min-h-screen bg-gray-100"
  >
    <div className="w-2/3 lg:w-1/3">
      <div class="flex justify-center pb-8 max-w-sm">
        <h1 class="text-2xl font-bold font-sans text-greenPrimary">
          <!-- Seahub Messaging -->
        </h1>
        <img src="../../assets/logo-seahub.png" width="150"/>
      </div>
      <CardComponent>
        <h1
          className="font-semibold text-xl text-center text-greenPrimary uppercase"
        >
          Register
        </h1>
        <p class="text-center mb-5 text-gray-500">Sign Up New Account</p>
        <AlertSuccessComponent
          v-if="success"
          message="Register new account success"
        />
        <Form
          name="form-register"
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors, isSubmitting }"
        >
          <div class="flex space-x-5">
            <InputWithLabel
              label="First Name *"
              type="text"
              name="firstName"
              :error="errors.firstName"
            />
            <InputWithLabel label="Last Name" type="text" name="lastName" />
          </div>
          <InputWithLabel
            label="Email *"
            type="email"
            name="email"
            :error="errors.email"
          />
          <InputWithLabel
            label="Password *"
            type="password"
            name="password"
            :error="errors.password"
          />
          <ButtonComponent vClass="w-full bg-darkGreen" :loading="isSubmitting"
            >Sign Up</ButtonComponent
          >
          <span class="text-xs font-sans font-semibold text-gray-700"
            >Already have an account?
            <a class="text-red-800" href="/login">Sign In</a></span>
            
        </Form>
        <div class="text-center">
          <p>OR</p>
          <GoogleLogin :callback="callback"/>
          </div>
      </CardComponent>
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import CardComponent from "@/components/CardComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import AlertSuccessComponent from "@/components/AlertSuccessComponent.vue";
import * as Yup from "yup";
import { useErrorStore } from "@/store";
import { ref } from "vue";
import axios from "axios";

export default {
  components: {
    Form,
    CardComponent,
    InputWithLabel,
    ButtonComponent,
    AlertSuccessComponent,
  },
  setup() {
    const callback = (response) => {
      // This callback will be triggered when the user selects or login to
      // his Google account from the popup
      console.log("Handle the response", response)
    }

    const schema = Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(
          /[a-zA-Z]/,
          "Password must contain at least 1 letter and 1 number"
        )
        .matches(/\d/, "Password must contain at least 1 letter and 1 number"),
    });

    const errorStore = useErrorStore();
    const success = ref(false);

    async function onSubmit(value) {
      const baseUrl = process.env.VUE_APP_API_URL;
      const { firstName, lastName, email, password } = value;
      try {
        await axios.post(baseUrl + "users", {
          firstName: firstName,
          lastName: lastName ? lastName : " ",
          email: email,
          password: password,
          role: "user",
        });
        success.value = true;
        document.getElementsByName("form-register")[0].reset();
      } catch (error) {
        errorStore.open = true;
        errorStore.message = error.response.data.message
          ? error.response.data.message
          : errorStore.message;
        console.log(error);
      }
    }

    return {
      onSubmit,
      schema,
      success,
      callback,
    };
  },
};
</script>

<style></style>
