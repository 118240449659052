<template>
  <div class="main">
    <HeaderTitle title="Mobile User">
      <IconMobileUser size="w-10 h-10" color="#14444f" />
    </HeaderTitle>
    <MainContent>
      <div class="pt-5 relative">
        <div
          class="flex flex-col md:flex-row space-y-2 md:space-y-0 justify-between pb-2"
        >
          <SearchInput @input="search" id="mobile_user" />

          <ButtonComponent
            vClass="text-xs text-greenPrimary bg-gray-300 hover:bg-[#c6cbd2] focus:ring-0 px-3 py-2"
            @click="modal.showModal(1)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>Register Mobile</span>
          </ButtonComponent>

          <RegisterMobileUser :id="1" />
        </div>

        <div
          class="flex flex-col md:flex-row space-y-2 md:space-y-0 justify-between pb-5"
        >
          <FilterByModem
            v-model:formControlName="group"
            @onSelectChange="changeData()"
            @onClose="close"
          />

          <DatePickerNoLabel
            v-model:formControlName="dateFilter"
            @onClickApply="onClickApply"
          />
        </div>

        <div class="pt-3 pb-3" v-if="rangeDate != ''">
          <span
            class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
          >
            {{ rangeDate }}
          </span>
        </div>

        <div v-if="mobileUsers.rows?.length">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5 mb-3">
            <CardMobileUser
              :selected="selected == mu.mobile_id"
              v-for="mu in mobileUsers.rows"
              :key="mu.chat_id"
              :firstname="mu.first_name"
              :lastname="mu.last_name"
              :mobileid="mu.mobile_id"
              :date="date(mu.createdAt)"
              :isactive="mu.is_active"
              @click="onClick(mu)"
            />
          </div>
          <PaginationComponent
            v-model:size="size"
            v-model:length="length"
            v-model:page="page"
            @onSelectChange="changePage"
          />
        </div>
        <div
          class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-5"
          v-if="mobileUsers.loading"
        >
          <SkeletonComponent width="h-56" v-for="index in 4" :key="index" />
        </div>
        <div class="flex justify-center" v-if="mobileUsers.rows?.length == 0">
          <NoData size="w-full md:w-5/12" />
        </div>
        <AlertErrorComponent
          v-if="mobileUsers.error"
          :message="mobileUsers.error.response.data.message"
        />
      </div>
    </MainContent>
  </div>
  <MobileUserDetail v-if="!mobileUsers.error" />
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import MainContent from "@/components/MainContent.vue";
import IconMobileUser from "@/components/icons/IconMobileUser.vue";
import SearchInput from "@/components/SearchInput.vue";
import CardMobileUser from "@/components/CardMobileUser.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import SkeletonComponent from "@/components/SkeletonComponent.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import MobileUserDetail from "./MobileUserDetail.vue";
import RegisterMobileUser from "./RegisterMobileUser.vue";
import FilterByModem from "@/components/FilterByModem.vue";
import DatePickerNoLabel from "@/components/DatePickerNoLabel.vue";
import {
  useMobileUserStore,
  useModalStore,
  useModemStore,
  useSidebarStore,
} from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import moment from "moment";

export default {
  components: {
    HeaderTitle,
    MainContent,
    IconMobileUser,
    SearchInput,
    CardMobileUser,
    PaginationComponent,
    SkeletonComponent,
    NoData,
    AlertErrorComponent,
    ButtonComponent,
    MobileUserDetail,
    RegisterMobileUser,
    FilterByModem,
    DatePickerNoLabel,
  },
  setup() {
    const sidebarStore = useSidebarStore();
    const modal = useModalStore();
    const modemStore = useModemStore();
    const { listModem } = storeToRefs(modemStore);
    const mobileUserStore = useMobileUserStore();
    const { mobileUsers, length, page, selected, dateFilter } =
      storeToRefs(mobileUserStore);

    const offset = ref(0);
    const size = ref(10);
    const q = ref("");
    const group = ref("all");
    const rangeDate = ref("");

    const getAllModem = async () => {
      await modemStore.getAllModem();
    };

    const select = () => {
      sidebarStore.$patch((state) => {
        if (mobileUserStore.mobileUsers?.count != 0) {
          if (screen.width >= 768) {
            state.open = true;
            selected.value = mobileUserStore.mobileUsers.rows[0].mobile_id;
          }

          if (state.id != mobileUserStore.mobileUsers.rows[0].mobile_id) {
            state.id = mobileUserStore.mobileUsers.rows[0].mobile_id;
            state.data = mobileUserStore.mobileUsers.rows[0];
          }
        } else {
          state.open = false;
        }
      });
    };

    onMounted(async () => {
      getAllModem();
      await mobileUserStore.getAll({ offset: offset.value });
      mobileUserStore.length = mobileUserStore.mobileUsers.count;
      select();
    });

    const search = async (value) => {
      offset.value = 0;
      page.value = 1;
      let dataParams = {
        offset: offset.value,
        q: value.target.value,
        modemid: group.value,
      };

      if (mobileUserStore.dateFilter != null) {
        if (mobileUserStore.dateFilter.length != 0) {
          dataParams = {
            ...dataParams,
            fromDate: moment(mobileUserStore.dateFilter[0]).format(
              "YYYY-MM-DD"
            ),
            toDate: moment(mobileUserStore.dateFilter[1]).format("YYYY-MM-DD"),
          };
        } else {
          await mobileUserStore.getAll(dataParams);
        }
      }

      await mobileUserStore.getAll(dataParams);
      q.value = value.target.value;
      length.value = mobileUserStore.mobileUsers.count;
      select();
    };

    const onClickApply = async () => {
      offset.value = 0;
      mobileUserStore.page = 1;
      let dataParams = {
        offset: offset.value,
      };

      if (mobileUserStore.q) {
        dataParams = {
          ...dataParams,
          q: mobileUserStore.q,
        };
      }

      if (mobileUserStore.dateFilter != null) {
        rangeDate.value = `${moment(dateFilter.value[0]).format(
          "DD MMM YYYY"
        )} - ${moment(dateFilter.value[1]).format("DD MMM YYYY")}`;

        dataParams = {
          ...dataParams,
          fromDate: moment(mobileUserStore.dateFilter[0]).format("YYYY-MM-DD"),
          toDate: moment(mobileUserStore.dateFilter[1]).format("YYYY-MM-DD"),
        };
      } else {
        rangeDate.value = "";
        dataParams;
      }

      await mobileUserStore.getAll(dataParams);
      mobileUserStore.length = mobileUserStore.mobileUsers.count;
      select();
    };

    const onClick = (value) => {
      selected.value = value.mobile_id;
      sidebarStore.$patch((state) => {
        if (!state.open) {
          state.open = !state.open;
        }

        if (state.id != value.mobile_id) {
          state.id = value.mobile_id;
          state.data = value;
        }
      });
    };

    const changePage = async (value) => {
      page.value = value;
      offset.value = (page.value - 1) * size.value;
      let dataParams = { offset: offset.value, modemid: group.value };

      if (q.value) {
        dataParams = {
          ...dataParams,
          q: q.value,
        };
      }

      await mobileUserStore.getAll(dataParams);
      select();
    };

    const date = (value) => {
      return moment(value).format("DD MMMM YYYY");
    };

    const changeData = async () => {
      offset.value = 0;
      page.value = 1;
      await mobileUserStore.getAll({
        offset: offset.value,
        modemid: group.value,
      });
      length.value = mobileUserStore.mobileUsers.count;
      select();
    };

    const close = () => {
      group.value = "all";
      changeData();
    };

    return {
      modal,
      mobileUsers,
      listModem,
      page,
      length,
      size,
      selected,
      group,
      rangeDate,
      dateFilter,
      search,
      onClick,
      changePage,
      date,
      onClickApply,
      changeData,
      close,
    };
  },
};
</script>

<style></style>
