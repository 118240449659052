<template>
  <SidebarRight>
    <div v-if="!users.loading">
      <div class="px-8">
        <TitleSubIconGap :title="data.email" titleColor="text-white">
          <IconUserCircle size="w-12 h-12" />
        </TitleSubIconGap>
        <TittleBetween addClass="pt-8 pb-5" title="Basic Info" />
      </div>
      <div class="flex flex-col space-y-5 px-8 [&_svg]:w-6 [&_svg]:h-6">
        <TextIconGroup
          :textWhite="true"
          :text="data.firstName + ' ' + data.lastName"
          textSize="text-md"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path
              d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z"
            />
          </svg>
        </TextIconGroup>
        <TextIconGroup
          :textWhite="true"
          :text="data.role.toUpperCase()"
          textSize="text-md"
        >
          <IconRole />
        </TextIconGroup>
        <TextIconGroup
          :textWhite="true"
          :text="data.isEmailVerified == true ? 'Verified' : 'Not Verified'"
          textSize="text-md"
        >
          <IconCheck />
        </TextIconGroup>
        <TextIconGroup
          :textWhite="true"
          :text="date(data.createdAt)"
          textSize="text-md"
        >
          <IconDatePlus />
        </TextIconGroup>
        <TextIconGroup
          :textWhite="true"
          :text="date(data.updatedAt)"
          textSize="text-md"
        >
          <IconDate />
        </TextIconGroup>
      </div>
    </div>
    <SkeletonDetailComponent v-if="users.loading" />
  </SidebarRight>
</template>

<script>
import SidebarRight from "@/components/SidebarRight.vue";
import TitleSubIconGap from "@/components/TitleSubIconGap.vue";
import IconUserCircle from "@/components/icons/IconUserCircle.vue";
import TittleBetween from "@/components/TittleBetween.vue";
import TextIconGroup from "@/components/TextIconGroup.vue";
import IconRole from "@/components/icons/IconRole.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import IconDatePlus from "@/components/icons/IconDatePlus.vue";
import IconDate from "@/components/icons/IconDate.vue";
import SkeletonDetailComponent from "@/components/SkeletonDetailComponent.vue";
import { useSidebarStore, useUserStore } from "@/store";
import { computed, onMounted } from "vue";
import moment from "moment";
import { storeToRefs } from "pinia";

export default {
  components: {
    SidebarRight,
    TitleSubIconGap,
    IconUserCircle,
    TittleBetween,
    TextIconGroup,
    IconRole,
    IconCheck,
    IconDatePlus,
    IconDate,
    SkeletonDetailComponent,
  },
  setup() {
    const sidebar = useSidebarStore();
    const userStore = useUserStore();
    const { users } = storeToRefs(userStore);

    onMounted(() => {
      userStore.getAll({});
    });
    const data = computed(() => sidebar.getData);
    const date = (date) => {
      return moment(date).format("DD MMMM YYYY, HH:mm:ss");
    };

    return {
      users,
      data,
      date,
    };
  },
};
</script>

<style></style>
