<template>
  <div class="main">
    <HeaderTitle title="Form">
      <IconForm size="w-10 h-10" color="#14444f" />
    </HeaderTitle>
    <MainContent>
      <div class="pt-3 flex items-center justify-between">
        <SelectSearchWithLabel
          :label="'Jenis Form'"
          :data="result.rows?.length ? result.rows : []"
          :optionsValue="'form_name'"
          :optionsKey="'form_id'"
          v-model:formControlName="select"
          :loading="result.loading ? true : false"
          @onSelectChange="changeData"
          placeholder="Pilih Form"
        />

        <ButtonComponent
          vClass="text-xs text-greenPrimary bg-gray-300 hover:bg-[#c6cbd2] focus:ring-0 px-3 py-2 space-x-1 flex items-center"
          @click="newForm"
        >
          <IconFileAdd />
          <span>New Form</span>
        </ButtonComponent>
      </div>

      <div v-if="forms.form_properties?.length">
        <FormKit id="form" type="form" @submit="submitForm">
          <div v-for="(prop, index) in forms.form_properties" :key="index">
            <div
              v-if="
                prop.type != 'radio' &&
                prop.type != 'select' &&
                prop.type != 'checkbox' &&
                prop.type != 'textarea'
              "
            >
              <FormKit
                :type="prop.type"
                :name="prop.name"
                :label="prop.name"
                :validation="prop.validation"
              />
            </div>

            <div v-if="prop.type == 'textarea'">
              <FormKit
                :type="prop.type"
                :name="prop.name"
                :label="prop.name"
                :validation="prop.validation"
              />
            </div>

            <div v-if="prop.type == 'radio'">
              <FormKit
                :type="prop.type"
                :name="prop.name"
                :label="prop.name"
                :validation="prop.validation"
                :options="prop.options"
              />
            </div>
            <div v-if="prop.type == 'select'">
              <FormKit
                :type="prop.type"
                :name="prop.name"
                :label="prop.name"
                :validation="prop.validation"
                :options="prop.options"
                :placeholder="`Pilih ${prop.name}`"
              />
            </div>
            <div v-if="prop.type == 'checkbox'">
              <FormKit
                :type="prop.type"
                :name="prop.name"
                :label="prop.name"
                :validation="prop.validation"
                :options="prop.options"
              />
            </div>
          </div>
        </FormKit>
      </div>
      <div v-if="select == '' && !result.loading && !forms.loading">
        <p>No Form Selected</p>
      </div>
      <LoadingLottie v-if="result.loading || forms.loading" />
    </MainContent>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import MainContent from "@/components/MainContent.vue";
import IconForm from "@/components/icons/IconForm.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import SelectSearchWithLabel from "@/components/SelectSearchWithLabel.vue";
import IconFileAdd from "@/components/icons/IconFileAdd.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import router from "@/router";
import { onMounted } from "vue";
import {
  useAuthStore,
  useChatStore,
  useFormStore,
  useReportStore,
} from "@/store";
import { storeToRefs } from "pinia";
import { reset } from "@formkit/core";
import axios from "@/config";

export default {
  components: {
    HeaderTitle,
    MainContent,
    ButtonComponent,
    IconForm,
    SelectSearchWithLabel,
    IconFileAdd,
    LoadingLottie,
  },
  setup() {
    const authStore = useAuthStore();
    const chatStore = useChatStore();
    const reportStore = useReportStore();
    const formStore = useFormStore();
    const { select, forms, result } = storeToRefs(formStore);

    const newForm = () => {
      router.push("/form_generator/form_generator_new");
    };

    const changeData = async () => {
      await formStore.getForm();
    };

    const submitForm = async (val) => {
      var keys = Object.keys(val);
      const values = keys.map(function (key) {
        return { key: key, value: val[key] };
      });

      let reportValue = [];
      formStore.forms.form_properties.forEach((properties) => {
        let value = values.find((data) => data.key === properties.name);
        if (value) {
          reportValue.push({
            ...properties,
            value: value.value,
          });
        } else {
          reportValue.push({ ...properties });
        }
      });

      const topic = "shb/out/message";
      let payload = `{"message": "report", "status": 1, "sender": "${chatStore.id}","receiver": "${authStore.user.firstName}"}`;

      chatStore.clien.publish(topic, payload, async (error) => {
        if (error) {
          console.log("publish error : ", error);
        } else {
          console.log(`publish message ${payload} to topic ${topic}`);
          const data = JSON.parse(payload);
          try {
            const chat = await axios.post("tele_chat", {
              sender: data.sender,
              receiver: data.receiver,
              message: data.message,
              status: data.status,
              contact: data.sender,
            });
            await reportStore.saveReport(
              formStore.forms.form_name,
              reportValue,
              chat.data.id_message
            );
          } catch (error) {
            console.log(error);
          }
        }
      });

      reset("form");
    };

    onMounted(async () => {
      chatStore.createConnection();
      await formStore.getAll();
      formStore.getForm();
    });

    return {
      newForm,
      result,
      select,
      forms,
      changeData,
      submitForm,
      chatStore,
    };
  },
};
</script>

<style></style>
