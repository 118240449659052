<template>
  <div>
    <div class="inline-flex items-center">
      <Datepicker
        v-model="formName"
        :enable-time-picker="false"
        :format="format"
        calendar-class-name="p-3"
        placeholder="Select Range Date"
        :input-class-name="'input-date'"
        range
      />
      <ButtonComponent
        vClass="bg-gray-300 hover:bg-[#c6cbd2] text-greenPrimary px-2 py-2.5 rounded-l-none rounded-r-sm focus:ring-0"
        @click="$emit('onClickApply')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="1.5"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
          />
        </svg>
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { computed } from "vue";

export default {
  components: {
    Datepicker,
    ButtonComponent,
  },
  props: {
    formControlName: Array,
    theme: {
      type: String,
      default: "default",
    },
  },
  setup(props, { emit }) {
    const formName = computed({
      get() {
        return props.formControlName;
      },
      set(value) {
        emit("update:formControlName", value);
      },
    });

    const format = (datePick) => {
      const date = datePick[0];
      const date2 = datePick[1];
      let value = "";

      if (date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        value = value.concat(`${day}/${month}/${year}`);
      }

      if (date2) {
        const day2 = date2.getDate();
        const month2 = date2.getMonth() + 1;
        const year2 = date2.getFullYear();
        value = value.concat(` - ${day2}/${month2}/${year2}`);
      }

      return value;
    };

    return {
      format,
      formName,
    };
  },
};
</script>
