<template>
  <div
    class="h-14 py-2 px-3 bg-greenSide flex flex-row justify-between items-center"
  >
    <div class="flex items-center space-x-2">
      <div class="rounded-full bg-gray-400 p-1">
        <IconUser color="#E5E7EB" />
      </div>
      <p class="text-white">
        {{ contactById.first_name }} {{ contactById.last_name }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import IconUser from "./icons/IconUser.vue";
import { useChatStore } from "@/store";

export default {
  components: {
    IconUser,
  },
  setup() {
    const chatStore = useChatStore();

    const contactById = computed(() => chatStore.getData);

    return {
      contactById,
    };
  },
};
</script>

<style></style>
