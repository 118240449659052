<template>
  <div class="flex mb-3 justify-end items-center px-4 pt-4">
    <a
      href="#"
      @click="openNav"
      :class="[open ? 'hidden' : 'block']"
      class="fixed z-10 p-2 top-3 left-5 text-indigo-400 transition-colors duration-200 rounded-md bg-indigo-50 hover:text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:bg-indigo-100 block md:hidden focus:ring-indigo-800"
    >
      <span class="sr-only">Menu</span>
      <svg
        fill="none"
        class="w-7 h-7"
        stroke="currentColor"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        ></path>
      </svg>
    </a>
    <h1 class="text-2xl text-end font-bold text-greenPrimary font-sans">
      <!-- Seahub Messaging -->
    </h1>
    <img src="../assets/logo-seahub.png" width="150"/>
    <!-- <img
      alt="Smartmedia logo"
      width="150"
      src="../assets/smartmedia-logo.png"
    /> -->
  </div>
</template>

<script>
import { useNavigationStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const navigationStore = useNavigationStore();
    const { open } = storeToRefs(navigationStore);

    const openNav = () => {
      navigationStore.open = !navigationStore.open;
    };

    return {
      openNav,
      open,
    };
  },
};
</script>
