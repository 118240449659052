<template>
  <div class="bg-gray-300 px-4 py-4 flex items-center">
    <div class="flex-1 mx-3">
      <div class="absolute bottom-20" v-show="showOptions">
        <ul class="bg-[#a1a1a1] w-60 max-h-60 overflow-y-auto">
          <li
            v-for="(item, index) in result.rows"
            :key="index"
            @click="handleClick(item)"
            class="px-3 py-2 text-sm text-white cursor-pointer hover:bg-[#aeaeae]"
          >
            {{ item.text_defined }}
          </li>
        </ul>
      </div>
      <textarea
        class="input w-full border border-gray-300 rounded px-2 py-2 resize-none"
        :rows="1"
        placeholder="Type a message"
        @input="search"
        v-model="message"
      ></textarea>
    </div>
    <div class="cursor-pointer" @click="publish">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#263238"
        stroke-opacity=".45"
        class="w-7 h-7"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { useChatStore, useDefinedMessage } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

export default {
  setup() {
    const definedStore = useDefinedMessage();
    const chatStore = useChatStore();
    const { result } = storeToRefs(definedStore);
    const { message, showOptions } = storeToRefs(chatStore);

    const input = ref(null);

    const publish = () => {
      chatStore.publish();
      chatStore.showOptions = false;
    };

    const keyboardListen = (evt) => {
      if (evt.key === "Enter" && evt.shiftKey) {
        input.value + "\n";
        return true;
      }
      if (evt.key === "Enter" && !evt.shiftKey) {
        evt.preventDefault();
        chatStore.publish();
        chatStore.showOptions = false;
      }
    };

    onMounted(async () => {
      input.value = document.querySelector(".input");
      input.value.addEventListener("keydown", keyboardListen);
    });

    const search = async (value) => {
      chatStore.suggestText(value);
    };

    const handleClick = (item) => {
      chatStore.message = item.text_defined;
      chatStore.showOptions = false;
      chatStore.textSelect = item.text_id;
      const input = document.querySelector(".input");
      input.addEventListener("keydown", keyboardListen);
    };

    return {
      definedStore,
      result,
      message,
      showOptions,
      publish,
      search,
      handleClick,
    };
  },
};
</script>

<style></style>
