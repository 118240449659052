<template>
  <ModalComponent :id="id" title="Update Seahub Device">
    <template #body>
      <Form @submit="update">
        <div class="p-6 space-y-3">
          <InputWithLabel
            label="Serial Number"
            type="text"
            name="serialNumber"
            :value="byId.serialNumber"
          />
          <InputWithLabel
            label="MMSI"
            type="text"
            name="mmsi"
            :value="byId.mmsi"
          />
          <InputWithLabel
            label="Mode"
            type="text"
            name="mode"
            :value="byId.mode.toString()"
          />
        </div>
        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary">
              Update
            </ButtonComponent>
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import { Form } from "vee-validate";
import ModalComponent from "@/components/ModalComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useSeahubDevice } from "@/store";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    Form,
    ModalComponent,
    InputWithLabel,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const deviceStore = useSeahubDevice();
    const { byId } = storeToRefs(deviceStore);

    const update = async (value) => {
      const { serialNumber, mmsi, mode } = value;
      await deviceStore.updateDevice(serialNumber, mmsi, mode);
    };

    return {
      byId,
      update,
    };
  },
};
</script>

<style></style>
