<template>
  <div
    class="bg-greenSide w-full flex justify-between items-center text-white font-medium py-2 px-3 rounded-t-sm"
  >
    Atur Batas Kecepatan
    <button
      class="bg-gray-100 hover:bg-gray-200 rounded-md p-1"
      @click="modal.showModal(2)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="#195563"
        class="w-4 h-4"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 4.5v15m7.5-7.5h-15"
        />
      </svg>
    </button>
    <NewBatasKecepatan :id="2" />
  </div>
  <div v-if="speed.rows?.length" class="-mb-3 max-h-[393px] overflow-y-auto">
    <table class="w-full text-sm text-left text-gray-500 font-medium">
      <tbody>
        <tr class="bg-white border-b text-gray-900">
          <th scope="row" class="py-2.5 px-4 text-center">#</th>
          <th scope="row" class="py-2.5 px-6">Area</th>
          <th scope="row" class="py-2.5 px-6 text-center">Batas Min</th>
          <th scope="row" class="py-2.5 px-6 text-center">Batas Max</th>
          <th scope="row" class="py-2.5 px-6 text-center">Rentang Waktu</th>
          <th scope="row" class="py-2.5 px-6 text-center">Aksi</th>
        </tr>

        <tr
          class="bg-white border-b"
          v-for="(data, index) in speed.rows"
          :key="data.id"
        >
          <td class="py-2.5 px-4 text-center">{{ index + 1 }}</td>
          <td class="py-2.5 px-6">{{ data.areaGeofence.area }}</td>
          <td class="py-2.5 px-6 text-center">{{ data.batas_min }}</td>
          <td class="py-2.5 px-6 text-center">{{ data.batas_max }}</td>
          <td class="py-2.5 px-6 text-center">
            {{ data.waktu_awal }} - {{ data.waktu_akhir }}
          </td>
          <td class="py-2.5 px-6 text-center">
            <button
              class="bg-green-700 hover:bg-green-800 rounded-md p-1"
              @click="editKecepatan(data.id)"
            >
              <IconEdit size="w-3.5 h-3.5" color="fill-white" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <EditBatasKecepatan :id="4" />
  </div>
  <LoadingLottie v-if="speed.loading" />
  <div class="flex justify-center" v-if="speed.rows?.length == 0">
    <NoData size="w-full md:w-5/12" />
  </div>
  <AlertErrorComponent
    v-if="speed.error"
    :message="speed.error.response.data.message"
  />
</template>

<script>
import IconEdit from "@/components/icons/IconEdit.vue";
import NewBatasKecepatan from "@/components/NewBatasKecepatan.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import EditBatasKecepatan from "@/components/EditBatasKecepatan.vue";
import { useModalStore, useSpeedLimitStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

export default {
  components: {
    IconEdit,
    NewBatasKecepatan,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
    EditBatasKecepatan,
  },
  setup() {
    const modal = useModalStore();
    const speedStore = useSpeedLimitStore();
    const { speed } = storeToRefs(speedStore);

    onMounted(async () => {
      await speedStore.getAll({});
    });

    const editKecepatan = async (id) => {
      await speedStore.speedById(id);
      modal.showModal(4);
    };

    return {
      modal,
      speed,
      length,
      editKecepatan,
    };
  },
};
</script>

<style></style>
