<template>
  <ModalFormGenerator :id="id" title="Edit Form Field" @close="close">
    <Form @submit="acceptModal" :validation-schema="schema" v-slot="{ errors }">
      <div class="p-6 space-y-3">
        <InputWithLabel
          label="Name *"
          type="text"
          name="name"
          :value="formById.name"
          :error="errors.name"
        />
        <SelectSearchWithLabel
          label="Type"
          :data="types"
          v-model:formControlName="formById.type"
          name="types"
          optionsKey="value"
          optionsValue="label"
          placeholder="Please Select Type Field"
        />
        <div
          v-if="
            formById.type == 'radio' ||
            formById.type == 'select' ||
            formById.type == 'checkbox'
          "
        >
          <p class="text-sm font-medium text-gray-900">Options</p>
          <div
            class="flex space-x-3 space-y-1"
            v-for="(option, index) in formById.options"
            :key="index"
          >
            <input
              type="text"
              v-model="option.value"
              placeholder="option value"
              class="border-0 border-b-2 border-b-gray-200 focus:ring-0"
            />
            <div class="mt-3" @click="addMore">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </div>
            <div class="mt-3" @click="remove(index)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </div>
          </div>
        </div>
        <InputWithLabel
          label="Validation"
          type="text"
          name="validation"
          :value="formById.validation"
        />
      </div>
      <div
        class="flex justify-end items-center p-6 space-x-2 rounded-b border-t border-gray-200"
      >
        <ButtonComponent
          vClass="bg-greenSide hover:bg-greenPrimary"
          :disabled="errors.name || formById.type == '' ? true : false"
          >Save</ButtonComponent
        >
        <ButtonComponent vClass="btn-white" @click="close"
          >Close</ButtonComponent
        >
      </div>
    </Form>
  </ModalFormGenerator>
</template>

<script>
import ModalFormGenerator from "@/components/ModalFomGenerator.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import SelectSearchWithLabel from "@/components/SelectSearchWithLabel.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { reactive, ref } from "vue";
import { useFormStore, useModalStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  components: {
    ModalFormGenerator,
    InputWithLabel,
    SelectSearchWithLabel,
    ButtonComponent,
    Form,
  },
  props: {
    id: Number,
  },
  setup() {
    const formStore = useFormStore();
    const { formById } = storeToRefs(formStore);
    const modal = useModalStore();

    const schema = Yup.object().shape({
      name: Yup.string().required("Name is required"),
    });

    const types = reactive([
      { label: "Text", value: "text" },
      { label: "Number", value: "number" },
      { label: "Password", value: "password" },
      { label: "Textarea", value: "textarea" },
      { label: "Date", value: "date" },
      { label: "Time", value: "time" },
      { label: "Radio", value: "radio" },
      { label: "Select", value: "select" },
      { label: "Checkbox", value: "checkbox" },
    ]);

    const properties = ref([]);

    const acceptModal = async (value) => {
      const { name, validation } = value;
      let data = formStore.formById.options;

      const formattedData = (data) =>
        data.map((obj) => ({
          label: obj.value,
          ...obj,
        }));

      const objIndex = formStore.formProperties.findIndex(
        (obj) => obj.id == formStore.formById.id
      );
      properties.value = {
        id: objIndex,
        name: name,
        type: formStore.formById.type,
        validation: validation,
      };
      if (
        formStore.formById.type == "radio" ||
        formStore.formById.type == "select" ||
        formStore.formById.type == "checkbox"
      ) {
        properties.value = {
          ...properties.value,
          options: formattedData(data),
        };
      }

      formStore.formProperties[objIndex] = properties.value;
      localStorage.removeItem("properties");
      modal.closeModal();
    };

    const addMore = () => {
      formStore.formById.options.push({ value: "" });
    };

    const remove = (index) => {
      formStore.formById.options.splice(index, 1);
    };

    const close = () => {
      const old = localStorage.getItem("properties");
      formStore.formById.type = JSON.parse(old).type;
      formStore.formById.options = JSON.parse(old).options;
      modal.closeModal();
      localStorage.removeItem("properties");
    };

    return {
      acceptModal,
      addMore,
      remove,
      types,
      schema,
      formById,
      close,
    };
  },
};
</script>

<style></style>
