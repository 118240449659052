import { defineStore } from "pinia";
import debounce from "debounce-promise";
import { getData } from "@/utils/fetch";
import axios from "../config";
import router from "../router";
import { useErrorStore } from "./error.store";
import { useAlertStore } from "./alert.store";

let debouncedFetch = debounce(getData, 500);

export const useFormStore = defineStore({
  id: "form",
  state: () => ({
    formProperties: [],
    formById: {},
    select: "",
    result: {},
    forms: [],
    formName: "",
  }),
  getters: {
    getData(state) {
      return state;
    },
  },
  actions: {
    async getAll() {
      this.result = { loading: true };

      try {
        const data = await debouncedFetch("form_generator");
        this.result = { ...data.data };
        if (this.result.rows?.length) {
          this.select = this.result.rows[0].form_id;
        }
      } catch (error) {
        this.result = { error };
      }
    },
    async getForm() {
      this.forms = { loading: true };

      try {
        const data = await debouncedFetch(`form_generator/${this.select}`);
        this.forms = data.data;
      } catch (error) {
        this.forms = { error };
      }
    },
    async saveForm() {
      const alertStore = useAlertStore();
      try {
        if (this.formName == "") {
          alertStore.error("Form Name is required");
        } else {
          await axios.post("form_generator", {
            form_name: this.formName,
            form_properties: this.formProperties,
          });
          this.formName = "";
          this.formProperties = [];
          router.push("/form_generator");
        }
      } catch (error) {
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.retry = false;
        errorMessage.message = error.response.data.message;
        console.log(error);
      }
    },
  },
});
