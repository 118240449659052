<template>
  <ModalComponent :id="id" title="Add Defined Message">
    <template #body>
      <Form
        @submit="acceptModal"
        :validation-schema="schema"
        v-slot="{ errors }"
      >
        <div class="p-6 space-y-3">
          <InputWithLabel
            label="Text Defined *"
            type="text"
            name="text_defined"
            :error="errors.text_defined"
          />
        </div>
        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary"
              >Save</ButtonComponent
            >
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import ModalComponent from "@/components/ModalComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useDefinedMessage } from "@/store";

export default {
  props: {
    id: Number,
  },
  components: {
    Form,
    ModalComponent,
    InputWithLabel,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const definedStore = useDefinedMessage();

    const schema = Yup.object().shape({
      text_defined: Yup.string().required("Text defined is required"),
    });

    const acceptModal = async (value) => {
      const { text_defined } = value;

      await definedStore.addMessage(text_defined);
    };

    return {
      schema,
      acceptModal,
    };
  },
};
</script>

<style></style>
