<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 20 20"
    id="mobile"
    :class="size"
    :fill="color"
  >
    <path
      d="M14.004 0H5.996A1.996 1.996 0 0 0 4 1.996v16.007C4 19.106 4.894 20 5.996 20h8.007A1.997 1.997 0 0 0 16 18.004V1.996A1.996 1.996 0 0 0 14.004 0zM10 19c-.69 0-1.25-.447-1.25-1s.56-1 1.25-1 1.25.447 1.25 1-.56 1-1.25 1zm4-3H6V2h8v14z"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "w-7 h-7",
    },
    color: {
      type: String,
      default: "#062346",
    },
  },
};
</script>

<style></style>
