<template>
  <div class="main">
    <HeaderTitle title="Defined Message">
      <IconDefinedMessage color="#14444f" size="w-10 h-10" />
    </HeaderTitle>
    <MainContent>
      <div class="flex justify-between">
        <SearchInput id="defined_message" @input="search" />
        <ButtonComponent
          vClass="text-xs text-greenPrimary bg-gray-300 hover:bg-[#c6cbd2] focus:ring-0 px-3 py-2"
          @click="modal.showModal(1)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <span>New Message</span>
        </ButtonComponent>
        <AddDefinedMessage :id="1" />
      </div>
      <div class="pt-5 pb-5">
        <div
          class="overflow-x-auto relative shadow-md rounded-lg"
          v-if="result.rows?.length"
        >
          <table class="w-full text-sm text-left text-gray-500">
            <tbody>
              <tr
                class="bg-white border-b"
                v-for="data in result.rows"
                :key="data.text_id"
              >
                <th scope="row" class="py-3 px-6 font-medium text-gray-900">
                  <div class="flex items-center space-x-3">
                    <div class="bg-greenSide rounded-full p-2">
                      <IconText />
                    </div>
                    <p class="text-md">{{ data.text_defined }}</p>
                  </div>
                </th>
                <td class="py-3 px-5">{{ data.text_id }}</td>
                <td class="py-3 px-10">
                  <div
                    class="flex justify-end space-x-2.5 [&_svg]:w-3 [&_svg]:h-3"
                  >
                    <button
                      class="bg-amber hover:bg-amber500 rounded-full cursor-pointer p-1"
                      @click="onEdit(data.text_id)"
                    >
                      <IconEdit color="fill-white" />
                    </button>
                    <button
                      class="bg-red-700 hover:bg-red-800 rounded-full cursor-pointer p-1"
                      @click="onDelete(data.text_id)"
                    >
                      <IconTrash />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginationComponent
            v-model:length="length"
            v-model:size="size"
            v-model:page="page"
            @onSelectChange="changePage"
          />
        </div>

        <LoadingLottie v-if="result.loading" />
        <div class="flex justify-center" v-if="result.rows?.length == 0">
          <NoData size="w-5/12" />
        </div>
        <AlertErrorComponent
          v-if="result.error"
          :message="result.error.message"
        />
      </div>
      <EditDefinedMessage :id="2" />
      <DeleteConfirmation :id="3" @onDeleteData="deleteMessage" />
    </MainContent>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import IconDefinedMessage from "@/components/icons/IconDefinedMessage.vue";
import MainContent from "@/components/MainContent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import AddDefinedMessage from "./AddDefinedMessage.vue";
import EditDefinedMessage from "./EditDefinedMessage.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import SearchInput from "@/components/SearchInput.vue";
import IconText from "@/components/icons/IconText.vue";
import { useDefinedMessage, useModalStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

export default {
  components: {
    HeaderTitle,
    IconDefinedMessage,
    MainContent,
    ButtonComponent,
    AddDefinedMessage,
    EditDefinedMessage,
    IconEdit,
    IconTrash,
    PaginationComponent,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
    DeleteConfirmation,
    SearchInput,
    IconText,
  },
  setup() {
    const modal = useModalStore();
    const definedStore = useDefinedMessage();
    const { result, length, page } = storeToRefs(definedStore);

    const offset = ref(0);
    const size = ref(10);

    const message = ref("");
    const textId = ref("");

    onMounted(async () => {
      await definedStore.getAll({ offset: offset.value });
      length.value = definedStore.result.count;
    });

    const onEdit = async (id) => {
      modal.showModal(2);
      await definedStore.getById(id);
    };

    const onDelete = async (id) => {
      modal.showModal(3);
      textId.value = id;
    };

    const changePage = async (value) => {
      definedStore.page = value;
      offset.value = (definedStore.page - 1) * size.value;
      let dataParams = { offset: offset.value };
      if (definedStore.q != "") {
        dataParams = {
          ...dataParams,
          q: definedStore.q,
        };
      }
      await definedStore.getAll(dataParams);
    };

    const deleteMessage = async () => {
      await definedStore.deleteDefinedMessage(textId.value);
    };

    const search = async (value) => {
      offset.value = 0;
      definedStore.page = 1;
      definedStore.q = value.target.value;
      await definedStore.getAll({
        offset: offset.value,
        q: value.target.value,
      });
      definedStore.q = value.target.value;
      definedStore.length = definedStore.result.count;
    };

    return {
      modal,
      result,
      message,
      length,
      size,
      page,
      onEdit,
      onDelete,
      changePage,
      deleteMessage,
      search,
    };
  },
};
</script>

<style></style>
