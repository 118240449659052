<template>
  <div>
    <div class="relative z-10" v-if="open">
      <SidebarContact />
    </div>
    <div
      class="w-full h-[420px] flex flex-col bg-gray-100 rounded-l-lg relative z-0 transition ease-in-out duration-300"
      v-if="!open"
    >
      <div class="flex justify-between pt-3 pb-1 px-2.5 items-center">
        <SearchInput addClass="shadow-md rounded-3xl h-9" @input="search" />
        <span
          class="inline-flex items-center justify-center me-2 px-1 py-1 bg-amber hover:bg-amber500 rounded-full cursor-pointer shadow-md shadow-gray-400"
          @click="openContact"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512.000000 512.000000"
            class="w-6 h-6"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#ffffff"
              stroke="none"
            >
              <path
                d="M764 4255 c-82 -18 -137 -47 -201 -107 -61 -59 -94 -112 -117 -187 -14 -49 -16 -201 -16 -1502 0 -1392 1 -1450 19 -1508 50 -161 156 -247 345 -282 78 -15 184 -1 261 35 43 19 286 208 653 508 l83 68 1262 0 c814 0 1278 4 1306 11 123 28 235 119 293 236 l33 68 3 1154 c3 1261 5 1206 -54 1307 -31 53 -119 136 -173 164 -101 51 -47 50 -1906 49 -1400 0 -1742 -3 -1791 -14z m3506 -1480 l0 -1065 -1247 0 c-1029 0 -1260 -3 -1314 -14 -127 -27 -176 -59 -518 -335 -178 -144 -327 -264 -332 -267 -5 -3 -9 570 -9 1370 l0 1376 1710 0 1710 0 0 -1065z"
              />
              <path
                d="M2502 3621 c-54 -14 -108 -56 -131 -103 -20 -41 -21 -60 -21 -285 l0 -241 -248 -4 -249 -3 -48 -30 c-65 -40 -95 -97 -95 -179 1 -104 53 -182 138 -206 20 -5 140 -10 270 -10 l232 0 0 -242 c0 -214 3 -248 19 -283 69 -154 313 -154 382 0 16 35 19 69 19 283 l0 242 233 0 c129 0 249 5 269 10 85 24 137 102 138 206 0 82 -30 139 -95 179 l-48 30 -249 3 -248 4 0 242 c0 235 -1 244 -23 287 -41 78 -152 123 -245 100z"
              />
            </g>
          </svg>
        </span>
      </div>

      <div v-if="!loading && lastHistory.length != 0">
        <div
          class="overflow-y-auto pt-4 px-3 flex flex-col space-y-3"
          v-for="(chat, index) in lastHistory"
          :key="index"
        >
          <div class="border-b border-gray-400 py-1 flex flex-col space-y-1">
            <div class="flex justify-between">
              <p
                class="text-sm text-gray-900 cursor-pointer font-medium"
                @click="openChat(chat)"
              >
                {{ chat.name }}
              </p>
              <p class="text-xs text-gray-500">{{ date(chat.createdAt) }}</p>
            </div>
            <div class="flex justify-between items-center">
              <p
                class="flex-1 text-xs text-gray-500"
                :class="readState[chat.mmsi] ? 'font-bold' : ''"
              >
                {{ chat.message }}
              </p>
              <div v-for="count in unreadChat" :key="count.mmsi">
                <span
                  class="bg-red-500 text-white text-xs font-medium me-2 px-1.5 py-0.5 rounded-full"
                  v-if="count.mmsi == chat.mmsi && count.total != 0"
                >
                  {{ count.total }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="lastHistory.length == 0 && !loading"
        class="pt-5 flex justify-center text-sm font-semibold text-gray-500"
      >
        <p>No chat history</p>
      </div>

      <div v-if="loading" class="pt-5">
        <div
          class="py-3 px-3 flex flex-col space-y-2"
          v-for="index in 4"
          :key="index"
        >
          <SkeletonComponent height="h-6" width="w-full" />
          <SkeletonComponent height="h-4" width="w-full" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from "./SearchInput.vue";
import SidebarContact from "./SidebarContact.vue";
import SkeletonComponent from "./SkeletonComponent.vue";
import { useChatVesselStore, useSidebarStore } from "@/store";
import moment from "moment";
import { storeToRefs } from "pinia";
import { onMounted, ref, watchEffect } from "vue";
import axios from "../config";

export default {
  components: {
    SearchInput,
    SidebarContact,
    SkeletonComponent,
  },
  setup() {
    const sidebar = useSidebarStore();
    const { open } = storeToRefs(sidebar);
    const chatVesselStore = useChatVesselStore();
    const { lastChat, unreadChat, readState } = storeToRefs(chatVesselStore);

    const loading = ref(false);
    const searchData = ref([]);
    const searchKey = ref("");
    const lastHistory = ref([]);

    onMounted(async () => {
      loading.value = true;
      await chatVesselStore.getShip();
      await chatVesselStore.getChatHistory();
      await chatVesselStore.getLastChatHistory();
      await chatVesselStore.countUnreadChat();
      loading.value = false;
    });

    const date = (date) => {
      const currentDate = moment(new Date()).format("DD-MM-YYYY");
      const dataDate = moment(date).format("DD-MM-YYYY");
      if (dataDate == currentDate) {
        return moment(date).startOf("hour").fromNow();
      } else {
        return moment(date).format("DD/MM/YYYY");
      }
    };

    const openContact = () => {
      sidebar.$patch((state) => {
        if (!state.open) {
          state.open = !state.open;
        }
      });
    };

    const openChat = async (value) => {
      chatVesselStore.id = value.mmsi;
      chatVesselStore.open = true;
      chatVesselStore.byMMSI = value;

      await axios.delete(`unread_vessel_chat/${value.mmsi}`);
      await chatVesselStore.countUnreadChat();
    };

    const search = (value) => {
      searchKey.value = value.target.value;
      searchData.value = chatVesselStore.filterItems(
        value.target.value,
        lastChat.value
      );
    };

    watchEffect(() => {
      if (searchKey.value == "") {
        lastHistory.value = lastChat.value;
      } else {
        lastHistory.value = searchData.value;
      }
    });

    return {
      open,
      unreadChat,
      readState,
      loading,
      lastHistory,
      date,
      openContact,
      openChat,
      search,
    };
  },
};
</script>

<style></style>
