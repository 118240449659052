<template>
  <card-component
    :addClass="[
      { 'hover:bg-gray-200': !selected },
      'cursor-pointer border-gray-300',
    ]"
    :selected="selected"
  >
    <div class="flex justify-between items-center">
      <div class="flex items-center space-x-2 md:space-x-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#14444f"
          class="w-9 h-9"
        >
          <path
            fill-rule="evenodd"
            d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
            clip-rule="evenodd"
          />
        </svg>

        <h1 class="text-sm md:text-lg font-bold text-greenPrimary">
          {{ message }}
        </h1>
      </div>
      <h1 class="text-sm md:text-lg font-bold text-greenPrimary">
        {{ size }}
      </h1>
    </div>

    <div
      class="flex flex-col space-y-2 md:flex-row md:space-y-0 justify-between pt-6 p-2 md:pl-1"
    >
      <div class="inline-flex items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          :stroke="'#143f4f'"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
          />
        </svg>

        <h3 class="text-sm md:text-base text-gray-600">
          {{ sender }}
        </h3>
      </div>

      <div class="inline-flex items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          :stroke="'#e69400'"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
          />
        </svg>
        <h3 class="text-sm md:text-base text-gray-600">
          {{ destination }}
        </h3>
      </div>
      <div class="inline-flex items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          :stroke="'#073265'"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>
        <h3 class="text-sm md:text-base text-gray-600">
          {{ regionName }}
        </h3>
      </div>
      <div class="inline-flex items-center gap-2">
        <IconDatePlus size="w-6 h-6" />
        <h3 class="text-sm md:text-base text-gray-600">
          {{ messageSent }}
        </h3>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from "./CardComponent.vue";
import IconDatePlus from "./icons/IconDatePlus.vue";

export default {
  components: {
    CardComponent,
    IconDatePlus,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    message: String,
    size: Number,
    sender: String,
    destination: String,
    regionName: String,
    messageSent: String,
  },
};
</script>
