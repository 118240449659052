<template>
  <div class="flex flex-row">
    <div class="basis-5/12 border-r-2 border-gray-300">
      <ChatVesselContact />
    </div>
    <div class="basis-full">
      <RoomChat :id="chatVesselStore.id" />
    </div>
  </div>
</template>

<script>
import ChatVesselContact from "../../components/ChatVesselContact.vue";
import RoomChat from "./RoomChat.vue";
import { watchEffect } from "vue";
import { useChatVesselStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  components: {
    ChatVesselContact,
    RoomChat,
  },
  setup() {
    const chatVesselStore = useChatVesselStore();
    const { noShip } = storeToRefs(chatVesselStore);

    watchEffect(() => {
      window.addEventListener("keydown", (event) => {
        if (event.key == "Escape") {
          chatVesselStore.$patch((state) => {
            state.id = 0;
            state.open = false;
          });
        }
      });
    });

    return {
      chatVesselStore,
      noShip,
    };
  },
};
</script>

<style></style>
