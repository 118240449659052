<template>
  <ModalComponent :id="id" title="Add New User">
    <template #body>
      <Form
        @submit="acceptModal"
        :validation-schema="schema"
        v-slot="{ errors }"
      >
        <div class="p-6 space-y-3">
          <InputWithLabel
            label="First Name *"
            type="text"
            name="firstName"
            :error="errors.firstName"
          />
          <InputWithLabel label="Last Name" type="text" name="lastName" />
          <InputWithLabel
            label="Email *"
            type="email"
            name="email"
            :error="errors.email"
          />
          <InputWithLabel
            label="Password *"
            type="password"
            name="password"
            :error="errors.password"
          />
          <SelectSearchWithLabel
            label="Role *"
            labelColor="text-black"
            placeholder="Select User Role"
            :data="roleUser"
            :optionsValue="'desc'"
            :optionsKey="'name'"
            v-model:formControlName="role"
          />
        </div>
        <ModalFooter colorClose="btn-darkGray">
          <template #accept>
            <ButtonComponent vClass="bg-darkGreen hover:bg-greenPrimary"
              >Save</ButtonComponent
            >
          </template>
        </ModalFooter>
      </Form>
    </template>
  </ModalComponent>
</template>

<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import ModalComponent from "@/components/ModalComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import SelectSearchWithLabel from "@/components/SelectSearchWithLabel.vue";
import ModalFooter from "@/components/ModalFooter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { ref } from "vue";
import { useUserStore } from "@/store";

export default {
  props: {
    id: Number,
  },
  components: {
    Form,
    ModalComponent,
    InputWithLabel,
    SelectSearchWithLabel,
    ModalFooter,
    ButtonComponent,
  },
  setup() {
    const userStore = useUserStore();

    const roleUser = [
      { name: "user", desc: "User" },
      { name: "admin", desc: "Admin" },
    ];

    const role = ref("");

    const schema = Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-zA-Z]/, "Password must contain at least 1 letter")
        .matches(/\d/, "Password must contain at least 1 number"),
    });

    const acceptModal = async (value) => {
      const { firstName, lastName, email, password } = value;

      await userStore.addUser(firstName, lastName, email, password, role.value);
      role.value = "";
    };

    return {
      roleUser,
      role,
      schema,
      acceptModal,
    };
  },
};
</script>

<style></style>
