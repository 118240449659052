<template>
  <div class="bg-gray-100 flex-1 h-16">
    <div class="px-3 py-2 flex items-center" v-for="index in 7" :key="index">
      <div class="rounded-full bg-gray-400 p-1 w-8 h-8"></div>
      <div class="flex-1 py-3 h-12 border-b border-gray-200 px-3">
        <div class="bg-gray-400 h-2 w-11/12 mt-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
