<template>
  <div class="main">
    <HeaderTitle title="User">
      <IconUserGroup color="#14444f" size="w-11 h-11" />
    </HeaderTitle>
    <MainContent>
      <div
        class="pt-5 flex flex-col md:flex-row space-y-3 md:space-y-0 justify-between"
      >
        <SearchInput @input="search" id="user" />
        <div class="flex space-x-2">
          <DatePickerNoLabel
            v-model:formControlName="dateFilter"
            @onClickApply="onClickApply"
          />
          <ButtonComponent
            vClass="text-xs text-greenPrimary bg-gray-300 hover:bg-[#c6cbd2] focus:ring-0 px-3 py-2"
            @click="modal.showModal(1)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
              />
            </svg>
            <span class="sr-only md:not-sr-only">New User</span>
          </ButtonComponent>
          <AddUser :id="1" />
        </div>
      </div>
      <div class="pt-3" v-if="date != ''">
        <span
          class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
          >{{ date }}</span
        >
      </div>
      <TableUser />
    </MainContent>
  </div>
  <DetailUser />
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import IconUserGroup from "@/components/icons/IconUserGroup.vue";
import MainContent from "@/components/MainContent.vue";
import SearchInput from "@/components/SearchInput.vue";
import DatePickerNoLabel from "@/components/DatePickerNoLabel.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import AddUser from "./AddUser.vue";
import TableUser from "./TableUser.vue";
import DetailUser from "./DetailUser.vue";
import { useModalStore, useUserStore } from "@/store";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";

export default {
  components: {
    HeaderTitle,
    IconUserGroup,
    MainContent,
    SearchInput,
    DatePickerNoLabel,
    ButtonComponent,
    AddUser,
    TableUser,
    DetailUser,
  },
  setup() {
    const modal = useModalStore();
    const userStore = useUserStore();
    const { dateFilter } = storeToRefs(userStore);

    const offset = ref(0);
    const date = ref("");

    const search = async (value) => {
      offset.value = 0;
      userStore.page = 1;

      let dataParams = { offset: offset.value, q: value.target.value };

      if (userStore.dateFilter != null) {
        if (userStore.dateFilter.length != 0) {
          dataParams = {
            ...dataParams,
            fromDate: moment(userStore.dateFilter[0]).format("YYYY-MM-DD"),
            toDate: moment(userStore.dateFilter[1]).format("YYYY-MM-DD"),
          };
        } else {
          await userStore.getAll(dataParams);
        }
      }
      await userStore.getAll(dataParams);
      userStore.q = value.target.value;
      userStore.length = userStore.users.count;
      userStore.select();
    };

    const onClickApply = async () => {
      offset.value = 0;
      userStore.page = 1;
      let dataParams = {
        offset: offset.value,
      };

      if (userStore.q) {
        dataParams = {
          ...dataParams,
          q: userStore.q,
        };
      }

      if (userStore.dateFilter != null) {
        date.value = `${moment(dateFilter.value[0]).format(
          "DD MMM YYYY"
        )} - ${moment(dateFilter.value[1]).format("DD MMM YYYY")}`;
        dataParams = {
          ...dataParams,
          fromDate: moment(userStore.dateFilter[0]).format("YYYY-MM-DD"),
          toDate: moment(userStore.dateFilter[1]).format("YYYY-MM-DD"),
        };
      } else {
        date.value = "";
        dataParams;
      }

      await userStore.getAll(dataParams);
      userStore.length = userStore.users.count;
      userStore.select();
    };

    return {
      modal,
      dateFilter,
      search,
      onClickApply,
      date,
    };
  },
};
</script>

<style></style>
