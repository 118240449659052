<template>
  <SidebarLeft>
    <template #search>
      <SearchInput @input="search" addClass="shadow-md rounded-3xl h-9" />
    </template>

    <div
      class="px-8 pt-3 h-[350px] max-h-[350px] overflow-y-auto"
      v-if="!loading && contact.length != 0"
    >
      <div class="flex flex-col" v-for="kapal in contact" :key="kapal.mmsi">
        <div class="py-2 border-b text-sm text-gray-500">
          <span class="cursor-pointer" @click="newChat(kapal)">{{
            kapal.name
          }}</span>
        </div>
      </div>
    </div>

    <div
      v-if="contact.length == 0"
      class="pt-5 flex justify-center text-sm font-semibold text-gray-500"
    >
      <p>No data found</p>
    </div>

    <div v-if="loading" class="pt-5">
      <div class="py-3 px-3" v-for="index in 7" :key="index">
        <skeleton-component height="h-6" width="w-full" />
      </div>
    </div>
  </SidebarLeft>
</template>

<script>
import { useChatVesselStore, useSidebarStore } from "@/store";
import SidebarLeft from "./SidebarLeft.vue";
import SearchInput from "./SearchInput.vue";
import SkeletonComponent from "./SkeletonComponent.vue";
import { storeToRefs } from "pinia";
import { ref, watchEffect } from "vue";

export default {
  components: {
    SidebarLeft,
    SearchInput,
    SkeletonComponent,
  },
  setup() {
    const sidebar = useSidebarStore();
    const chatVesselStore = useChatVesselStore();
    const { ship, loading } = storeToRefs(chatVesselStore);

    const contact = ref([]);
    const searchData = ref([]);
    const searchKey = ref("");

    const newChat = (value) => {
      console.log(value);
      chatVesselStore.id = value.mmsi;
      chatVesselStore.open = true;
      chatVesselStore.byMMSI = value;
      sidebar.$reset();
    };

    const search = (value) => {
      searchKey.value = value.target.value;
      searchData.value = chatVesselStore.filterItems(
        value.target.value,
        ship.value
      );
    };

    watchEffect(() => {
      if (searchKey.value == "") {
        contact.value = ship.value;
      } else {
        contact.value = searchData.value;
      }
    });

    return {
      ship,
      contact,
      loading,
      newChat,
      search,
    };
  },
};
</script>

<style></style>
