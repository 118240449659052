<template>
  <div class="main">
    <HeaderTitle title="Modem">
      <IconModem color="#14444f" size="w-11 h-11" />
    </HeaderTitle>
    <MainContent>
      <div
        class="pt-5 flex flex-col md:flex-row space-y-3 md:space-y-0 justify-between"
      >
        <SearchInput @input="search" id="modem" />

        <div class="flex space-x-2">
          <DatePickerNoLabel
            v-model:formControlName="dateFilter"
            @onClickApply="onClickApply"
          />

          <ButtonComponent
            vClass="text-xs text-greenPrimary bg-gray-300 hover:bg-[#c6cbd2] focus:ring-0 px-3 py-2"
            @click="modal.showModal(1)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span class="sr-only md:not-sr-only">Register Modem</span>
          </ButtonComponent>
          <RegisterModem :id="1" />
        </div>
      </div>
      <div class="pt-3" v-if="date != ''">
        <span
          class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
        >
          {{ date }}
        </span>
      </div>
      <TableModem />
    </MainContent>
  </div>
  <DetailModem />
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import IconModem from "@/components/icons/IconModem.vue";
import MainContent from "@/components/MainContent.vue";
import SearchInput from "@/components/SearchInput.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import RegisterModem from "./RegisterModem.vue";
import TableModem from "./TableModem.vue";
import DetailModem from "./DetailModem.vue";
import DatePickerNoLabel from "@/components/DatePickerNoLabel.vue";
import moment from "moment";
import { useModalStore, useModemStore } from "@/store";
import { ref } from "vue";
import { storeToRefs } from "pinia";

export default {
  components: {
    HeaderTitle,
    IconModem,
    MainContent,
    SearchInput,
    ButtonComponent,
    RegisterModem,
    TableModem,
    DetailModem,
    DatePickerNoLabel,
  },
  setup() {
    const modal = useModalStore();
    const modemStore = useModemStore();
    const { dateFilter } = storeToRefs(modemStore);

    const offset = ref(0);
    const date = ref("");

    const search = async (value) => {
      offset.value = 0;
      modemStore.page = 1;

      let dataParams = { offset: offset.value, q: value.target.value };

      if (modemStore.dateFilter != null) {
        if (modemStore.dateFilter.length != 0) {
          dataParams = {
            ...dataParams,
            fromDate: moment(modemStore.dateFilter[0]).format("YYYY-MM-DD"),
            toDate: moment(modemStore.dateFilter[1]).format("YYYY-MM-DD"),
          };
        } else {
          await modemStore.getAllModem(dataParams);
        }
      }

      await modemStore.getAllModem(dataParams);

      modemStore.q = value.target.value;
      modemStore.length = modemStore.listModem.count;
      modemStore.select();
    };

    const onClickApply = async () => {
      offset.value = 0;
      modemStore.page = 1;
      let dataParams = {
        offset: offset.value,
      };

      if (modemStore.q) {
        dataParams = {
          ...dataParams,
          q: modemStore.q,
        };
      }

      if (modemStore.dateFilter != null) {
        date.value = `${moment(dateFilter.value[0]).format(
          "DD MMM YYYY"
        )} - ${moment(dateFilter.value[1]).format("DD MMM YYYY")}`;
        dataParams = {
          ...dataParams,
          fromDate: moment(modemStore.dateFilter[0]).format("YYYY-MM-DD"),
          toDate: moment(modemStore.dateFilter[1]).format("YYYY-MM-DD"),
        };
      } else {
        date.value = "";
        dataParams;
      }

      await modemStore.getAllModem(dataParams);
      modemStore.length = modemStore.listModem.count;
      modemStore.select();
    };

    return {
      modal,
      dateFilter,
      date,
      search,
      onClickApply,
    };
  },
};
</script>

<style></style>
