<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :class="size"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="color"
      stroke="none"
    >
      <path
        d="M2371 4260 c-502 -42 -931 -219 -1233 -509 -321 -308 -476 -663 -495
-1136 -16 -417 100 -777 354 -1095 l60 -76 -24 -88 c-48 -181 -153 -350 -304
-489 -81 -75 -84 -79 -84 -122 0 -35 6 -50 28 -72 l28 -28 162 1 c178 1 327
20 467 61 158 46 339 145 423 231 l38 39 77 -23 c212 -66 438 -98 692 -97 203
0 322 12 505 49 503 105 933 387 1172 769 283 452 321 1087 96 1590 -236 525
-782 896 -1443 979 -160 20 -386 27 -519 16z m277 -927 c14 -10 35 -32 46 -47
20 -26 21 -43 24 -296 l3 -269 269 -3 c253 -3 270 -4 296 -24 53 -39 69 -71
69 -134 0 -63 -16 -95 -69 -134 -26 -20 -43 -21 -296 -24 l-269 -3 -3 -269
c-3 -253 -4 -270 -24 -296 -39 -53 -71 -69 -134 -69 -63 0 -95 16 -134 69 -20
26 -21 43 -24 296 l-3 269 -269 3 c-253 3 -270 4 -296 24 -53 39 -69 71 -69
134 0 63 16 95 69 134 26 20 43 21 297 24 l269 3 0 254 c0 155 4 265 11 282
14 37 47 72 84 89 40 19 119 12 153 -13z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#14444f",
    },
    size: {
      type: String,
      default: "w-10 h-10",
    },
  },
};
</script>

<style></style>
