<template>
  <div
    class="absolute bottom-[180px] right-12 z-10 bg-white px-2 py-1.5 rounded-md"
  >
    <small class="text-sm font-semibold flex justify-center">Vessel</small>
    <table>
      <tr class="text-sm text-gray-900">
        <td class="px-2 py-0.5">
          <img src="../../assets/vessel/high_speed_craft.png" />
        </td>
        <td class="px-2 py-0.5">High speed craft</td>
      </tr>
      <tr class="font-medium text-sm text-gray-900">
        <td class="px-2 py-0.5">
          <img src="../../assets/vessel/cargo.png" />
        </td>
        <td class="px-2 py-0.5">Cargo ship</td>
      </tr>
      <tr class="font-medium text-sm text-gray-900">
        <td class="px-2 py-0.5">
          <img src="../../assets/vessel/passenger.png" />
        </td>
        <td class="px-2 py-0.5">Passenger ship</td>
      </tr>
      <tr class="font-medium text-sm text-gray-900">
        <td class="px-2 py-0.5">
          <img src="../../assets/vessel/fishing.png" />
        </td>
        <td class="px-2 py-0.5">Fishing vessel</td>
      </tr>
      <tr class="font-medium text-sm text-gray-900">
        <td class="px-2 py-0.5">
          <img src="../../assets/vessel/tanker.png" />
        </td>
        <td class="px-2 py-0.5">Tanker</td>
      </tr>
      <tr class="font-medium text-sm text-gray-900">
        <td class="px-2 py-0.5">
          <img src="../../assets/vessel/tug.png" />
        </td>
        <td class="px-2 py-0.5">Tug</td>
      </tr>
      <tr class="font-medium text-sm text-gray-900">
        <td class="px-2 py-0.5">
          <img src="../../assets/vessel/other.png" />
        </td>
        <td class="px-2 py-0.5">Other vessel</td>
      </tr>
      <tr class="font-medium text-sm text-gray-900">
        <td class="px-2 py-0.5">
          <img src="../../assets/vessel/unknown.png" />
        </td>
        <td class="px-2 py-0.5">Unknown vessel</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style></style>
