<template>
  <div class="main">
    <HeaderTitle title="Logbook">
      <IconLogBook size="w-8 h-8" color="#14444f" />
    </HeaderTitle>
    <MainContent>
      <div class="pt-5 relative">
        <div
          class="flex flex-col md:flex-row space-y-2 md:space-y-0 justify-between"
        >
          <SearchInput @input="search" id="logbook" />
          <SelectGroupByMessage
            v-model:formControlName="group"
            @onSelectChange="changeData"
            @onClose="close"
          />

          <DatePickerNoLabel
            v-model:formControlName="dateFilter"
            @onClickApply="onClickApply"
          />
        </div>

        <div class="pt-5" v-if="dateDisplay != ''">
          <span
            class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
            >{{ dateDisplay }}</span
          >
        </div>

        <div class="pt-5 grid grid-cols-1 gap-3" v-if="logbook.rows?.length">
          <CardMessageList
            :selected="selected == logbook.message_id"
            v-for="logbook in logbook.rows"
            :key="logbook"
            :message="JSON.parse(logbook.message_json).Payload.Fields[2].Value"
            :size="logbook.message_size"
            :sender="JSON.parse(logbook.message_json).Payload.Fields[0].Value"
            :destination="
              JSON.parse(logbook.message_json).Payload.Fields[1].Value
            "
            :regionName="JSON.parse(logbook.message_json).RegionName"
            :messageSent="date(JSON.parse(logbook.message_json).MessageUTC)"
            @click="onClick(logbook)"
          />
          <PaginationComponent
            v-model:length="length"
            v-model:page="page"
            v-model:size="size"
            @onSelectChange="changePage"
          />
        </div>
        <div class="pt-5 grid grid-cols-1 gap-3">
          <SkeletonComponent
            v-show="logbook.loading"
            v-for="index in 3"
            :key="index"
            width="w-full"
          />
        </div>
        <div class="flex justify-center" v-if="logbook.rows?.length == 0">
          <NoData size="w-full md:w-5/12" />
        </div>
        <AlertErrorComponent
          v-if="logbook.error"
          :message="logbook.error.response.message"
        />
      </div>
    </MainContent>
  </div>
  <LogbookDetail v-if="!logbook.error" />
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import IconLogBook from "@/components/icons/IconLogBook.vue";
import MainContent from "@/components/MainContent.vue";
import SearchInput from "@/components/SearchInput.vue";
import SelectGroupByMessage from "@/components/SelectGroupByMessage.vue";
import DatePickerNoLabel from "@/components/DatePickerNoLabel.vue";
import CardMessageList from "@/components/CardMessageList.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import SkeletonComponent from "@/components/SkeletonComponent.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import LogbookDetail from "./LogbookDetail.vue";
import { useSidebarStore, useLogbookStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import moment from "moment";

export default {
  components: {
    HeaderTitle,
    IconLogBook,
    MainContent,
    SearchInput,
    SelectGroupByMessage,
    DatePickerNoLabel,
    CardMessageList,
    PaginationComponent,
    SkeletonComponent,
    NoData,
    AlertErrorComponent,
    LogbookDetail,
  },
  setup() {
    const sidebar = useSidebarStore();
    const logbookStore = useLogbookStore();
    const { logbook } = storeToRefs(logbookStore);

    const offset = ref(0);
    const length = ref(0);
    const page = ref(1);
    const size = ref(10);
    const selected = ref("");
    const q = ref("");
    const dateFilter = ref([]);
    const group = ref("message_id");
    const dateDisplay = ref("");

    const select = () => {
      sidebar.$patch((state) => {
        if (logbookStore.logbook?.count != 0) {
          if (screen.width >= 768) {
            state.open = true;
            selected.value = logbookStore.logbook.rows[0].message_id;
          }
          if (state.id != logbookStore.logbook.rows[0].message_id) {
            state.id = logbookStore.logbook.rows[0].message_id;
            state.data = logbookStore.logbook.rows[0];
          }
        } else {
          state.open = false;
        }
      });
    };

    onMounted(async () => {
      await logbookStore.getAll({ offset: offset.value });
      length.value = logbookStore.logbook.count;
      select();
    });

    const changeData = async () => {
      offset.value = 0;
      page.value = 1;
      await logbookStore.getAll({ offset: offset.value, order: group.value });
      length.value = logbookStore.logbook.count;
      select();
    };

    const search = async (value) => {
      offset.value = 0;
      page.value = 1;
      let dataParams = {
        offset: offset.value,
        order: group.value,
        q: value.target.value,
      };

      if (dateFilter.value != null) {
        if (dateFilter.value.length != 0) {
          dataParams = {
            ...dataParams,
            fromDate: moment(dateFilter.value[0]).format("YYYY-MM-DD"),
            toDate: moment(dateFilter.value[1]).format("YYYY-MM-DD"),
          };
        }
      }
      q.value = value.target.value;
      await logbookStore.getAll(dataParams);
      length.value = logbookStore.logbook.count;
      select();
    };

    const onClickApply = async () => {
      offset.value = 0;
      page.value = 1;
      let dataParams = { offset: offset.value, order: group.value };

      if (q.value) {
        dataParams = {
          ...dataParams,
          q: q.value,
        };
      }

      if (dateFilter.value != null) {
        dateDisplay.value = `${moment(dateFilter.value[0]).format(
          "DD MMM YYYY"
        )} - ${moment(dateFilter.value[1]).format("DD MMM YYYY")}`;
        dataParams = {
          ...dataParams,
          fromDate: moment(dateFilter.value[0]).format("YYYY-MM-DD"),
          toDate: moment(dateFilter.value[1]).format("YYYY-MM-DD"),
        };
      } else {
        dateDisplay.value = "";
      }
      await logbookStore.getAll(dataParams);
      length.value = logbookStore.logbook.count;
      select();
    };

    const onClick = (value) => {
      selected.value = value.message_id;
      sidebar.$patch((state) => {
        if (!state.open) {
          state.open = !state.open;
        }

        if (state.id != value.message_id) {
          state.id = value.message_id;
          state.data = value;
        }
      });
    };

    const changePage = async (value) => {
      page.value = value;
      offset.value = (page.value - 1) * size.value;
      let dataParams = { offset: offset.value, order: group.value };

      if (dateFilter.value != null && dateFilter.value.length != 0) {
        dataParams = {
          ...dataParams,
          fromDate: moment(dateFilter.value[0]).format("YYYY-MM-DD"),
          toDate: moment(dateFilter.value[1]).format("YYYY-MM-DD"),
        };
      }
      if (dateFilter.value == null) {
        dataParams;
      }
      if (q.value != "") {
        dataParams = {
          ...dataParams,
          q: q.value,
        };
      }
      await logbookStore.getAll(dataParams);
      select();
    };

    const date = (date) => {
      return moment(date).format("DD MMM YYYY, HH:mm:ss");
    };

    const close = async () => {
      group.value = "message_id";
      changeData();
    };

    return {
      logbook,
      length,
      page,
      size,
      selected,
      dateFilter,
      group,
      dateDisplay,
      changeData,
      search,
      onClickApply,
      onClick,
      changePage,
      date,
      close,
    };
  },
};
</script>

<style></style>
