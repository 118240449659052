<template>
  <div class="main">
    <HeaderTitle title="Manajemen Geofencing" />
    <MainContent>
      <fwb-tabs
        v-model="activeTab"
        variant="underline"
        directive="show"
        class="p-5"
      >
        <fwb-tab name="geofence" title="Area Geofence" class="-ml-5">
          <ManajemenGeofencing />
        </fwb-tab>
        <fwb-tab name="kecepatan" title="Batas Kecepatan" class="-ml-5">
          <BatasKecepatan />
        </fwb-tab>
        <fwb-tab name="alarm" title="Pengaturan Alarm" class="-ml-5">
          <PengaturanAlarm />
        </fwb-tab>
        <fwb-tab name="pesan" title="Pesan" class="-ml-5">
          <PesanKapalView />
        </fwb-tab>
      </fwb-tabs>
    </MainContent>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import MainContent from "@/components/MainContent.vue";
import ManajemenGeofencing from "./ManajemenGeofencing.vue";
import BatasKecepatan from "./BatasKecepatan.vue";
import PengaturanAlarm from "./PengaturanAlarm.vue";
import PesanKapalView from "./PesanKapalView.vue";
import { FwbTabs, FwbTab } from "flowbite-vue";
import { ref } from "vue";

export default {
  components: {
    HeaderTitle,
    MainContent,
    ManajemenGeofencing,
    BatasKecepatan,
    PengaturanAlarm,
    PesanKapalView,
    FwbTabs,
    FwbTab,
  },
  setup() {
    const activeTab = ref("geofence");

    return {
      activeTab,
    };
  },
};
</script>

<style></style>
