<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="size"
    viewBox="0 0 512.000000 512.000000"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="color"
      stroke="none"
    >
      <path
        d="M1430 4904 c-89 -14 -145 -31 -220 -66 -114 -55 -230 -168 -284 -278
-64 -130 -68 -157 -73 -452 l-5 -268 -124 0 c-136 0 -177 -10 -227 -58 -83
-79 -90 -219 -14 -300 49 -53 92 -66 235 -70 l132 -4 0 -319 0 -319 -133 0
c-121 0 -138 -2 -178 -23 -24 -13 -57 -43 -74 -66 -27 -39 -30 -50 -30 -120 0
-66 4 -83 25 -114 14 -21 43 -50 64 -65 38 -26 45 -27 183 -30 l143 -3 0 -319
0 -318 -132 -4 c-143 -4 -186 -17 -235 -70 -76 -81 -69 -221 14 -300 50 -48
91 -58 227 -58 l124 0 5 -267 c5 -296 9 -323 73 -453 51 -103 170 -222 275
-274 154 -76 31 -70 1524 -74 908 -2 1363 1 1410 8 157 24 295 96 395 208 58
65 133 209 149 290 15 75 15 3609 0 3684 -16 81 -91 225 -149 290 -68 77 -182
151 -283 185 l-82 28 -1355 1 c-745 1 -1366 0 -1380 -2z m1416 -1285 c88 -16
162 -56 227 -120 168 -168 168 -430 0 -598 -166 -165 -420 -169 -588 -10 -92
87 -135 185 -135 309 0 123 43 222 133 308 64 60 119 89 201 107 78 17 90 17
162 4z m418 -1279 c230 -37 393 -257 357 -481 -30 -185 -173 -329 -357 -359
-83 -14 -915 -13 -990 1 -88 16 -162 56 -227 120 -168 168 -168 431 0 598 64
63 129 99 213 117 68 15 917 19 1004 4z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: String,
      default: "w-7 h-7",
    },
  },
};
</script>

<style></style>
