<template>
  <div class="mb-6 mt-4">
    <label :for="name" class="block mb-2 text-sm font-medium text-gray-900">{{
      label
    }}</label>
    <label class="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        :name="name"
        v-model="value"
        class="sr-only peer"
      />
      <div
        class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
      ></div>
      <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{
        ket
      }}</span>
    </label>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    modelValue: Boolean,
    name: String,
    label: String,
    ket: String,
  },
  setup(props, { emit }) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      },
    });

    return {
      value,
    };
  },
};
</script>

<style></style>
