import { defineStore } from "pinia";
import { useModalStore } from "./modal.store";
import axios from "../config";
import { useErrorStore } from "./error.store";
import debounce from "debounce-promise";
import { getData } from "@/utils/fetch";
import { reactive } from "vue";

let debouncedFetch = debounce(getData, 500);

export const useSpeedLimitStore = defineStore({
  id: "speedLimit",
  state: () => ({
    speed: {},
    speedId: "",
    dataById: reactive({
      area: "",
      batas_min: "",
      batas_max: "",
      waktu_awal: "",
      waktu_akhir: "",
      startHour: "",
      startMinute: "",
      endHour: "",
      endMinute: "",
    }),
  }),
  getters: {
    getDataSpeed(state) {
      return state.speed.data;
    },
  },
  actions: {
    async addSpeed(area, batasMin, batasMax, waktuAwal, waktuAkhir) {
      const modal = useModalStore();
      try {
        await axios.post("speed_limit", {
          area: area,
          batas_min: batasMin,
          batas_max: batasMax,
          waktu_awal: waktuAwal,
          waktu_akhir: waktuAkhir,
        });
        modal.closeModal();
        await this.getAll({});
      } catch (error) {
        console.log(error);
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },
    async getAll(params) {
      this.speed = { loading: true };

      const dataparams = {
        limit: params?.limit || 999,
        offset: params?.offset || 0,
        order: params?.order || "createdAt",
        ordertype: params?.ordertype || "ASC",
      };

      try {
        const result = await debouncedFetch("speed_limit", dataparams);
        this.speed = { ...result.data };
      } catch (error) {
        console.log(error);
      }
    },
    async speedById(id) {
      if (id) {
        this.speedId = id;
        try {
          const result = await debouncedFetch(`speed_limit/${id}`);
          this.dataById.area = result.data.area;
          this.dataById.batas_min = result.data.batas_min;
          this.dataById.batas_max = result.data.batas_max;
          this.dataById.startHour = result.data.waktu_awal.slice(0, 2);
          this.dataById.startMinute = result.data.waktu_awal.slice(3);
          this.dataById.endHour = result.data.waktu_akhir.slice(0, 2);
          this.dataById.endMinute = result.data.waktu_akhir.slice(3);
        } catch (error) {
          console.log(error);
        }
      }
    },
    async updateSpeed(area, batasMin, batasMax, waktuAwal, waktuAkhir) {
      const modal = useModalStore();
      try {
        await axios.patch(`speed_limit/${this.speedId}`, {
          area: area,
          batas_min: batasMin,
          batas_max: batasMax,
          waktu_awal: waktuAwal,
          waktu_akhir: waktuAkhir,
        });
        modal.closeModal();
        await this.getAll({});
      } catch (error) {
        console.log(error);
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },
  },
});
