<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.000000 512.000000"
    class="w-8 h-8"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#ffffff"
      stroke="none"
    >
      <path
        d="M946 5110 c-237 -38 -442 -140 -611 -305 -151 -147 -246 -312 -302
-524 -22 -81 -26 -120 -27 -246 -1 -167 10 -243 57 -375 78 -224 112 -267 608
-765 l429 -430 411 410 c225 226 436 444 467 485 273 359 295 856 54 1239
-163 259 -410 433 -707 497 -85 19 -298 26 -379 14z m289 -455 c184 -38 361
-176 444 -345 94 -193 94 -387 0 -580 -83 -169 -260 -307 -444 -345 -146 -31
-285 -12 -425 56 -119 59 -230 170 -289 289 -68 140 -87 279 -56 425 38 184
176 361 345 444 139 68 279 87 425 56z"
      />
      <path
        d="M1003 4356 c-103 -34 -176 -98 -222 -196 -22 -46 -26 -69 -26 -140 0
-76 4 -93 33 -152 38 -77 92 -130 171 -167 47 -22 70 -26 141 -26 71 0 94 4
141 26 79 37 133 90 171 167 29 59 33 76 33 152 0 71 -4 94 -26 141 -58 124
-169 199 -302 205 -43 3 -89 -2 -114 -10z"
      />
      <path
        d="M1890 2681 l0 -151 298 0 c233 0 310 -3 356 -15 274 -71 304 -440 47
-559 -44 -20 -67 -21 -441 -27 -453 -6 -473 -9 -656 -99 -557 -271 -716 -978
-330 -1466 148 -188 380 -320 619 -353 52 -7 376 -11 943 -11 l864 0 0 150 0
150 -865 0 c-972 0 -952 -1 -1104 72 -68 32 -101 57 -171 127 -72 71 -95 101
-128 171 -94 196 -95 399 -3 585 90 181 244 306 439 356 67 17 112 19 421 19
265 0 362 4 416 15 269 57 475 310 475 585 0 190 -108 390 -267 496 -31 21
-96 52 -143 68 l-85 31 -342 3 -343 4 0 -151z"
      />
      <path
        d="M4200 1923 c-318 -55 -573 -286 -656 -593 -24 -89 -25 -301 -2 -385
59 -214 96 -264 466 -638 l306 -308 314 313 c340 340 389 400 441 543 87 242
58 499 -81 712 -150 229 -404 366 -675 362 -48 -1 -99 -4 -113 -6z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
