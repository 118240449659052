<template>
  <div class="py-5 pb-5">
    <div
      class="overflow-x-auto relative shadow-md rounded-lg"
      v-if="reports.rows?.length"
    >
      <table class="w-full text-sm text-left text-gray-500">
        <tbody>
          <tr
            class="bg-white border-b"
            v-for="report in reports.rows"
            :key="report.mmsi"
          >
            <th scope="row" class="py-3 px-6 font-medium text-gray-900">
              <div class="flex items-center space-x-3">
                <div class="bg-greenSide rounded-full p-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512.000000 512.000000"
                    class="w-6 h-6"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#ffffff"
                      stroke="none"
                    >
                      <path
                        d="M930 3940 l0 -270 145 0 145 0 0 270 0 270 -145 0 -145 0 0 -270z"
                      />
                      <path
                        d="M590 3365 l0 -155 490 0 490 0 0 155 0 155 -490 0 -490 0 0 -155z"
                      />
                      <path
                        d="M590 2720 l0 -330 490 0 490 0 0 330 0 330 -490 0 -490 0 0 -330z"
                      />
                      <path
                        d="M1737 2843 c-4 -3 -7 -107 -7 -230 l0 -223 1405 0 1405 0 0 230 0
230 -1398 0 c-769 0 -1402 -3 -1405 -7z"
                      />
                      <path
                        d="M3 2208 c3 -13 36 -231 73 -485 l68 -463 2161 0 2161 0 322 479 c177
264 322 482 322 485 0 3 -1150 6 -2556 6 l-2556 0 5 -22z"
                      />
                      <path
                        d="M174 1063 c3 -21 9 -62 12 -90 l7 -53 2022 0 2021 0 61 90 61 90
-2095 0 -2096 0 7 -37z"
                      />
                    </g>
                  </svg>
                </div>
                <p class="text-md whitespace-nowrap">{{ report.name }}</p>
              </div>
            </th>

            <td class="py-3 px-3">{{ report.latitude }}</td>
            <td class="py-3 px-3">{{ report.longitude }}</td>
            <td class="py-3 px-3">
              {{ converLatLng([report.latitude, report.longitude])[0] }}
            </td>
            <td class="py-3 px-3">
              {{ converLatLng([report.latitude, report.longitude])[1] }}
            </td>
            <td class="py-3 px-3">{{ report.area_name }}</td>
            <td class="py-3 px-3 whitespace-nowrap">
              {{ date(report.entry_time) }}
            </td>
            <td
              class="py-3 px-6 whitespace-nowrap"
              :class="report.exit_time == null ? 'text-center' : ''"
            >
              {{ report.exit_time == null ? "-" : date(report.exit_time) }}
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent
        v-model:length="length"
        v-model:size="size"
        v-model:page="page"
        @onSelectChange="changePage"
      />
    </div>
    <LoadingLottie v-if="reports.loading" />
    <div class="flex justify-center" v-if="reports.rows?.length == 0">
      <NoData size="w-full lg:w-5/12" />
    </div>
    <AlertErrorComponent
      v-if="reports.error"
      :message="ifExist.error.response.data.message"
    />
  </div>
</template>

<script>
import PaginationComponent from "@/components/PaginationComponent.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import { useVesselReportStore } from "@/store";
import { onMounted, ref } from "vue";
import proj4 from "proj4";
import moment from "moment";
import { storeToRefs } from "pinia";

export default {
  components: {
    PaginationComponent,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
  },
  setup() {
    const vesselReportStore = useVesselReportStore();
    const { reports, page, length } = storeToRefs(vesselReportStore);

    const size = ref(10);
    const offset = ref(0);

    onMounted(async () => {
      vesselReportStore.page = 1;
      await vesselReportStore.getReport({
        offset: offset.value,
        fromDate: moment(vesselReportStore.dateFilter[0]).format("YYYY-MM-DD"),
        toDate: moment(vesselReportStore.dateFilter[1]).format("YYYY-MM-DD"),
      });
      vesselReportStore.length = vesselReportStore.reports.count;
    });

    var gws84 =
      "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=WGS84 +units=m +no_defs";

    const converLatLng = (latLng) => {
      return proj4(gws84, latLng);
    };

    const date = (date) => {
      return moment(date).format("DD-MM-YYYY, HH:mm:ss");
    };

    const changePage = async (value) => {
      vesselReportStore.page = value;
      offset.value = (vesselReportStore.page - 1) * size.value;
      let dataParams = { offset: offset.value };

      if (
        vesselReportStore.dateFilter != null &&
        vesselReportStore.dateFilter.length != 0
      ) {
        dataParams = {
          ...dataParams,
          fromDate: moment(vesselReportStore.dateFilter[0]).format(
            "YYYY-MM-DD"
          ),
          toDate: moment(vesselReportStore.dateFilter[1]).format("YYYY-MM-DD"),
        };
      }
      await vesselReportStore.getReport(dataParams);
    };

    return {
      reports,
      page,
      length,
      size,
      converLatLng,
      date,
      changePage,
    };
  },
};
</script>

<style></style>
