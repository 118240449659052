import { getData } from "@/utils/fetch";
import debounce from "debounce-promise";
import { defineStore } from "pinia";
import { reactive } from "vue";

let debouncedFetch = debounce(getData, 500);

export const useRawOgwsStore = defineStore({
  id: "rawOgws",
  state: () => ({
    ogwsData: {},
    filter: reactive({
      date: [],
    }),
  }),
  getters: {
    getDataOgws(state) {
      return state.ogwsData.data;
    },
  },
  actions: {
    async getAll(params) {
      this.ogwsData = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        fromDate: params?.fromDate,
        toDate: params?.toDate,
        order: params?.order || "message_id",
        ordertype: params?.ordertype || "ASC",
        q: params?.q,
      };

      try {
        const data = await debouncedFetch("raw_ogws", dataparams);
        this.ogwsData = { ...data.data };
      } catch (error) {
        this.ogwsData = { error };
      }
    },
  },
});
