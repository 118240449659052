<template>
  <div class="flex items-center" :class="[addClass]">
    <div>
      <slot />
    </div>
    <div class="pl-5 w-full">
      <div class="flex justify-between">
        <h1 class="font-bold" :class="[titleColor, titleSize]">
          {{ title }}
        </h1>
      </div>

      <div class="flex justify-between">
        <div>
          <p :class="[subtitleColor, subtitleSize]">
            {{ subtitle }}
          </p>
        </div>

        <div>
          <p :class="[subtitleColor, subtitleSize]">
            {{ subtitle2 }}
          </p>
        </div>

        <div>
          <p :class="[subtitleColor, subtitleSize]">
            {{ subtitle3 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: [String, Number],
    subtitle: [String, Number],
    subtitle2: [String, Number],
    subtitle3: [String, Number],
    titleColor: {
      default: "text-primary",
      type: String,
    },
    subtitleColor: {
      default: "text-gray-400",
      type: String,
    },
    titleSize: {
      default: "text-1xl",
      type: String,
    },
    subtitleSize: {
      default: "text-xs",
      type: String,
    },
    addClass: String,
  },
};
</script>
