<template>
  <SidebarRight>
    <div v-if="!result.loading">
      <div class="px-8">
        <div class="flex items-center">
          <TitleSubIconGap
            :title="data.username"
            titleColor="text-white"
            :subtitle="data.first_name"
            :subtitle2="data.last_name"
            subtitleColor="text-white"
          >
            <IconUser size="w-10 h-10" />
          </TitleSubIconGap>
        </div>

        <TittleBetween
          title="Basic Info"
          addClass="pt-8 pb-5 flex items-center"
        >
          <IconTrash
            class="cursor-pointer"
            color="white"
            size="w-5 h-5"
            @click="onDelete(data.chat_id)"
          />
        </TittleBetween>
      </div>
      <div class="flex flex-col px-8 space-y-5 [&_svg]:w-6 [&_svg]:h-6">
        <TextIconGroup
          :text="data.chat_id"
          :textWhite="true"
          textSize="text-md"
        >
          <IconMessageId color="white" />
        </TextIconGroup>

        <TextIconGroup
          :text="data.parent_id != null ? data.parent_id : '0'"
          :textWhite="true"
          textSize="text-md"
        >
          <IconId />
        </TextIconGroup>

        <TextIconGroup
          :text="data.status != null ? data.status : '0'"
          :textWhite="true"
          textSize="text-md"
        >
          <IconCheck />
        </TextIconGroup>

        <TextIconGroup
          :text="date(data.createdAt)"
          :textWhite="true"
          textSize="text-md"
        >
          <IconDatePlus />
        </TextIconGroup>

        <TextIconGroup
          :text="date(data.updatedAt)"
          :textWhite="true"
          textSize="text-md"
        >
          <IconDate />
        </TextIconGroup>
      </div>
    </div>

    <SkeletonDetailComponent v-if="result.loading" />
  </SidebarRight>

  <DeleteConfirmation :chatId="selectChatId" @onDeleteData="delTeleUSer" />
</template>

<script>
import SidebarRight from "@/components/SidebarRight.vue";
import TextIconGroup from "@/components/TextIconGroup.vue";
import TittleBetween from "@/components/TittleBetween.vue";
import TitleSubIconGap from "@/components/TitleSubIconGap.vue";
import IconUser from "@/components/icons/IconUser.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconId from "@/components/icons/IconId.vue";
import IconDate from "@/components/icons/IconDate.vue";
import IconMessageId from "@/components/icons/IconMessageId.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import IconDatePlus from "@/components/icons/IconDatePlus.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import { useModalStore, useSidebarStore, useTeleUserStore } from "@/store";
import { computed, onMounted, ref } from "vue";
import moment from "moment";
import { storeToRefs } from "pinia";
import SkeletonDetailComponent from "@/components/SkeletonDetailComponent.vue";

export default {
  components: {
    SidebarRight,
    IconUser,
    IconTrash,
    IconId,
    TextIconGroup,
    IconDate,
    IconMessageId,
    IconCheck,
    TittleBetween,
    TitleSubIconGap,
    IconDatePlus,
    DeleteConfirmation,
    SkeletonDetailComponent,
  },
  props: {
    chatId: Number,
  },
  setup() {
    const modal = useModalStore();
    const teleUserStore = useTeleUserStore();
    const sidebar = useSidebarStore();
    const data = computed(() => sidebar.getData);
    const { result } = storeToRefs(teleUserStore);

    const selectChatId = ref("");

    const date = (date) => {
      return moment(date).format("DD MMMM YYYY, HH:mm:ss");
    };

    onMounted(() => {
      teleUserStore.getAll({});
    });

    const onDelete = async (chatId) => {
      modal.showModal();
      selectChatId.value = chatId;
    };

    const delTeleUSer = async () => {
      await teleUserStore.deleteTele(selectChatId.value);
      teleUserStore.$state.length = teleUserStore.result.count;
      teleUserStore.$state.page = 1;
      sidebar.$patch((state) => {
        if (teleUserStore.result.loading || teleUserStore.result?.count != 0) {
          if (screen.width >= 768) {
            state.open = true;
            teleUserStore.$state.selected =
              teleUserStore.result.rows[0].chat_id;
          }

          if (state.id != teleUserStore.result.rows[0].chat_id) {
            state.id = teleUserStore.result.rows[0].chat_id;
            state.data = teleUserStore.result.rows[0];
          }
        } else {
          state.open = false;
        }
      });
    };

    return {
      result,
      data,
      date,
      selectChatId,
      onDelete,
      delTeleUSer,
    };
  },
};
</script>
