<template>
  <div
    class="bg-gray-100 w-full h-[420px] flex justify-center items-center"
    v-show="id == 0 && !open"
  >
    <div
      class="inline-flex items-center justify-center w-52 h-52 bg-greenSide rounded-full shadow-lg shadow-gray-400"
    >
      <img src="../../assets/transport.png" class="w-40 h-40 px-2 py-2" />
    </div>
  </div>
  <div
    class="w-full h-[420px] flex flex-col bg-[#C1C5D0]"
    v-show="id != 0 && open"
  >
    <ChatVesselHeader />
    <ChatVesselRoom />
    <ChatVesselSent />
  </div>
</template>

<script>
import { onMounted } from "vue";
import ChatVesselHeader from "../../components/ChatVesselHeader.vue";
import ChatVesselRoom from "../../components/ChatVesselRoom.vue";
import ChatVesselSent from "../../components/ChatVesselSent.vue";
import { useChatVesselStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  props: {
    id: Number,
  },
  components: {
    ChatVesselHeader,
    ChatVesselRoom,
    ChatVesselSent,
  },
  setup() {
    const chatVesselStore = useChatVesselStore();
    const { open } = storeToRefs(chatVesselStore);

    const disconnect = () => {
      if (chatVesselStore.clien.connected) {
        try {
          chatVesselStore.clien.end(false, () => {
            console.log("Disconnected");
          });
        } catch (error) {
          console.log(error);
        }
      }
    };

    onMounted(async () => {
      await chatVesselStore.createConnection();
      await chatVesselStore.subscribe();
    });

    return {
      open,
      disconnect,
    };
  },
};
</script>

<style></style>
