import { getData } from "@/utils/fetch";
import debounce from "debounce-promise";
import { defineStore } from "pinia";
import { reactive } from "vue";
import axios from "../config";
import { useModalStore } from "./modal.store";
import { useErrorStore } from "./error.store";

let debouncedFetch = debounce(getData, 500);

export const useAlarmStore = defineStore({
  id: "alarm",
  state: () => ({
    alarm: {},
    alarmId: "",
    alarmById: reactive({
      jenis_alarm: "",
      notifikasi: false,
      status_respon: false,
      template_respon: null,
    }),
  }),
  getters: {
    getDataAlarm(state) {
      return state.alarm.data;
    },
  },
  actions: {
    async getAll(params) {
      this.alarm = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        order: params?.order || "createdAt",
        ordertype: params?.ordertype || "ASC",
      };

      try {
        const result = await debouncedFetch("alarm", dataparams);
        this.alarm = { ...result.data };
      } catch (error) {
        console.log(error);
        this.alarm = { error };
      }
    },
    async getById(id) {
      if (id) {
        this.alarmId = id;
        try {
          const result = await debouncedFetch(`alarm/${id}`);
          this.alarmById.jenis_alarm = result.data.jenis_alarm;
          this.alarmById.notifikasi = result.data.notifikasi;
          this.alarmById.status_respon = result.data.status_respon;
          if (result.data.template_respon != null) {
            this.alarmById.template_respon = result.data.template_respon.map(
              (respon) => ({
                respon: respon,
              })
            );
          } else {
            this.alarmById.template_respon = [{ respon: "" }];
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async updateAlarm(jenisAlarm, notifikasi, statusRespon, templateRespon) {
      const modal = useModalStore();
      try {
        await axios.patch(`alarm/${this.alarmId}`, {
          jenis_alarm: jenisAlarm,
          notifikasi: notifikasi,
          status_respon: statusRespon,
          template_respon: templateRespon,
        });
        modal.closeModal();
        await this.getAll({});
      } catch (error) {
        console.log(error);
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },
  },
});
