<template>
  <div
    className="flex pt-20 justify-center min-w-full min-h-screen bg-gray-100"
  >
    <div className=" w-2/3 lg:w-1/3">
      <div class="flex justify-center pb-8">
        <h1 class="text-2xl font-bold font-sans text-primary">
          <!-- Seahub Messaging -->
        </h1>
        <img src="../../assets/logo-seahub.png" width="200" />
      </div>
      <CardComponent>
        <h1
          className="font-semibold text-xl text-center text-primary uppercase"
        >
          Login
        </h1>
        <p class="text-center mb-5 text-gray-500">Sign In to your account</p>
        <AlertErrorComponent
          v-if="alert"
          :message="alert.message.response.data.message"
        />
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors, isSubmitting }"
        >
          <InputWithLabel
            label="Your email"
            name="email"
            type="text"
            placeholder="name@mail.com"
            :error="errors.email"
          />
          <InputWithLabel
            label="Your password"
            name="password"
            type="password"
            placeholder="••••••••"
            :error="errors.password"
          />
          <ButtonComponent vClass="w-full bg-darkGreen" :loading="isSubmitting"
            >Sign In</ButtonComponent
          >
          <div
            class="text-xs font-semibold text-gray-700"
            style="text-align: right; margin-top: 10px"
          >
            Don't have an account?
            <a class="text-red-700" href="/register">Register</a>
          </div>
        </Form>
        <div class="text-center" style="margin: 30px">
          <p class="or">OR</p>
          <GoogleLogin style="margin-top: 10px" :callback="callback" />
        </div>
      </CardComponent>
    </div>
  </div>
</template>

<style>
.googleContent {
  margin: 30px auto;
  width: 200px;
}

.or {
  text-align: center;
  font-size: 15px;
  background: linear-gradient(#3d3c3c 0 0) left,
    linear-gradient(#3d3c3c 0 0) right;
  background-size: 40% 1px;
  background-repeat: no-repeat;
}
</style>

<script>
import { Form } from "vee-validate";
import { useAuthStore, useAlertStore } from "@/store";
import { computed } from "vue";
import CardComponent from "@/components/CardComponent.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import * as Yup from "yup";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import { decodeCredential } from "vue3-google-login";

export default {
  components: {
    CardComponent,
    InputWithLabel,
    ButtonComponent,
    Form,
    AlertErrorComponent,
  },
  setup() {
    const callback = async (response) => {
      // This callback will be triggered when the user selects or login to
      // his Google account from the popup
      console.log("Login with Google");
      console.log("Handle the response", response);
      let userCredential = decodeCredential(response.credential);
      console.log("Decode Credential", userCredential);
      await authStore.login("", "", true, userCredential);
    };

    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is Invalid"),
      password: Yup.string().required("Password is required"),
    });

    const authStore = useAuthStore();
    const alertStore = useAlertStore();

    const alert = computed(() => alertStore.alert);

    async function onSubmit(values) {
      const { email, password } = values;

      await authStore.login(email, password);
    }

    return {
      alert,
      onSubmit,
      schema,
      callback,
    };
  },
};
</script>
