import { getData } from "@/utils/fetch";
import debounce from "debounce-promise";
import { defineStore } from "pinia";
// import axios from "axios";

let debouncedFetch = debounce(getData, 500);

export const useVesselGroupStore = defineStore({
  id: "vessel_group",
  state: () => ({
    grupSelect: 0,
    vesselGroup: [],
    vessels: [],
    byGroup: [],
    loading: false,
    loadingVessel: false,
  }),
  actions: {
    async getVesselGroup() {
      this.vesselGroup = { loading: true };
      try {
        const result = await debouncedFetch("ref_vessel_group");
        this.vesselGroup = result.data.rows;
      } catch (error) {
        console.log(error);
        this.vesselGroup = error;
      }
    },
    async getVesselInfo() {
      this.vessels = { loading: true };
      try {
        const result = await debouncedFetch("latest_vessel_info");
        this.vessels = result.data;
      } catch (error) {
        console.log(error);
        this.vessels = error;
      }
    },
    async vesselByGroup(groupId) {
      this.byGroup = { loadingVessel: true };
      try {
        const result = await debouncedFetch("vessel_group/" + groupId);
        // const vesselInfo = await axios.post(
        //   "http://183.91.67.210:50537/api/latest",
        //   {
        //     port: "[16, 18]",
        //   }
        // );

        // let res = [];
        // result.data.filter((grup) =>
        //   vesselInfo.data.data.some((vessel) => {
        //     if (grup.mmsi === vessel.mmsi) {
        //       res.push({
        //         mmsi: grup.mmsi,
        //         group_id: grup.group_id,
        //         name: vessel.name,
        //       });
        //     }
        //   })
        // );

        this.byGroup = result.data;
      } catch (error) {
        console.log(error);
        this.vessels = error;
      }
    },
  },
});
