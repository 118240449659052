<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :class="size"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      :fill="color"
      stroke="none"
    >
      <path
        d="M651 4765 c-150 -33 -254 -83 -363 -175 -117 -97 -209 -237 -255
-385 l-28 -90 0 -1290 0 -1290 28 -90 c80 -259 285 -464 542 -541 78 -24 109
-27 226 -28 131 -1 137 0 176 26 62 41 88 90 88 168 0 77 -26 127 -86 167 -33
22 -55 27 -151 33 -214 15 -331 91 -401 261 l-22 54 -3 1173 c-1 644 0 1172 4
1172 3 0 398 -243 876 -541 479 -298 902 -556 942 -575 108 -51 211 -74 336
-74 125 0 228 23 336 74 40 19 463 277 942 575 478 298 872 541 876 541 3 0 7
-253 8 -563 l3 -563 27 -41 c41 -62 90 -88 168 -88 78 0 127 26 168 88 l27 41
0 655 0 656 -28 90 c-80 259 -283 462 -542 542 l-90 28 -1870 2 c-1567 1
-1880 -1 -1934 -12z m3764 -399 c65 -17 140 -55 129 -66 -21 -19 -1794 -1115
-1834 -1134 -65 -30 -235 -30 -300 0 -40 19 -1813 1115 -1834 1134 -10 10 63
49 124 66 68 19 3644 19 3715 0z"
      />
      <path
        d="M3712 2310 c-101 -27 -166 -64 -242 -140 -66 -65 -82 -91 -130 -210
l-27 -65 -684 -5 c-614 -4 -687 -7 -714 -22 -50 -27 -415 -406 -436 -452 -23
-50 -24 -105 -4 -152 8 -20 98 -122 207 -235 146 -152 204 -205 243 -225 l52
-25 374 3 c374 3 374 3 412 27 21 13 50 42 65 64 23 34 27 52 27 107 0 55 -4
73 -27 107 -15 22 -44 51 -65 64 -38 24 -41 24 -353 27 l-315 3 -77 76 -77 76
72 79 71 78 710 0 c698 0 710 0 753 21 66 32 88 66 132 207 42 135 64 173 111
197 42 22 694 22 745 1 42 -18 65 -54 106 -166 70 -194 94 -401 65 -570 -23
-130 -62 -264 -99 -341 -51 -102 -39 -99 -445 -99 -234 0 -350 4 -365 11 -58
30 -77 62 -119 194 -22 71 -51 144 -64 161 -66 88 -213 95 -294 14 -51 -51
-67 -113 -51 -193 18 -87 68 -233 104 -302 43 -82 147 -184 230 -225 112 -55
154 -59 553 -60 398 0 441 4 552 56 96 44 203 149 250 246 43 88 106 282 133
410 30 140 33 397 5 543 -33 177 -108 392 -170 488 -46 72 -139 155 -215 190
-111 52 -152 56 -556 56 -332 0 -378 -2 -443 -19z"
      />
      <path
        d="M4242 1529 c-48 -14 -109 -80 -123 -131 -23 -89 12 -182 88 -229 57
-36 154 -34 210 3 62 41 88 90 88 168 0 77 -26 127 -85 166 -43 29 -125 39
-178 23z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#062346",
    },
    size: {
      type: String,
      default: "w-6 h-6",
    },
  },
};
</script>

<style></style>
