<template>
  <div class="roomChat flex-1 overflow-y-auto">
    <div class="flex flex-col px-5 py-2 mt-2 mb-2">
      <div v-for="data in chatHistory" :key="data.mmsi">
        <div
          class="flex mb-2"
          v-if="
            parseInt(data.sender_id) == chatVesselStore.id ||
            parseInt(data.receiver_id) == chatVesselStore.id
          "
          :class="
            parseInt(data.sender_id) == chatVesselStore.id ? '' : 'justify-end'
          "
        >
          <div
            class="rounded py-2 px-2 flex flex-col max-w-xs"
            :class="
              parseInt(data.sender_id) == chatVesselStore.id
                ? 'bg-greenSide'
                : 'bg-white'
            "
          >
            <div
              class="text-xs mt-1"
              :class="
                parseInt(data.sender_id) == chatVesselStore.id
                  ? 'text-white'
                  : 'text-gray-800'
              "
            >
              {{ data.message }}
            </div>
            <p
              class="text-[9px] mt-0.5"
              :class="
                parseInt(data.sender_id) == chatVesselStore.id
                  ? 'text-left text-white'
                  : 'text-right text-gray-600'
              "
            >
              {{ date(data.createdAt) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useChatVesselStore } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import moment from "moment";

export default {
  setup() {
    const chatVesselStore = useChatVesselStore();
    const { chatHistory } = storeToRefs(chatVesselStore);

    onMounted(() => {
      chatVesselStore.roomChat = document.querySelector(".roomChat");
    });

    const date = (date) => {
      return moment(date).format("DD/MM/YY, HH:mm");
    };

    return {
      chatVesselStore,
      chatHistory,
      date,
    };
  },
};
</script>

<style></style>
