import debounce from "debounce-promise";
import { getData } from "@/utils/fetch";
import { defineStore } from "pinia";
import axios from "../config";
import { useModalStore } from "./modal.store";
import { useErrorStore } from "./error.store";
import { useSidebarStore } from "./sidebar.store";
import { ref } from "vue";

let debouncedFetch = debounce(getData, 500);

export const useModemStore = defineStore({
  id: "modem",
  state: () => ({
    listModem: {},
    byId: {
      modem_id: "",
      name: "",
      status: "",
      type: "",
      tele_id: "",
      tele_name: "",
      client_id: "",
      client_secret: "",
    },
    detailModems: {},
    modemid: "",
    page: 1,
    length: 0,
    size: 10,
    q: "",
    selected: "",
    dateFilter: ref([]),
  }),
  getters: {
    getDataModem(state) {
      return state.modems.data;
    },
  },
  actions: {
    async registerModem(
      modemid,
      name,
      status,
      type,
      teleid,
      telename,
      clientid,
      clientsecret
    ) {
      const modal = useModalStore();
      try {
        await axios.post("modems", {
          modem_id: modemid,
          name: name,
          status: status,
          type: type,
          tele_id: teleid,
          tele_name: telename,
          client_id: clientid,
          client_secret: clientsecret,
        });

        modal.closeModal();
        this.page = 1;
        await this.getAllModem({});
        this.length = this.listModem.count;
      } catch (error) {
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },
    async getAllModem(params) {
      this.listModem = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        order: params?.order || "modem_id",
        ordertype: params?.ordertype || "ASC",
        q: params?.q,
        fromDate: params?.fromDate,
        toDate: params?.toDate,
      };

      try {
        const data = await debouncedFetch("modems", dataparams);
        this.listModem = { ...data.data };
      } catch (error) {
        this.listModem = { error };
      }
    },

    async getModemById(modemId) {
      if (modemId) {
        this.modemid = modemId;
        try {
          const res = await axios.get(`modems/${modemId}`);
          this.byId.modem_id = res.data.modem_id;
          this.byId.name = res.data.name;
          this.byId.status = res.data.status;
          this.byId.type = res.data.type;
          this.byId.tele_id = res.data.tele_id;
          this.byId.tele_name = res.data.tele_name;
          this.byId.client_id = res.data.client_id;
          this.byId.client_secret = res.data.client_secret;
        } catch (error) {
          console.log(error);
        }
      }
    },

    async updateModem(
      name,
      status,
      type,
      teleid,
      telename,
      clientid,
      clientsecret
    ) {
      const modal = useModalStore();
      try {
        await axios.patch(`modems/${this.modemid}`, {
          name: name,
          status: status,
          type: type,
          tele_id: teleid,
          tele_name: telename,
          client_id: clientid,
          client_secret: clientsecret,
        });
        modal.closeModal();
        this.page = 1;
        await this.getAllModem({});
      } catch (error) {
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },

    async deleteModem(modemId) {
      const modal = useModalStore();
      try {
        await axios.delete(`modems/${modemId}`);
        modal.closeModal();
        this.page = 1;
        await this.getAllModem({});
      } catch (error) {
        modal.closeModal();
        const errorStore = useErrorStore();
        errorStore.open = true;
        errorStore.retry = false;
        errorStore.message = error.response.data.message;
      }
    },

    async detailModem(modemId) {
      this.detailModems = { loading: true };

      try {
        const data = await debouncedFetch("modems/detail/" + modemId);
        this.detailModems = { ...data.data };
      } catch (error) {
        this.detailModems = { error };
      }
    },

    select() {
      const sidebarStore = useSidebarStore();
      sidebarStore.$patch(async (state) => {
        if (this.listModem?.count != 0) {
          if (screen.width >= 768) {
            state.open = true;
            this.selected = this.listModem.rows[0].modem_id;
          }

          if (state.id != this.listModem.rows[0].modem_id) {
            state.id = this.listModem.rows[0].modem_id;
            state.data = this.listModem.rows[0];
          }
        } else {
          state.open = false;
        }
      });
    },
  },
});
