<template>
  <div class="pt-5 pb-5">
    <div
      class="overflow-x-auto relative shadow-md rounded-lg"
      v-if="seahubDevice.rows?.length"
    >
      <table class="w-full text-sm text-left text-gray-500">
        <tbody>
          <tr
            class="bg-white border-b"
            v-for="data in seahubDevice.rows"
            :key="data.device_id"
          >
            <th scope="row" class="py-3 px-6 font-medium text-gray-900">
              <div class="flex items-center space-x-3">
                <div class="bg-greenSide rounded-full p-2">
                  <IconMobile color="white" size="w-5 h-5" />
                </div>
                <p class="text-md">{{ data.serial_number }}</p>
              </div>
            </th>
            <td class="py-3 px-2">{{ data.mmsi }}</td>
            <td class="py-3 px-2">{{ data.mode }}</td>
            <td clas="py-3 px-2">{{ date(data.createdAt) }}</td>
            <td class="py-3 px-2">
              <div
                class="flex justify-center space-x-2.5 [&_svg]:w-3 [&_svg]:h-3"
              >
                <button
                  class="bg-amber hover:bg-amber500 rounded-full cursor-pointer p-1"
                  @click="onEdit(data.serial_number)"
                >
                  <IconEdit color="fill-white" />
                </button>
                <button
                  class="bg-red-700 hover:bg-red-800 rounded-full cursor-pointer p-1"
                  @click="onDelete(data.serial_number)"
                >
                  <IconTrash />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent
        v-model:length="length"
        v-model:size="size"
        v-model:page="page"
        @onSelectChange="changePage"
      />
    </div>
    <LoadingLottie v-if="seahubDevice.loading" />
    <div class="flex justify-center" v-if="seahubDevice.rows?.length == 0">
      <NoData size="w-full md:w-5/12" />
    </div>
    <AlertErrorComponent
      v-if="seahubDevice.error"
      :message="seahubDevice.error.response.data.message"
    />
  </div>
  <EditDevice :id="2" />
  <DeleteConfirmation
    :id="3"
    :deviceId="selectDeviceId"
    @onDeleteData="deleteDevice"
  />
</template>

<script>
import IconMobile from "@/components/icons/IconMobile.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import LoadingLottie from "@/components/LoadingLottie.vue";
import NoData from "@/components/NoData.vue";
import AlertErrorComponent from "@/components/AlertErrorComponent.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import EditDevice from "./EditDevice.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import { useModalStore, useSeahubDevice } from "@/store";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import moment from "moment";

export default {
  props: {
    deviceId: String,
  },
  components: {
    IconMobile,
    PaginationComponent,
    LoadingLottie,
    NoData,
    AlertErrorComponent,
    IconEdit,
    IconTrash,
    EditDevice,
    DeleteConfirmation,
  },
  setup() {
    const modal = useModalStore();
    const deviceStore = useSeahubDevice();
    const { page, length, size, seahubDevice } = storeToRefs(deviceStore);

    const offset = ref(0);
    const selectDeviceId = ref("");

    onMounted(async () => {
      await deviceStore.getAllDevice({ offset: offset.value });
      deviceStore.length = deviceStore.seahubDevice.count;
    });

    const date = (date) => {
      return moment(date).format("DD MMM YYYY, HH:mm:ss");
    };

    const changePage = async (value) => {
      deviceStore.page = value;
      offset.value = (deviceStore.page - 1) * deviceStore.size;

      let dataParams = { offset: offset.value };
      if (deviceStore.q != "") {
        dataParams = {
          ...dataParams,
          q: deviceStore.q,
        };
      }
      await deviceStore.getAllDevice(dataParams);
    };

    const onEdit = async (deviceId) => {
      modal.showModal(2);
      await deviceStore.getDeviceById(deviceId);
    };

    const onDelete = (deviceId) => {
      modal.showModal(3);
      selectDeviceId.value = deviceId;
    };

    const deleteDevice = async () => {
      await deviceStore.deleteDevice(selectDeviceId.value);
    };

    return {
      page,
      length,
      size,
      seahubDevice,
      selectDeviceId,
      changePage,
      date,
      onEdit,
      onDelete,
      deleteDevice,
    };
  },
};
</script>

<style></style>
