<template>
  <div class="main">
    <HeaderTitle title="Vessel Report">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#14444f"
        class="w-8 h-8"
      >
        <path
          fill-rule="evenodd"
          d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z"
          clip-rule="evenodd"
        />
        <path
          fill-rule="evenodd"
          d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375ZM6 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V12Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 15a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V15Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 18a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V18Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"
          clip-rule="evenodd"
        />
      </svg>
    </HeaderTitle>

    <MainContent>
      <div class="pt-5 flex space-x-2 space-y-3 md:space-y-0 justify-end">
        <DatePickerNoLabel
          v-model:formControlName="dateFilter"
          @onClickApply="onClickApply"
        />
        <ButtonComponent
          vClass="text-xs text-primary bg-gray-300 [&>svg]:mr-0 focus:ring-0 px-3 py-2"
          @click="downloadExcel"
          :loading="loading"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>

          Export Excel
        </ButtonComponent>
      </div>
      <div class="pt-3">
        <span
          class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
        >
          {{ date }}
        </span>
      </div>

      <TableVesselReport />
    </MainContent>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle.vue";
import MainContent from "@/components/MainContent.vue";
import DatePickerNoLabel from "@/components/DatePickerNoLabel.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import TableVesselReport from "./TableVesselReport.vue";
import { onMounted, ref } from "vue";
import moment from "moment";
import { useVesselReportStore } from "@/store";
import { storeToRefs } from "pinia";
import axios from "../../config";

export default {
  components: {
    HeaderTitle,
    MainContent,
    DatePickerNoLabel,
    ButtonComponent,
    TableVesselReport,
  },
  setup() {
    const vesselReportStore = useVesselReportStore();
    const { dateFilter } = storeToRefs(vesselReportStore);

    const offset = ref(0);

    const dateNow = new Date();
    const date = ref("");
    const loading = ref(false);

    const downloadExcel = async (params) => {
      let dataParams = {
        order: params?.order || "entry_time",
        ordertype: params?.ordertype || "ASC",
      };
      if (vesselReportStore.dateFilter != null) {
        dataParams = {
          ...dataParams,
          fromDate: moment(vesselReportStore.dateFilter[0]).format(
            "YYYY-MM-DD"
          ),
          toDate: moment(vesselReportStore.dateFilter[1]).format("YYYY-MM-DD"),
        };
      }
      loading.value = true;
      const response = await axios.get("vessel_report/report/download", {
        params: dataParams,
        responseType: "blob",
      });
      const link = document.createElement("a");
      const fileName = "Vessel Report.xlsx";
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      loading.value = false;

      return response.data.data;
    };

    const onClickApply = async () => {
      offset.value = 0;
      vesselReportStore.page = 1;
      let dataParams = {
        offset: offset.value,
      };

      if (vesselReportStore.dateFilter != null) {
        date.value = `${moment(vesselReportStore.dateFilter[0]).format(
          "DD MMM YYYY"
        )} - ${moment(vesselReportStore.dateFilter[1]).format("DD MMM YYYY")}`;
        dataParams = {
          ...dataParams,
          fromDate: moment(vesselReportStore.dateFilter[0]).format(
            "YYYY-MM-DD"
          ),
          toDate: moment(vesselReportStore.dateFilter[1]).format("YYYY-MM-DD"),
        };
      }
      date.value = "";

      await vesselReportStore.getReport(dataParams);
      vesselReportStore.length = vesselReportStore.reports.count;
    };

    onMounted(async () => {
      dateFilter.value = [dateNow, dateNow];
      date.value = `${moment(dateFilter.value[0]).format(
        "DD MMM YYYY"
      )} - ${moment(dateFilter.value[1]).format("DD MMM YYYY")}`;
    });

    return {
      dateFilter,
      date,
      onClickApply,
      loading,
      downloadExcel,
    };
  },
};
</script>

<style></style>
