import { defineStore } from "pinia";
import debounce from "debounce-promise";
import { getData } from "@/utils/fetch";
import axios from "../config";
import { useErrorStore } from "./error.store";
import { reactive, ref } from "vue";
import { useModalStore } from "./modal.store";
import moment from "moment";
import { useSidebarStore } from "./sidebar.store";

let debouncedFetch = debounce(getData, 500);

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    users: {},
    byId: reactive({
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    }),
    id: "",
    total: {},
    selected: ref(""), //selected data table
    page: ref(1), //page pagination
    length: ref(0), //length pagination
    q: ref(""), //filter search
    dateFilter: ref([]), //filter date
  }),
  getters: {
    getDataUser(state) {
      return state.users.data;
    },
  },
  actions: {
    async getAll(params) {
      this.users = { loading: true };

      const dataparams = {
        limit: params?.limit || 10,
        offset: params?.offset || 0,
        name: params?.name,
        role: params?.role,
        order: params?.order || "firstName",
        ordertype: params?.ordertype || "ASC",
        q: params?.q,
        fromDate: params?.fromDate,
        toDate: params?.toDate,
      };

      try {
        const data = await debouncedFetch("users", dataparams);
        this.users = { ...data.data };
      } catch (error) {
        this.users = { error };
      }
    },

    select() {
      const sidebarStore = useSidebarStore();
      sidebarStore.$patch((state) => {
        if (this.users?.count != 0) {
          if (screen.width >= 768) {
            state.open = true;
            this.selected = this.users.rows[0].id;
          }

          if (state.id != this.users.rows[0].id) {
            state.id = this.users.rows[0].id;
            state.data = this.users.rows[0];
          }
        } else {
          state.open = false;
        }
      });
    },

    async addUser(firstName, lastName, email, password, role) {
      const modal = useModalStore();
      try {
        await axios.post("users", {
          firstName: firstName,
          lastName: lastName ? lastName : " ",
          email: email,
          password: password,
          role: role,
        });
        modal.closeModal();
        this.page = 1;
        this.dateFilter = [];
        await this.getAll({});
        this.select();
        this.length = this.users.count;
      } catch (error) {
        modal.closeModal();
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.retry = false;
        errorMessage.message = error.response.data.message;
      }
    },

    async userById(id) {
      if (id) {
        this.id = id;
        try {
          const res = await axios.get(`users/${id}`);
          this.byId.firstName = res.data.firstName;
          this.byId.lastName = res.data.lastName;
          this.byId.email = res.data.email;
          this.byId.role = res.data.role;
        } catch (error) {
          console.log(error);
        }
      }
    },

    async updateUser(firstName, lastName, email, role) {
      const modal = useModalStore();
      try {
        await axios.patch(`users/${this.id}`, {
          firstName: firstName,
          lastName: lastName ? lastName : " ",
          email: email,
          role: role,
        });
        modal.closeModal();
        this.page = 1;
        if (this.dateFilter != null) {
          if (this.dateFilter.length != 0) {
            await this.getAll({
              fromDate: moment(this.dateFilter[0]).format("YYYY-MM-DD"),
              toDate: moment(this.dateFilter[1]).format("YYYY-MM-DD"),
            });
          } else {
            await this.getAll({});
          }
        } else {
          await this.getAll({});
        }
        this.select();
        this.length = this.users.count;
      } catch (error) {
        modal.closeModal();
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.retry = false;
        errorMessage.message = error.response.data.message;
        console.log(error);
      }
    },

    async deleteUser(id) {
      const modal = useModalStore();
      try {
        await axios.delete(`users/${id}`);
        modal.closeModal();
        this.page = 1;
        if (this.dateFilter != null) {
          if (this.dateFilter.length != 0) {
            await this.getAll({
              fromDate: moment(this.dateFilter[0]).format("YYYY-MM-DD"),
              toDate: moment(this.dateFilter[1]).format("YYYY-MM-DD"),
            });
          } else {
            await this.getAll({});
          }
        } else {
          await this.getAll({});
        }
        this.length = this.users.count;
      } catch (error) {
        modal.closeModal();
        const errorMessage = useErrorStore();
        errorMessage.open = true;
        errorMessage.message = error.response.data.message;
        console.log(error);
      }
    },

    async countUser() {
      this.total = { loading: true };
      try {
        const data = await axios.get("users/count");
        this.total = { ...data.data };
      } catch (error) {
        this.total = error;
      }
    },
  },
});
